import { styled, Typography } from '@mui/material';

export const Title = styled(Typography)`
  color: #39393a;
  font-size: 38px;
  font-weight: 500;
  line-height: 45px;
  font-family: Roboto, sans-serif;
  span {
    color: #007236;
  }
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;
