import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import { getCloudDelegationList } from '@store/cloud/cloud.service';
import { CloudDelegation } from '@store/cloud/contracts';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

const Delegations = () => {
  const dispatch = useAppDispatch();
  const [delegations, setDelegations] = useState<CloudDelegation[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const {
    data: fetchedData,
    total,
    loading,
  } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getCloudDelegationList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setDelegations(fetchedData);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const budgetValue = (row: any) => {
    let currency = row.currency !== null ? row.currency.code : '';
    return row.budget + ' ' + currency;
  };

  return (
    <div className="col">
      <div className="mt-4">
        <AdaptiveTableMUI
          emptyLabel="У вас нет делегатов"
          setRequestBody={setRequestBody}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={loading}
          columns={{
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row) => (
                <NavLink to={`/clouds/delegations/${row?.id}`}>{value}</NavLink>
              ),
            },
            owner: {
              label: 'Делегировал',
              filterInput: true,
              children: (_, row) => (
                <NavLink to={`/userPage/${row?.owner?.id}`}>{row?.owner?.userName}</NavLink>
              ),
            },
            organization: {
              label: 'Организация',
              filterInput: true,
              children: (_, row) => row?.organization?.resourceName,
            },
            cloud: {
              label: 'Облако',
              filterInput: true,
              children: (_, row) => row?.cloud?.resourceName,
            },
            project: {
              label: 'Проект',
              filterInput: true,
              children: (_, row) => {
                return row.project && row.project.name ? (
                  <NavLink to={`/projects/${row.project.id}/details`}>{row.project.name}</NavLink>
                ) : (
                  <span>-</span>
                );
              },
            },
            budget: {
              label: 'Бюджет',
              filterInput: true,
              children: (_, row) => <span>{budgetValue(row)}</span>,
            },
          }}
          rows={delegations}>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="делегатов"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { Delegations };
