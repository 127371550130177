import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { useAppDispatch } from '@hooks/index';
import { getCloudDeploymentResource } from '@store/cloud/cloud.service';
import { CloudDeploymentResource } from '@store/cloud/contracts';
import { Card, CircularProgress } from '@mui/material';
import { Button } from '@components/MUI/Button';
import { ReactComponent as EditIcon } from '@assets/img/edit-icon-gray.svg';
import { AttributeBlock } from './AttributeBlock';
import styles from './DeploymentResourceDetail.module.scss';
import { openModalError } from '@store/ui/ui.slice';

const DeploymentResourceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deploymentResourceId } = useParams();
  const fetched = useRef(false);

  const [deploymentResource, setDeploymentResource] = useState<CloudDeploymentResource>();

  useEffect(() => {
    if (!fetched.current && deploymentResourceId) {
      (async () => {
        try {
          const item = await getCloudDeploymentResource(deploymentResourceId);
          setDeploymentResource(item);
        } catch (e: any) {
          dispatch(openModalError(e));
        } finally {
          fetched.current = true;
        }
      })();
    }
  }, [dispatch, deploymentResourceId]);

  return (
    <div className={`col common_bc container-content ${styles.containerContent}`}>
      {fetched.current ? (
        <>
          <div className="mt-4 ms-4 d-flex gap-2">
            <NavigationBack back={{ to: '/clouds/deployments/' }} />
          </div>
          <Card className="p-4 ms-4 me-4" elevation={0} variant="outlined">
            <div className="row  mb-4">
              <div className={styles.resource__detail__header}>
                <h2 className={styles.resource__detail__title}>{deploymentResource?.name}</h2>
                <Button
                  onClick={() => navigate(`/clouds/deployments/${deploymentResourceId}/edit`)}
                  className={styles.resource__detail__edit__button}
                  startIcon={<EditIcon />}>
                  Редактировать
                </Button>
              </div>
            </div>
            <div className={styles.resource__detail__cards}>
              <Card className={styles.resource__detail__cards__1} elevation={0} variant="outlined">
                <AttributeBlock
                  title="Платформа"
                  value={deploymentResource?.platform?.resourceName || 'Нет'}
                />
                <AttributeBlock
                  title="Инфраструктура"
                  value={deploymentResource?.infrastructure?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Зона"
                  value={deploymentResource?.zone?.resourceName || 'Нет'}
                />
                <AttributeBlock
                  title="Шаблон"
                  value={deploymentResource?.instanceParams?.name || 'Нет'}
                />
                <AttributeBlock title="Правила" value={deploymentResource?.rule?.name || 'Нет'} />
                <AttributeBlock
                  title="Количество виртуальных машин"
                  value={deploymentResource?.numberInstance?.toString() || '0'}
                />
                <AttributeBlock
                  title="Ядро"
                  value={deploymentResource?.instanceParams?.cores?.toString() || '0'}
                />
              </Card>
              <Card className={styles.resource__detail__cards__2} elevation={0} variant="outlined">
                <AttributeBlock
                  title="Использовать образ виртуальной машины"
                  value={deploymentResource?.isUseMasterInstance ? 'Да' : 'Нет'}
                />
                <AttributeBlock
                  title="Мастер машина образа"
                  value={deploymentResource?.masterInstance?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Образ машины"
                  value={deploymentResource?.image?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Расписание"
                  value={deploymentResource?.shedule?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Действие на старте расписания"
                  value={deploymentResource?.startAction?.name || 'Нет'}
                />
                <AttributeBlock
                  title="Действие на окончании расписания"
                  value={deploymentResource?.endAction?.name || 'Нет'}
                />
              </Card>
            </div>
          </Card>
        </>
      ) : (
        <div className={styles.loadingBlock}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export { DeploymentResourceDetail };
