import { plainToInstance } from 'class-transformer';
import { ApiClient } from '../api-client';
import { GetMyVacanciesResponse } from './contracts';
import Api from '@api-schema';

export const getFavoriteVacancies = async (
  requestBody: Api.operations['getFavoriteVacancies']['requestBody']['content']['application/json'],
) => {
  const { data } = await ApiClient.post<
    Api.operations['getFavoriteVacancies']['responses']['200']['content']['application/json']
  >('/api/vacancy/favorite', requestBody);
  return plainToInstance(GetMyVacanciesResponse, data);
};
