import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as TrackerService from '@store/tasks/tracker.service';
import {
  CreateTaskRequest,
  CreateTaskResponse,
  GetTasksResponse,
  LoadProjectsRequest,
  Project,
  Task,
  TaskTransition,
  TrackerProvider,
  UpdateTaskRequest,
} from './contracts';
import { RootState } from '../index';
import { enqueueSnackbar } from 'notistack';
import { api } from '@store/api-client';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import { mapFieldsToJsonSchema } from '@components/JsonForm/helpers';

type TrackerState = {
  projects: Project[];
  tasks: Task[];
  provider: TrackerProvider;
  task: Task | null;
  projectIds: string[] | null;
};

const initialState: TrackerState = {
  projects: [],
  tasks: [],
  provider: TrackerProvider.Yandex,
  task: null,
  projectIds: null,
};

type SetProjectsActionPayload = {
  provider: TrackerProvider;
  projects: Project[];
};

type SetTasksActionPayload = {
  tasks: Task[];
};

const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<SetProjectsActionPayload>) => {
      state.provider = action.payload.provider;
      state.projects = [...action.payload.projects];
    },
    setTasks: (state, action: PayloadAction<SetTasksActionPayload>) => {
      state.tasks = [...action.payload.tasks];
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setSelectedProjectId: (state, action: PayloadAction<string[]>) => {
      state.projectIds = action.payload;
    },
  },
});

export const loadProjects = createAsyncThunk(
  'tracker/loadProjects',
  async (request: LoadProjectsRequest, { dispatch }) => {
    const projects = await TrackerService.getTrackerProjects(request.provider);
    await dispatch(setProjects({ projects, provider: request.provider }));
  },
);

const { setProjects } = trackerSlice.actions;
export const selectProjects = (state: RootState) => state.tracker.projects;

export const selectProvider = (state: RootState) => state.tracker.provider;

export const trackerReducer = trackerSlice.reducer;

const tasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<GetTasksResponse, Api.operations['list']['parameters']['query']>({
      query: (params) => ({
        method: `GET`,
        url: `/api/tracker/tasks`,
        params: params,
      }),
      transformResponse: (response: ResponseBody<Api.operations['list']>) => {
        return plainToInstance(GetTasksResponse, response);
      },
      providesTags: ['TasksList'],
    }),

    loadTaskDetail: builder.query<Task, PathParameters<Api.operations['get'], 'taskId'>>({
      query: (taskId) => ({
        method: `GET`,
        url: `/api/tracker/tasks/${taskId}`,
      }),
      transformResponse: (response: ResponseBody<Api.operations['get']>) => {
        return plainToInstance(Task, response);
      },
      providesTags: ['TaskDetail'],
    }),

    loadTaskTransitions: builder.query<
      TaskTransition[],
      PathParameters<Api.operations['transitions'], 'taskId'>
    >({
      query: (taskId) => ({
        method: `GET`,
        url: `/api/tracker/tasks/${taskId}/transitions`,
      }),
      transformResponse: (response: ResponseBody<Api.operations['transitions']>[]) => {
        if (!Array.isArray(response)) return [];
        return response.map((transition) => {
          if (transition.fields) {
            return {
              ...transition,
              schema: mapFieldsToJsonSchema(transition.fields),
            };
          } else return transition;
        });
      },
      providesTags: ['TaskTransitions'],
    }),

    executeTaskTransition: builder.mutation<
      ResponseBody<Api.operations['executeTransition']>,
      {
        taskId: PathParameters<Api.operations['executeTransition'], 'taskId'>;
        transition: RequestBody<Api.operations['executeTransition']>;
      }
    >({
      query: ({ taskId, transition }) => ({
        method: `POST`,
        url: `/api/tracker/tasks/${taskId}/transitions/execute`,
        body: transition,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Переход статуса совершен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TaskDetail', 'TaskTransitions'],
    }),

    updateTask: builder.mutation<CreateTaskResponse, UpdateTaskRequest>({
      query: ({ taskId, data }) => ({
        method: `PATCH`,
        url: `/api/tracker/tasks/${taskId}`,
        body: data,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Задача отредактирована', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TaskDetail', 'TasksList', 'DashboardTaskStatuses'],
    }),

    createTask: builder.mutation<CreateTaskResponse, CreateTaskRequest>({
      query: ({ data }) => ({
        method: `POST`,
        url: `/api/tracker/tasks`,
        body: data,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Задача создана', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['TasksList', 'DashboardTaskStatuses', 'TaskDetail'],
    }),
    getSysTaskStatuses: builder.query<
      ResponseBody<Api.operations['listTaskStatuses']>,
      PathParameters<Api.operations['listTaskStatuses'], 'projectId'>
    >({
      query: (projectId) => ({
        method: 'GET',
        url: `/api/tracker/sys/${projectId}/task-statuses`,
      }),
      providesTags: ['TaskStatuses'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useLoadTaskDetailQuery,
  useLoadTaskTransitionsQuery,
  useExecuteTaskTransitionMutation,
  useUpdateTaskMutation,
  useCreateTaskMutation,
  useLazyGetSysTaskStatusesQuery,
} = tasksApi;
