import {
  InfrastructureForm,
  InfrastructureFormProps,
} from '@pages/cloud/infrastructures/InfrastructureForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import { createInfrastructure } from '@store/cloud/cloud.slice';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './InfrastructureForm.module.scss';

function CreateInfrastructureForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createSaveInfrastructure: InfrastructureFormProps['submit'] = async (
    InfrastructureForm,
    form,
  ) => {
    try {
      await dispatch(
        createInfrastructure({
          name: InfrastructureForm.name,
          organization: {
            resourceId: InfrastructureForm.organizationId,
            resourceName: InfrastructureForm.organizationName,
            resourceType: 'organization',
          },
          cloud: {
            resourceId: InfrastructureForm.cloudId,
            resourceName: InfrastructureForm.cloudName,
            parentResourceId: InfrastructureForm.organizationId,
            resourceType: 'cloud',
          },
          folder: {
            resourceId: InfrastructureForm.folderId,
            resourceName: InfrastructureForm.folderName,
            resourceType: 'folder',
            parentResourceId: InfrastructureForm.cloudId,
          },
          network: {
            resourceId: InfrastructureForm.networkId,
            resourceName: InfrastructureForm.networkName,
            resourceType: 'network',
            parentResourceId: InfrastructureForm.folderId,
          },
          //          serviceAccount: {resourceId:InfrastructureForm.serviceAccountId, resourceName: InfrastructureForm.serviceAccountName, resourceType: "service_account", parentResourceId: InfrastructureForm.folderId},
          delegationId: InfrastructureForm.delegationId,
        }),
      ).unwrap();
      navigate(`/clouds/Infrastructures`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Инфраструктура</h2>
          </div>
          <InfrastructureForm
            value={{
              id: '',
              name: '',
              organizationId: '',
              organizationName: '',
              cloudId: '',
              cloudName: '',
              folderId: '',
              folderName: '',
              networkId: '',
              networkName: '',
              serviceAccountId: '',
              serviceAccountName: '',
              delegationId: '',
            }}
            submit={createSaveInfrastructure}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateInfrastructureForm };
