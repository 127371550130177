import { Card } from 'react-bootstrap';
import { useLoadTaskDetailQuery, useUpdateTaskMutation } from '@store/tasks/tracker.slice';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TaskForm, TaskFormProps } from './TaskForm';
import styles from './TaskForm.module.scss';
import { Skeleton } from '@mui/material';

export const TaskEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { data: taskDetail } = useLoadTaskDetailQuery(taskId!);
  const [updateTask] = useUpdateTaskMutation();

  const submitTask: TaskFormProps['submit'] = useCallback(
    async (TaskForm, _) => {
      try {
        if (taskDetail) {
          updateTask({
            taskId: taskDetail.id,
            data: {
              ...TaskForm,
            },
          });
          navigate(`/tasks/${taskDetail.id}`);
        }
      } catch (e: any) {}
    },
    [dispatch, taskDetail],
  );
  if (!taskDetail) {
    return <Skeleton />;
  }

  return (
    <Card className="py-5 px-5 form-container mb-5">
      <div className={styles.form__container}>
        <div className={styles.form__container__title}>
          <h2 className="form-title m-0">
            Редактирование
            <br />
            задачи
          </h2>
        </div>
        <div className={styles.form__container__fields}>
          <TaskForm
            value={{
              id: taskDetail.id,
              projectId: taskDetail.projectId,
              description: taskDetail.description,
              name: taskDetail.name,
              priority: taskDetail.priority,
              queue: taskDetail.queue,
              type: taskDetail.type,
              tags: taskDetail.tags,
              deadline: taskDetail.deadline?.format('YYYY-MM-DD'),
              estimation: taskDetail.estimation,
              executors: taskDetail.executors,
              provider: taskDetail.provider,
              statusId: taskDetail.status.id || null,
            }}
            submit={submitTask}
          />
        </div>
      </div>
    </Card>
  );
};
