import { useEffect } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

type NewsParams = 'ordering' | 'tags__id' | 'page' | 'page_size' | 'chapter__id';

type NewsParamsResult = {
  [key in NewsParams]?: string | null;
};

type UseNewsSearchParamsResult = {
  currentQuery: NewsParamsResult;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

export function useNewsSearchParams(): UseNewsSearchParamsResult {
  const [searchParams, setSearchParams] = useSearchParams();

  let currentQuery: NewsParamsResult = {};

  const newsParams: NewsParams[] = ['ordering', 'tags__id', 'page', 'page_size', 'chapter__id'];

  if (Array.from(searchParams.keys()).length) {
    newsParams.forEach((query) => {
      if (searchParams.has(query)) {
        currentQuery[query] = searchParams.get(query);
      }
    });
  }

  useEffect(() => {
    setSearchParams((search) => {
      if (!searchParams.has('page_size')) {
        search.set('page_size', '5');
      }
      if (!searchParams.has('page')) {
        search.set('page', '1');
      }
      if (!searchParams.has('ordering')) {
        search.set('ordering', 'desc');
      }
      return search;
    });
  }, [searchParams]);

  return { currentQuery, searchParams, setSearchParams };
}
