import React from 'react';
import styles from './Card.module.scss';

interface CardFeaturesProps {
  iconURL: string;
  cardDescription: string;
  style?: string;
}

export const CardFeatures: React.FC<CardFeaturesProps> = ({ iconURL, cardDescription, style }) => {
  return (
    <div className={`${styles.card} ${style}`}>
      <div>
        <img src={iconURL} alt="icon" />
      </div>
      <div className={styles.cardDescription}>{cardDescription}</div>
    </div>
  );
};
