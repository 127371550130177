import {
  Box,
  Card as MCard,
  CardContent as MCardContent,
  styled,
  Typography as MTypography,
} from '@mui/material';

interface ProjectInfoCardProps {
  date?: string;
  projectName: string;
  textDeadline: string;
  dateOfDeadline: string;
  textBudget: string;
  budget: string;
}

const Card = styled(MCard)`
  border: 1px solid #f5f5f5;
  box-shadow: 2px 4px 12px 0px rgba(91, 91, 91, 0.06);
  border-radius: 4px;
  padding: 15px 28px 16px 16px;
  display: flex;
  gap: 12px;
  @media (max-width: 768px) {
    padding: 15px 16px 16px 16px;
  }
`;
const CardContent = styled(MCardContent)`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;

  &:last-child {
    padding: 0;
  }
`;

const StatsBlock = styled(MTypography)`
  color: var(--color-light-gray);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Text = styled(MTypography)`
  color: var(--count-light: );
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Common = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export function ProjectInfoCard({
  date,
  projectName,
  textBudget,
  textDeadline,
  dateOfDeadline,
  budget,
}: ProjectInfoCardProps) {
  return (
    <Card>
      <CardContent>
        <Common>
          <StatsBlock>{date}</StatsBlock>
          <Text>{projectName}</Text>
        </Common>
        <Common>
          <StatsBlock>{textDeadline}</StatsBlock>
          <Text>{dateOfDeadline}</Text>
        </Common>
        <Common>
          <StatsBlock>{textBudget}</StatsBlock>
          <Text>{budget}</Text>
        </Common>
      </CardContent>
    </Card>
  );
}
