import React from 'react';
import { Paper } from '@mui/material';
import { StatusList } from './StatusList';
import { ProjectsPie } from './ProjectsPie';
import styles from './AmountOfProjects.module.scss';
import { useGetDashboardProjectStatusesQuery } from '@store/dashboard/dashboard.slice';

export const projectStatusColors = new Map([
  ['Новое', '#FE5959'],
  ['В работе', '#3BABF0'],
  ['Завершено', '#34D15F'],
  ['Приемка', '#F1C426'],
  ['Принято', '#FE9417'],
  ['Неактивный', '#78858B'],
  ['Ждет назначения менеджера', '#2E1CFF'],
  ['default', '#D3D3D3'],
]);

export const AmountOfProjects: React.FC = () => {
  const { data: projects } = useGetDashboardProjectStatusesQuery('');

  return (
    <Paper elevation={0} variant="outlined" className={styles.container}>
      <h2 className={styles.title}>Количество проектов</h2>

      <div className={styles.content}>
        <StatusList projects={projects} />
        <ProjectsPie projects={projects ? projects : {}} />
      </div>
    </Paper>
  );
};
