import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import {
  ResettingFormStepThree,
  PasswordFormValue,
} from '@pages/authentication/ResettingFormStepThree';
import { useResetPasswordUpdateMutation } from '@store/user/user.slice';
import { openModal } from '@store/ui/ui.slice';
import { ModalError } from '@components/ModalError';
import styles from '@pages/authentication/Authentication.module.scss';

export function NewPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [userResetPasswordUpdate] = useResetPasswordUpdateMutation();

  const onSubmit = async (data: PasswordFormValue) => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const email = query.get('email');

    if (email && code) {
      userResetPasswordUpdate({
        email: email,
        code: code,
        newPassword: data.password,
      });
      navigate('/resetting-password/success');
    } else {
      dispatch(openModal(<ModalError errorMessage="Не удалось изменить пароль." />));
    }
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-4">Придумайте новый пароль</div>
      <ResettingFormStepThree
        value={{
          password: '',
          password_repeat: '',
        }}
        submit={onSubmit}
      />
    </div>
  );
}
