import { styled, Typography as MTypography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Button } from '@components/MUI/Button';
import styles from './RateYandexCard.module.scss';
import iconDone from '@assets/img/rates/done.svg';

export const TypographyRate = styled(MTypography)`
  color: var(--count-light);
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 18px;
  font-weight: 400;

  &:before {
    content: '';
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-top: 5px;
    background-image: url(${iconDone});
  }
`;

interface RateYandexCardProps {
  title: string;
  price: string;
  oldPrice: string;
  timeOfUSe: string;
  items: ReactNode;
  btnText: string;
  profitable?: boolean;
  onClickConnect: () => void;
}

export const RateYandexCard: React.FC<RateYandexCardProps> = ({
  title,
  price,
  oldPrice,
  timeOfUSe,
  items,
  btnText,
  profitable,
  onClickConnect,
}) => {
  const cardClassName = `${styles.rateYandexCard} ${profitable ? styles.profitable : ''}`;

  return (
    <>
      <div className={cardClassName}>
        <p className={styles.title}>{title}</p>
        <div className={styles.priceBlock}>
          <p className={styles.price}>{price}</p>
          <p className={styles.oldPrice}>{oldPrice}</p>
        </div>
        <p className={styles.timeOfUse}>{timeOfUSe}</p>
        <hr />
        <div className={styles.items}>{items}</div>
        <div className={styles.btnBlock}>
          <Button className="w-100" variant="contained" onClick={onClickConnect}>
            {btnText}
          </Button>
        </div>
      </div>
    </>
  );
};
