import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { NavLink } from 'react-router-dom';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import { getTasksFavorite, setTasksFavorite } from '@store/tasks/tracker.service';
import { statusColors, StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { GetTasksResponse, Task } from '@store/tasks/contracts';
import { Star } from '@components/Star/Star';
import { setTasksCount } from '@store/favorite/favorite.slice';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

export const FavoriteTasks: React.FC = () => {
  const dispatch = useAppDispatch();
  const [tasksFavorites, setTasksFavorites] = useState<GetTasksResponse[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);

  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: fetchedData, total } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: () => getTasksFavorite(),
    additionalParams: requestBody,
    refetch: refetch,
  });

  useEffect(() => {
    setTasksFavorites(fetchedData as GetTasksResponse[]);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          name: {
            label: 'Задача',
            children: (value, row) => (
              <NavLink to={`/tasks/${row.projectId}/${row.id}`}>{value}</NavLink>
            ),
            filterInput: true,
            isSortColumn: true,
          },
          status: {
            label: 'Статус',
            children: (value) => <StatusLabel text={value} color={statusColors.get(value)} />,
            filterInput: true,
            isSortColumn: true,
          },
          projectName: {
            label: 'Проект',
            children: (value, row) => (
              <NavLink to={`/projects/${row.projectId}/details`}>{value}</NavLink>
            ),
            filterInput: true,
            isSortColumn: true,
          },
          executor: {
            label: 'Исполнитель',
            children: (value, row) =>
              row.executor ? (
                <NavLink to={`/userPage/${row.executor}`}>{value}</NavLink>
              ) : (
                <p>Не назначен</p>
              ),
            filterInput: true,
            isSortColumn: true,
          },
          created: {
            label: 'Дата создания',
            filterInput: true,
            isSortColumn: true,
          },
          changed: {
            label: 'Дата завершения',
            filterInput: true,
            isSortColumn: true,
          },
          star: {
            children: (_, row: Task) => (
              <div style={{ marginRight: '1rem' }}>
                <Star
                  checked={true}
                  onChange={(value) =>
                    setTasksFavorite({ status: value }, row.id)
                      .then(() => dispatch(setTasksCount(total - 1)))
                      .then(() => setRefetch((prev) => !prev))
                  }
                />
              </div>
            ),
          },
        }}
        rows={tasksFavorites}
        emptyLabel={'Задач в избранном нет'}>
        <TablePaginationMUI
          limit={limit}
          count={total}
          entityLabel="Задач"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </>
  );
};
