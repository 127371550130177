import styles from './CustomerPage.module.scss';
import { Button } from '@components/MUI/Button';
import createTask from '@assets/img/customer/createTask.svg';
import customer from '@assets/img/customer/customer.png';
import { CardsCustomer } from '@components/Card/CardsCustomer';
import message from '@assets/img/customer/message.svg';
import grafics from '@assets/img/customer/grafics.svg';
import product from '@assets/img/customer/product.svg';
import developer from '@assets/img/customer/developer.png';
import { CardWithTabsAdaptive } from '@components/Card/CardWithTabsAdaptive';
import { Typography as MTypography } from '@components/AboutInfo/AboutInfo';
import { styled } from '@mui/material';
import tilda from '@assets/img/aboutInfo/tilda.svg';
import { CardFeatures } from '@components/Card/Card';
import spec_state from '@assets/img/customer/spec_state.svg';
import hands from '@assets/img/customer/hands.svg';
import money from '@assets/img/customer/money.svg';
import flexble from '@assets/img/customer/flexble.svg';
import analitics from '@assets/img/customer/analitics.svg';
import shedule from '@assets/img/customer/shedule.png';
import { Link } from 'react-router-dom';

const Typography = styled(MTypography)`
  color: var(--count-light);
  font-size: 18px;
  font-weight: 400;

  &:before {
    content: url(${tilda});
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const CustomerPage = () => {
  return (
    <>
      <div className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div className={styles.mainInfoSign}>
              <p className={styles.title}>
                Egile — от найма отдельных специалистов
                <br /> в команду до проектов <span>«под ключ»</span>
              </p>
              <div className={styles.mainInfoBtn}>
                <Link to="/authorization">
                  <Button className={styles.btn} variant="contained">
                    Опубликовать проект
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              <img src={customer} className={styles.iconCustomer} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainPopular}>
        <div className={styles.container}>
          <h1 className={styles.title_popular}>Мы работаем под ключ</h1>
          <div className={styles.wrapperCardsCustomer}>
            <CardsCustomer
              cardNumber="01"
              cardTitle="Размещайте предварительное задание по проекту"
              cardDescription="Укажите основные критерии проекта: что необходимо сделать,
               какие есть критерии разработки и в какой срок необходимо завершить проект.
               Установите бюджет проекта. Для полноты понимания можете прикрепить документы, файлы, картинки."
              cardImg={createTask}
            />
            <CardsCustomer
              cardNumber="02"
              cardTitle="Менеджер свяжется с Вами, уточнит детали и запустит проект в работу"
              cardDescription="После утверждения проектного менеджера, с ним можно будет связаться через общий чат на платформе.
               Утвердите основные цели и задачи проекта, договоритесь о сроках и бюджете проекта.
               Далее менеджер переводит вашу заявку в проект и работа над ним начинается."
              cardImg={message}
            />
            <CardsCustomer
              cardNumber="03"
              cardTitle="Получите всю аналитику по проектам в разделе «Дашборды»"
              cardDescription="Вы можете в удобной форме просматривать работу команды над проектом.
               Для вас доступна информация по статусу и времени выполнения задач.
               Уточняйте готовность проекта через чат на платформе."
              cardImg={grafics}
            />
            <CardsCustomer
              cardNumber="04"
              cardTitle="Принимайте готовый продукт"
              cardDescription="Менеджер по готовности проекта проведет демонстрацию выполненных работ в удобном
               для Вас формате. У Вас есть возможность отправить на доработку задачи, внести коррективы.
               Если проект выполнен согласно требованиям,
               то необходимо перевести проект в статус принятой работы."
              cardImg={product}
            />
          </div>
          <div className={styles.tabletCardsCustomer}>
            <CardWithTabsAdaptive
              cardNumber="01"
              cardTitle="Размещайте предварительное задание по проекту"
              cardDescription="Укажите основные критерии проекта: что необходимо сделать, какие есть критерии разработки и в
						   какой срок необходимо завершить проект. Установите бюджет проекта.
						   Для полноты понимания можете прикрепить документы, файлы, картинки."
              img={createTask}
            />
            <div className={styles.card_connectionElement}></div>
            <CardWithTabsAdaptive
              cardNumber="02"
              cardTitle="Менеджер свяжется с Вами, уточнит детали и запустит проект в работу"
              cardDescription="После утверждения проектного менеджера, с ним можно будет связаться через общий чат на платформе.
               Утвердите основные цели и задачи проекта, договоритесь о сроках и бюджете проекта.
               Далее менеджер переводит вашу заявку в проект и работа над ним начинается."
              img={message}
            />
            <div className={styles.card_connectionElement}></div>
            <CardWithTabsAdaptive
              cardNumber="03"
              cardTitle="Получите всю аналитику по проектам в разделе «Дашборды»"
              cardDescription="Вы можете в удобной форме просматривать работу команды над проектом.
               Для вас доступна информация по статусу и времени выполнения задач.
               Уточняйте готовность проекта через чат на платформе."
              img={grafics}
            />
            <div className={styles.card_connectionElement}></div>
            <CardWithTabsAdaptive
              cardNumber="04"
              cardTitle="Принимайте готовый продукт"
              cardDescription="Менеджер по готовности проекта проведет демонстрацию выполненных работ в удобном
               для Вас формате. У Вас есть возможность отправить на доработку задачи, внести коррективы.
               Если проект выполнен согласно требованиям,
               то необходимо перевести проект в статус принятой работы."
              img={product}
            />
            <div className={styles.card_connectionElement}></div>
          </div>
        </div>
      </div>
      <div className={styles.aboutControl}>
        <div className={styles.container}>
          <div className={styles.about_block}>
            <div className={styles.about_common}>
              <h1 className={styles.about_title}>
                Предпочитаете держать все под личным контролем?
              </h1>
              <span className={styles.about_description}>
                Возьмите управление проекта в свои руки и станьте менеджером своего проекта
              </span>
              <Typography>
                Сами находите исполнителей на платформе или заходите вместе со своей командой
              </Typography>
              <Typography>Сами ставьте и распределяйте задачи</Typography>
              <Typography>Контролируйте весь процесс</Typography>
            </div>
            <div>
              <img src={developer} className={styles.about_icon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainPopular}>
        <div className={styles.container}>
          <h1 className={styles.title_benefits}>Наши особенности и преимущества</h1>
          <div className={styles.about_gridLayout}>
            <CardFeatures
              cardDescription="Наличие базы специалистов под любой запрос"
              iconURL={spec_state}
            />
            <CardFeatures
              cardDescription="Аналитика для отслеживания актуального статуса работы удаленной команды"
              iconURL={analitics}
            />
            <CardFeatures
              cardDescription="Уменьшение затрат на содержание штатных команд"
              iconURL={money}
            />
            <CardFeatures
              cardDescription="Быстрая и гибкая масштабируемость бизнеса, благодаря исполнению проектов на аутсорсе"
              iconURL={flexble}
            />
            <CardFeatures
              cardDescription="Прозрачность и безопасность финансовых отношений благодаря сервису «Безопасная сделка»"
              iconURL={hands}
            />
          </div>
        </div>
      </div>
      <div className={styles.aboutControl}>
        <div className={styles.container}>
          <div className={styles.about_block}>
            <div className={styles.about_common}>
              <h1 className={styles.about_title}>
                Вы всегда будете в курсе <br className={styles.hideBr} /> актуального состояния
                работы
              </h1>
              <span className={styles.about_description}>
                Аналитика по Вашим проектам доступна в личном кабинете
              </span>
              <Typography>Современный подход к визуализации аналитической информации</Typography>
              <Typography>Графики по ключевым показателям</Typography>
              <Typography>Контроль выполнения задач и соблюдения сроков</Typography>
              <Typography>Удобное формирование отчетности</Typography>
              <Typography>Отслеживание финансовых расчетов</Typography>
            </div>
            <div>
              <img src={shedule} className={styles.about_icon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
