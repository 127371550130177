import { Link, useNavigate } from 'react-router-dom';
import { useRegistrationMutation } from '@store/user/user.slice';
import { RegistrationForm, RegistrationFormProps } from '@pages/authentication/RegistrationForm';
import styles from '@pages/authentication/Authentication.module.scss';

export function Registration() {
  const navigate = useNavigate();
  const [userRegistration] = useRegistrationMutation();

  const register: RegistrationFormProps['submit'] = async (registrationForm, form) => {
    try {
      await userRegistration({
        email: registrationForm.email,
      }).unwrap();
      navigate('/registration/verify-mail');
    } catch (e: any) {
      form.setErrors({
        email: e.message,
      });
    }
  };
  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Регистрация</div>
      <div style={{ marginBottom: '32px' }}>
        Уже есть профиль?
        <Link
          to="/authorization"
          className="ms-1 color-green color-green_hover text-decoration-underline fw-500">
          Войдите
        </Link>
      </div>
      <div className="display-flex flex-direction-column">
        <RegistrationForm
          value={{
            email: '',
            agreement: false,
          }}
          submit={register}
        />
      </div>
    </div>
  );
}
