import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { TasksNavigationBar } from '@layouts/task/TasksNavigationBar';

export type ProjectsIds = {
  projectIds: string[];
};

export type TaskContext = [ProjectsIds];

export function TasksLayout() {
  const [context, setContext] = useState<ProjectsIds>({ projectIds: [] });

  return (
    <MainContentLayout>
      <TasksNavigationBar setContext={setContext} />
      <Outlet context={[context]} />
    </MainContentLayout>
  );
}
