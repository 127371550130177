import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetOauthProvidersQuery, useLoginMutation } from '@store/user/user.slice';
import { LoginForm, LoginFormProps } from './LoginForm';
import VK from '@assets/img/authorization/VK.svg';
import Yandex from '@assets/img/yandex.svg';
import { AUTH_PROVIDER, IdentifierType, OAUTH_REDIRECT } from '@store/user/contracts';
import styles from './Authentication.module.scss';

export function Authorization() {
  const navigate = useNavigate();

  const { data: vkProviders } = useGetOauthProvidersQuery(OAUTH_REDIRECT.VK_REDIRECT);
  const { data: yandexProviders } = useGetOauthProvidersQuery(OAUTH_REDIRECT.YANDEX_REDIRECT);

  const vkProvider = useMemo(() => {
    const vkProvider = vkProviders?.find((provider) => provider!.name === AUTH_PROVIDER.VK)?.url;
    return vkProvider;
  }, [vkProviders]);

  const yandexProvider = useMemo(() => {
    const yandexProvider = yandexProviders?.find(
      (provider) => provider!.name === AUTH_PROVIDER.YANDEX,
    )?.url;
    return yandexProvider;
  }, [yandexProviders]);

  const [userLogin] = useLoginMutation();

  const login: LoginFormProps['submit'] = async (loginForm, form) => {
    try {
      form.setSubmitting(true);
      await userLogin({
        identifierTypeId: IdentifierType.Email,
        identifierValue: loginForm.email,
        password: loginForm.password,
      }).unwrap();
      navigate('/');
    } catch (e: any) {
      form.setErrors({
        email: e.message,
      });
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Войти</div>
      <div style={{ marginBottom: '24px' }}>
        Новый пользователь?
        <Link
          to="/registration"
          className="ms-1 color-green color-green_hover fw-500 text-decoration-underline">
          Создать учетную запись
        </Link>
      </div>
      <div className="d-flex flex-column mb-2 text-secondary">
        <div>
          Войти через
          <div className="mb-4 d-flex mt-2">
            <a href={vkProvider} className="me-3">
              <img src={VK} alt="VK_icon" />
            </a>
            <a href={yandexProvider}>
              <img src={Yandex} alt="Yandex_icon" />
            </a>
          </div>
        </div>
        <div>или Email</div>
      </div>
      <LoginForm
        value={{
          email: '',
          password: '',
        }}
        submit={login}
      />
    </div>
  );
}
