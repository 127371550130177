import { Outlet } from 'react-router-dom';
import { JobsWithoutRegistration } from '@pages/main/JobsWithoutRegistration';

const OpenProjectLayout = () => {
  return (
    <div>
      <div>
        <JobsWithoutRegistration />
        <Outlet />
      </div>
    </div>
  );
};

export { OpenProjectLayout };
