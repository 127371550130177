import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ProjectsHeader } from '@components/ui/ProjectsHeader/ProjectsHeader';
import styles from '../projects/Project/Project.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';

export const ProjectDetail = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { data: project } = useDetailProjectQuery(projectId || '');

  if (!projectId) {
    navigate('/projects');
    return null;
  }

  if (project) {
    return (
      <div className="col container-content">
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <ProjectsHeader projectId={projectId} />
            <Outlet />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
