import InfiniteScroll from 'react-infinite-scroll-component';
import { RequisitesCard } from './components/RequisitesCard';
import { useListRequisitesQuery } from '@store/requisite/requisite.slice';
import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

const Requisites: React.FC = () => {
  const [start, setStart] = useState(0);

  const { data } = useListRequisitesQuery({
    sortOrder: 'asc',
    start: start,
    limit: 10,
  });

  const hasMore = useMemo(() => {
    if (data?.data && data?.totalCount && data?.data.length < data?.totalCount) return true;
    return false;
  }, [data]);

  if (data?.data && data.data.length === 0) {
    return (
      <div>
        <div className="mt-4 text-center">
          <h6 className="text-secondary">У вас пока нет реквизитов</h6>
        </div>
      </div>
    );
  }
  return (
    <div>
      <InfiniteScroll
        className="row"
        dataLength={data?.data?.length || 0}
        next={() => setStart(start + 10)}
        hasMore={hasMore}
        loader={<Skeleton />}>
        {data?.data?.map((item) => (
          <div className="col-12 col-md-4 mb-4" key={item.id}>
            <RequisitesCard data={item} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export { Requisites };
