import { api } from '@store/api-client';
import { PathParameters, RequestBody, ResponseBody } from '@store/utility';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { enqueueSnackbar } from 'notistack';
import { GetOffersResponse } from './contracts';

const offerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOffer: builder.mutation<
      ResponseBody<Api.operations['createOffer']>,
      RequestBody<Api.operations['createOffer']>
    >({
      query: (requestBody) => ({
        method: `POST`,
        url: '/api/offer',
        body: requestBody,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          const message = arg.fromManager
            ? 'Оффер успешно отправлен'
            : 'Отклик на вакансию отправлен';
          enqueueSnackbar(message, {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['VacanciesExecutor', 'TeamsList', 'Vacancy'],
    }),

    getOffers: builder.query<GetOffersResponse, RequestBody<Api.operations['getOffers']>>({
      query: (requestBody) => ({
        method: `POST`,
        url: 'api/offer/list',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getOffers']>) => {
        return plainToInstance(GetOffersResponse, response);
      },
      providesTags: ['OffersList'],
    }),

    denyOffer: builder.mutation<
      ResponseBody<Api.operations['denyOffer']>,
      PathParameters<Api.operations['denyOffer'], 'offerId'>
    >({
      query: (offerId) => ({
        method: `POST`,
        url: `/api/offer/deny/${offerId}`,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Оффер отклонен', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['DashboardVacanciesAnalytics', 'MyOffers', 'VacancyResponses', 'Vacancy'],
    }),

    acceptOffer: builder.mutation<
      ResponseBody<Api.operations['acceptOffer']>,
      PathParameters<Api.operations['acceptOffer'], 'offerId'>
    >({
      query: (offerId) => ({
        method: `POST`,
        url: `/api/offer/accept/${offerId}`,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.then(() => {
          enqueueSnackbar('Оффер принят', {
            variant: 'success',
          });
        });
      },
      invalidatesTags: ['DashboardVacanciesAnalytics', 'MyOffers', 'VacancyResponses', 'Vacancy'],
    }),
  }),
});

export const { useCreateOfferMutation, useDenyOfferMutation, useAcceptOfferMutation } = offerApi;
