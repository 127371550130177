import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { object, string } from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select, SelectOption } from '@components/MUI/Select';
import { SelectChangeEvent } from '@mui/material';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import styles from './AddTime.module.scss';

type AddTimeValue = {
  time: string;
  timeFormat: string;
  date: string | null;
  comment: string;
};

type AddTimeFormProps = {
  submit: (value: AddTimeValue, form: FormikBag<AddTimeFormProps, AddTimeValue>) => void;
};

const addTimeSchema = object({
  time: string().required('Заполните время'),
  date: string().trim().required('Заполните дату'),
});

const AddTime: React.FC = () => {
  const dispatch = useAppDispatch();
  const form = useFormikContext<AddTimeValue>();

  const handleTimeFormat = (event: SelectChangeEvent) => {
    form.setFieldValue('timeFormat', event.target.value);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <Form className={styles.add__time__form}>
      <div>
        <p className={styles.add__time__form__title}>Потраченное время</p>
        <div className={styles.add__time__form__time}>
          <InputField
            name="time"
            type="number"
            className={styles.add__time__form__time__value}
            isLabel={false}
            placeholder=""
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                top: '0px',
              },
            }}
          />
          <Select
            name="timeFormat"
            onChange={(event) => handleTimeFormat(event)}
            values={timeFormatValues}
            isOutlined
            className={styles.add__time__form__time__format}
            value={form.values.timeFormat}
          />
        </div>
        <InputField
          name="date"
          type="date"
          label="Дата*"
          className={styles.add__time__form__date}
        />
        <InputField
          name="comment"
          type="text"
          placeholder="Комментарий"
          isLabel={false}
          className={styles.add__time__form__comment}
          multiline
          rows={4}
        />
        <div className={styles.add__time__form__buttons}>
          <Button className={styles.add__time__form__buttons__cancel} onClick={handleCancel}>
            Отменить
          </Button>
          <Button variant="contained" type="submit">
            Сохранить
          </Button>
        </div>
      </div>
    </Form>
  );
};

export const AddTimeForm = withFormik<AddTimeFormProps, AddTimeValue>({
  displayName: 'AddTimeForm',
  mapPropsToValues: (props) => {
    return {
      time: '',
      timeFormat: '1',
      date: ' ',
      comment: '',
      submit: props.submit,
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: addTimeSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddTime);

const timeFormatValues: SelectOption[] = [
  {
    label: 'Минут(ы)',
    value: '1',
  },
  {
    label: 'Час(ы)',
    value: '2',
  },
];
