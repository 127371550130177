import styles from '@pages/main/JobsWithoutRegistration.module.scss';
import { JobCharacteristicsForm } from '@components/JobCharacteristicsForm/JobCharacteristicsForm';
import { JobCard } from '@components/JobCard/JobCard';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import {
  publicVacancy,
  selectIsLoadingPublicVacancies,
  selectPublicVacancies,
} from '@store/publicVacancy/publicVacancy.slice';
import dayjs from 'dayjs';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { CircularProgress } from '@mui/material';
import { Button } from '@components/MUI/Button';
import { Link } from 'react-router-dom';

export const JobsWithoutRegistrationExecutor = () => {
  const publicVacancies = useAppSelector(selectPublicVacancies);
  const roles = useAppSelector(selectDictionary(DictionaryName.ROLE));
  const isLoading = useAppSelector(selectIsLoadingPublicVacancies);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(publicVacancy({ start: 0, limit: 5, sortOrder: 'desc' }));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.main}>
          <div className={styles.settingSearch}>
            <JobCharacteristicsForm
              value={{
                start: 0,
                limit: 10,
                sortOrder: 'desc',
                stack: [],
                experienceFrom: 1,
                experienceTo: 2,
                hourPriceFrom: 0,
                hourPriceTo: 1000,
                monthPriceFrom: 1000,
                monthPriceTo: 5000,
                statusId: [],
                rating: 10,
                location: 'Russia',
                sortField: '',
                roleId: roles.find((role) => role.code === 'EXECUTOR')!.id,
              }}
              submit={() => {}}
            />
          </div>
          {/*Todo: добавить поля на бэке, которые будут принимать "Направления", "Стоимость" "Стек", затем сделать
       интеграцию
       */}
          <div className={styles.jobBox}>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : publicVacancies.length > 0 ? (
              publicVacancies.map((job: any) => (
                <JobCard
                  key={job.id}
                  name={dayjs(job.created).format('DD.MM.YYYY')}
                  title={job.name}
                  status={job.statusName}
                  role={job.positionName}
                  stack={job.stack}
                  payment={job.payment}
                  titleBtn="Откликнуться"
                />
              ))
            ) : (
              <div className="d-flex justify-content-center text-secondary fs-6 fw-bold">
                Нет доступных вакансий
              </div>
            )}
            {publicVacancies.length > 0 ? (
              <div className="d-flex justify-content-center mt-4">
                <Link to="/registration">
                  <Button variant="outlined">Загрузить ещё</Button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
