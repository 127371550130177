import { useState } from 'react';
import { AnalyticsTasks } from '@store/dashboard/contracts';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';

const dataAboutTasks = [
  {
    team: 'Команда1',
    project: 'Проект1',
    newTasks: 21,
    inProgress: 4,
    inTesting: 7,
    done: 24,
    overdue: 12,
  },
  {
    team: 'Команда2',
    project: 'Проект2',
    newTasks: 21,
    inProgress: 4,
    inTesting: 7,
    done: 24,
    overdue: 12,
  },
  {
    team: 'Команда3',
    project: 'Проект3',
    newTasks: 21,
    inProgress: 4,
    inTesting: 7,
    done: 24,
    overdue: 12,
  },
  {
    team: 'Команда4',
    project: 'Проект4',
    newTasks: 21,
    inProgress: 4,
    inTesting: 7,
    done: 24,
    overdue: 12,
  },
];

export const DashboardInfoAboutTasks = () => {
  const [data] = useState<AnalyticsTasks[]>(dataAboutTasks);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [requestBody] = useState({});

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  const handleChangeRowsPerPage = (newLimit: number) => {
    handleLimitChange(newLimit);
    setPage(1);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    event?.preventDefault();
    setPage(newPage);
  };

  return (
    <div className="col">
      <div className="mt-4 ms-4">
        {dataAboutTasks.length > 0 ? (
          <AdaptiveTableMUI
            filter={requestBody}
            rows={data}
            columns={{
              team: {
                label: 'Команда',
                children: (value) => <a href="/team">{value}</a>,
              },
              project: {
                label: 'Проект',
                children: (value) => <a href="/project">{value}</a>,
              },
              newTasks: {
                label: 'Новые',
              },
              inProgress: {
                label: 'В работе',
              },
              inTesting: {
                label: 'В тестировании',
              },
              done: {
                label: 'Завершенные',
              },
              overdue: {
                label: 'Просроченные',
              },
            }}>
            <TablePaginationMUI
              limit={limit}
              count={100}
              entityLabel="задач"
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </AdaptiveTableMUI>
        ) : (
          <div className="text-lg-center color-gray fw-600">
            <p>Информация по задачам отсутствует</p>
          </div>
        )}
      </div>
    </div>
  );
};
