import { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

interface Props {
  children: ReactNode;
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
  }
}

const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007236',
    },
    secondary: {
      main: '#007236',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
    action: {
      disabledBackground: `#8CCD9E`,
      disabled: '#8CCD9E',
    },
    error: {
      main: '#e04040',
    },
    text: {
      primary: '#39393A',
      secondary: '#78858B',
    },
  },
  breakpoints: {
    values: {
      mobile: 767,
      tablet: 991,
    },
  },
  zIndex: {
    modal: 3100,
  },
  charts: {
    colorPalette: [
      '#FF5733',
      '#33FF57',
      '#3357FF',
      '#FF33FF',
      '#FFFF33',
      '#33FFFF',
      '#FF3333',
      '#33FF33',
      '#3333FF',
      '#FF33A1',
      '#FFA533',
      '#33FFA5',
      '#A533FF',
      '#A5FF33',
      '#33A5FF',
      '#FF57A5',
      '#A5A533',
      '#A533A5',
      '#57FFA5',
      '#A5A5FF',
    ],
  },
  statuses: {
    typesColorPalette: {
      Начальный: '#3485FF',
      'На паузе': '#F4CB3A',
      'В работе': '#E04040',
      Завершен: '#10C943',
      Отменен: '#78858B',
    },
  },
});

export const ApplicationThemeProvider = ({ children }: Props) => {
  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
};

declare module '@mui/material/styles' {
  interface Theme {
    charts: {
      colorPalette: string[];
    };
    statuses: {
      typesColorPalette: Record<string, string>;
    };
  }
  interface ThemeOptions {
    charts?: {
      colorPalette?: string[];
    };
    statuses?: {
      typesColorPalette?: Record<string, string>;
    };
  }
}
