import styles from '@pages/rates/RatesInfo.module.scss';
import { Typography } from '@components/AboutInfo/AboutInfo';
import customer from '@assets/img/customer/customer.png';
import { Box } from '@mui/material';
import { RatesPageForCustomer } from '@pages/rates/ratesCard/RatesPageForCustomer';

export const RatesInfo = () => {
  return (
    <>
      <div className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div>
              <p className={styles.title}>Тарифы</p>
              <p className={styles.subTitle}>
                Подписка на «Эджайл» — это возможность для Пользователя за абонентскую плату в
                течение определенного промежутка времени получать удаленный доступ к платформе
              </p>
              <div className={styles.description}>
                <Typography>Для новых пользователей подписка на 3 месяца бесплатная</Typography>
                <Typography>
                  Для расширения возможностей выберете подходящий для вас тариф
                </Typography>
              </div>
            </div>
            <div>
              <img src={customer} className={styles.specialistsPic} alt="Заказчик" />
            </div>
          </div>
        </div>
      </div>
      <Box>
        <div className={styles.addGoodsWrapper}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <h2 className={styles.title_rates}>Тарифы</h2>
              <RatesPageForCustomer />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
