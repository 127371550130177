import { List, styled, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { ReactComponent as Cloud } from '@assets/img/cloud.svg';
import { ReactComponent as Dashboard } from '@assets/img/dashboard.svg';
import { ReactComponent as Tasks } from '@assets/img/tasks.svg';
import { ReactComponent as Messages } from '@assets/img/messages.svg';
import { ReactComponent as Projects } from '@assets/img/projects.svg';
import { useAppSelector } from '@hooks/index';
import { ButtonSidebar } from '@components/Sidebar/ButtonSidebar';
import { Can } from '@access-control';

export const Sidebar = () => {
  const open = useAppSelector((state) => state.ui.sidebarVisibility);
  const isTablet = useMediaQuery('(max-width: 991px)');

  const openedMixin = () => ({
    width: '188px',
    transition: 'all 0.5s ease-in-out',
    overflowX: 'hidden',
    zIndex: 5,
  });

  const closedMixin = () => ({
    transition: 'all 0.5s ease-in-out',
    width: isTablet ? '0px' : '82px',
    overflowX: 'hidden',
    zIndex: 5,
  });

  const getSidebarStyles = (open: any) => {
    if (open) {
      return openedMixin();
    } else {
      return closedMixin();
    }
  };
  const Drawer = styled(MuiDrawer)({
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      position: 'sticky',
      ...getSidebarStyles(open),
      '& .MuiDrawer-paper': getSidebarStyles(open),
      transition: 'width 0.5s ease',
      zIndex: 5,
    },
    '&.MuiDrawer-docked.MuiDrawer-root': {
      position: 'sticky',
      top: 0,
    },
  });

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <List style={{ paddingTop: '66px' }}>
          <Can I="read" a="Dashboard">
            <ButtonSidebar href={`/dashboard/projects`} text="Дашборд" open={open}>
              <Dashboard />
            </ButtonSidebar>
          </Can>
          <Can I="read" a="Projects">
            <ButtonSidebar href="/projects" text="Проекты" open={open}>
              <Projects />
            </ButtonSidebar>
          </Can>
          <Can I="create" a="Task">
            <ButtonSidebar href="/tasks" text="Задачи" open={open}>
              <Tasks />
            </ButtonSidebar>
          </Can>
          <ButtonSidebar href="/clouds" text="Облачный ДЦ" open={open}>
            <Cloud />
          </ButtonSidebar>
          <ButtonSidebar href="/messages" text="Сообщения" open={open}>
            <Messages />
          </ButtonSidebar>
        </List>
      </Drawer>
    </>
  );
};
