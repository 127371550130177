import { useFormikContext, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Button } from '@components/MUI/Button';
import styles from './Requisite.module.scss';
import { InputField } from '@components/MUI/InputField/InputField';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';

type RequisiteFormProps = {
  value: RequisiteFormValue;
  submit: (value: RequisiteFormValue) => void;
};

export type RequisiteFormValue = {
  id?: string;
  accountNumber: string;
  bankName: string;
  bik: number | null;
  corporateAccount: string;
  inn: number | null;
  kpp: number | null;
};

const updateRequisiteSchema = Yup.object({
  accountNumber: Yup.string()
    .matches(
      /^(?!(.*-)|\D*$)\d{20,}$/,
      'Поле должно содержать минимум 20 цифр и не должно содержать других символов',
    )
    .required('Поле обязательно для заполнения'),
  bankName: Yup.string().required('Поле обязательно для заполнения'),
  bik: Yup.string()
    .length(9, 'Поле должно быть длиной 9 символов')
    .matches(/^\d{9}$/, 'Поле должно содержать 9 цифр и не должно содержать других символов')
    .nullable()
    .required('Поле обязательно для заполнения'),
  corporateAccount: Yup.string()
    .matches(
      /^(?!(.*-)|\D*$)\d{20,}$/,
      'Поле должно содержать 20 цифр и не должно содержать других символов',
    )
    .required('Поле обязательно для заполнения')
    .length(20, 'Поле должно быть длиной 20 символов'),
  inn: Yup.string()
    .nullable()
    .length(12, 'Поле должно быть длиной 12 символов')
    .matches(
      /^\d{10}$|^\d{12}$/,
      'ИНН должен содержать 10 или 12 цифр и не должен содержать других символов',
    )
    .required('Поле обязательно для заполнения'),
  kpp: Yup.string()
    .nullable()
    .length(9, 'Поле должно быть длиной 9 символов')
    .matches(
      /^(?!(.*-)|\D*$)\d{9,}$/,
      'Поле должно содержать 9 цифр и не должно содержать других символов',
    )
    .required('Поле обязательно для заполнения'),
});

const FormComponent = () => {
  const reqUpdatingForm = useFormikContext<RequisiteFormValue>();

  return (
    <Form onSubmit={reqUpdatingForm.handleSubmit}>
      <div className={styles.reqForm}>
        <InputField
          name="accountNumber"
          placeholder="Номер счёта*"
          type="text"
          className={styles.field}
        />
        <InputField
          name="bankName"
          placeholder="Банк получателя*"
          type="text"
          className={styles.field}
        />
        <InputField name="bik" placeholder="БИК*" type="text" className={styles.field} />
        <InputField
          name="corporateAccount"
          placeholder="Корреспондентский счёт*"
          type="text"
          className={styles.field}
        />
        <InputField name="inn" placeholder="ИНН*" type="text" className={styles.field} />
        <InputField name="kpp" placeholder="КПП*" type="text" className={styles.field} />
      </div>
      <div className="req-btn-small mt-5">
        <Button type="submit" variant="contained" disabled={!reqUpdatingForm.isValid}>
          Сохранить
        </Button>
        <RequiredFieldsIndicator />
      </div>
    </Form>
  );
};

export const RequisiteForm = withFormik<RequisiteFormProps, RequisiteFormValue>({
  displayName: 'ReqUpdatingForm',
  mapPropsToValues: (props) => {
    return {
      id: props.value.id,
      accountNumber: props.value.accountNumber,
      bankName: props.value.bankName,
      bik: props.value.bik,
      corporateAccount: props.value.corporateAccount,
      inn: props.value.inn,
      kpp: props.value.kpp,
    };
  },
  validationSchema: updateRequisiteSchema,
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values);
  },
})(FormComponent);
