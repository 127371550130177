import { ButtonHTMLAttributes, FC } from 'react';
import styles from './IconButton.module.scss';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'error' | 'default';
  text?: string;
}

const IconButton: FC<Props> = ({ children, className, text, variant = 'default', ...rest }) => {
  const isTablet = useMediaQuery('(max-width: 991px)');

  return (
    <>
      {isTablet ? (
        <button className={styles.text__button} {...rest}>
          {text}
        </button>
      ) : (
        <button
          className={classNames([styles[`${variant}Variant`], styles.button, className])}
          {...rest}>
          {children}
        </button>
      )}
    </>
  );
};

export { IconButton };
