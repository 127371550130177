import { PieChart } from '@mui/x-charts/PieChart';
import { PieCenterLabel } from '@components/PieCenterLabel/PieCenterLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './TasksPie.module.scss';

type TaskPieProps = {
  statuses: Record<string, number>;
  tasksCount: number;
};

export const TasksPie = ({ statuses, tasksCount }: TaskPieProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <PieChart
      series={[
        {
          data: Object.entries(statuses || {})?.map(([key, value]) => ({
            id: key,
            value: value,
            color: theme.statuses.typesColorPalette[key],

            label: key,
          })),
          innerRadius: !mobile ? 70 : 50,
        },
      ]}
      colors={theme.charts.colorPalette}
      width={!mobile ? 400 : 250}
      height={200}
      className={styles.tasks__pie}
      slotProps={{ legend: { hidden: true } }}>
      <PieCenterLabel label={'Задач'} count={tasksCount} />
    </PieChart>
  );
};
