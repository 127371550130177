import { RatesCardBasic } from '@pages/rates/ratesCard/RatesCardBasic';
import { RatesCardPersonal } from '@pages/rates/ratesCard/RatesCardPersonal';
import styles from '../Rates.module.scss';
import { RatesCardPremium } from '@pages/rates/ratesCard/RatesCardPremium';

const benefits = [
  'До 10 проектов в портфолио',
  'Вывод проектов в топ (доп. опция)',
  'Открытый доступ к проектам для всех пользователей',
  'Выбор 1 направления',
  'До 3 откликов в сутки',
];

const benefitsPersonal = [
  'До 55 проектов в портфолио',
  'Вывод  до 10 проектов в топ',
  'Возможность ограничить доступ к проектам',
  'Выбор 5 направлений',
  'До 20 откликов в сутки',
];

const benefitsPremium = [
  'До 100 проектов в портфолио',
  'Вывод  до 15 проектов в топ',
  'Возможность ограничить доступ к проектам',
  'Неограниченный выбор направлений',
  'Неограниченное число откликов',
];

export const PrivateProfileRatesForExecutor = () => {
  return (
    <div className={styles.gridLayout}>
      <RatesCardBasic list={benefits} title="6 месяцев бесплатно" />
      <RatesCardPersonal list={benefitsPersonal} />
      <RatesCardPremium list={benefitsPremium} />
    </div>
  );
};
