import React, { ElementType, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import styles from './form.module.scss';
import { AnySchema, reach } from 'yup';

type FormFieldProps = {
  name: string;
  placeholder: string;
  type: React.HTMLInputTypeAttribute | undefined;
  className?: string;
  floating?: boolean;
  floatingLabelClassName?: string;
  children?: ReactNode;
  as?: 'input' | 'textarea' | ElementType;
  disabled?: boolean;
  min?: number;
  max?: number;
};

export function FormField({
  name,
  placeholder,
  type,
  className,
  floating,
  floatingLabelClassName,
  children,
  min,
  max,
  as = 'input',
  disabled,
}: FormFieldProps) {
  const form = useFormikContext();
  const fieldSchema: AnySchema | null = form.validationSchema
    ? reach(form.validationSchema, name)
    : null;
  const isRequiredField = Boolean(
    fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required'),
  );
  const meta = form.getFieldMeta<any>(name);
  const hasError = meta.error !== undefined;
  const placeholderField = isRequiredField ? `${placeholder}*` : `${placeholder}`;

  return (
    <Form.Group className={`${styles.input}`} controlId={name}>
      {floating ? (
        <FloatingLabel controlId={name} label={placeholderField} className={floatingLabelClassName}>
          <Form.Control
            value={meta.value || ''}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={meta.touched && hasError}
            className={className}
            type={type}
            placeholder={placeholderField}
            as={as}
            min={min}
            max={max}
            disabled={disabled}
          />
          {children}
          <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        </FloatingLabel>
      ) : (
        <>
          <Form.Control
            value={meta.value}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isInvalid={meta.touched && hasError}
            className={className}
            type={type}
            placeholder={placeholderField}
            as={as}
            min={min}
            max={max}
            disabled={disabled}
          />
          {children}
          <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        </>
      )}
    </Form.Group>
  );
}
