import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { AuthLayout } from '@layouts/AuthLayout';
import { MainLayout } from '@layouts/main/MainLayout';
import { Profile } from '@pages/profile/Profile';
import { Authorization } from '@pages/authentication/Authorization';
import { Registration } from '@pages/authentication/Registration';
import { DashboardAnalyticsRequests } from '@pages/dashboard/DashboardAnalyticsRequests';
import { MessagesLayout } from '@pages/chats/MessagesLayout';
import { Requisites } from '@pages/balance/Requisites';
import { Profit } from '@pages/balance/Profit';
import { BalanceCreateRequisite } from '@pages/balance/BalanceCreateRequisite';
import { ProfileEdit } from '@pages/profile/ProfileEdit';
import { MyProfile } from '@pages/profile/MyProfile';
import { useAppSelector } from '@hooks/index';
import { BalanceUpdateRequisite } from '@pages/balance/BalanceUpdateRequisite';
import { BalanceLayout } from '@layouts/balance/BalanceLayout';
import { Customer } from '@pages/project/Customer';
import { Participants } from '@pages/project/Participants';
import { ProjectDetail } from '@pages/project/ProjectDetail';
import { ProjectPageRedirect } from '@pages/project/ProjectDetailRedirect';
import { CloudsLayout } from '@layouts/cloud/CloudsLayout';
import { Templates } from '@pages/cloud/templates/Templates';
import { Delegations } from '@pages/cloud/delegations/Delegations';
import { DelegationDetail } from '@pages/cloud/delegations/DelegationDetail';
import { CreateDelegationForm } from '@pages/cloud/delegations/CreateDelegationForm';
import { Infrastructures } from '@pages/cloud/infrastructures/Infrastructures';
import { CreateInfrastructureForm } from '@pages/cloud/infrastructures/CreateInfrastructureForm';
import { DeploymentResources } from '@pages/cloud/deployments/DeploymentResources';
import { DeploymentResourceDetail } from '@pages/cloud/deployments/DeploymentResourceDetail/DeploymentResourceDetail';
import { CreateDeploymentResourceForm } from '@pages/cloud/deployments/CreateDeploymentResourceForm';
import { InstanceResources } from '@pages/cloud/instances/InstanceResources';
import { InstanceResourceDetail } from '@pages/cloud/instances/InstanceResourceDetail';
import { CreateInstanceResourceForm } from '@pages/cloud/instances/CreateInstanceResourceForm';
import { DashboardLayout } from '@pages/dashboard/DashboardLayout';
import { DashboardAnalyticsProjects } from '@pages/dashboard/DashboardAnalyticsProjects';
import { DashboardInfoAboutVacancy } from '@pages/dashboard/DashboardInfoAboutVacancy/DashboardInfoAboutVacancy';
import { DashboardInfoAboutTasks } from '@pages/dashboard/DashboardInfoAboutTasks';
import { EmptyChat } from '@pages/chats/messages/EmptyChat';
import { Chat } from '@pages/chats/messages/Chat';
import { isAuthenticated } from '@store/user/user.slice';
import { MainScreen } from '@pages/main/MainScreen';
import { About } from '@components/About/About';
import { PublicLayout } from '@layouts/public/PublicLayout';
import { VacancyPageDetail } from '@pages/vacancy/VacancyPageDetail/VacancyPageDetail';
import { VerifyEmail } from '@pages/authentication/VerifyEmail';
import { Identity } from '@pages/authentication/Identity';
import { PasswordEntryScreen } from '@pages/authentication/PasswordEntryScreen';
import { NotMatch } from '@pages/notMatch/NotMatch';
import { Project } from '@pages/projects/Project/Project';
import { Resetting } from '@pages/authentication/Resetting';
import { CheckEmail } from '@pages/authentication/ResettingCheckEmail';
import { NewPassword } from '@pages/authentication/ResettingNewPassword';
import { Success } from '@pages/authentication/ResettingSuccess';
import { TasksList } from '@pages/task/TasksList';
import { TasksLayout } from '@layouts/task/TasksLayout';
import { TaskPageDetail } from '@pages/task/TaskPageDetail';
import { TaskEdit } from '@pages/task/TaskEdit';
import { TaskCreate } from '@pages/task/TaskCreate';
import { ProjectsLayout } from '@layouts/project/ProjectsLayout';
import { MyRates } from '@pages/rates/MyRates';
import { ProfileLayout } from '@layouts/profile/ProfileLayout';
import { EditProject } from '@pages/projects/Project/EditProject';
import { OpenProjectLayout } from '@layouts/openProject/OpenProjectLayout';
import { JobsWithoutRegistrationCustomer } from '@pages/main/JobsWithoutRegistrationCustomer';
import { JobsWithoutRegistrationExecutor } from '@pages/main/JobsWithoutRegistrationExecutor';
import { CustomerPage } from '@pages/customer/CustomerPage';
import { SpecialistPage } from '@pages/specialist/SpecialistPage';
import { TermsOfUsePage } from '@pages/termsOfUsePage/TermsOfUsePage';
import { FavoriteUsers } from '@pages/favorite/FavoriteUsers/FavoriteUsers';
import { FavoriteTasks } from '@pages/favorite/FavoriteTasks';
import { FavoriteDocuments } from '@pages/favorite/FavoriteDocuments';
import { FavoriteVacancies } from '@pages/favorite/FavoriteVacancies';
import { FavoriteMessages } from '@pages/favorite/messages/FavoriteMessages';
import { FavoriteLayout } from '@layouts/favorite/FavoriteLayout';
import { LegalDocumentList } from '@pages/legalDocuments/legalDocumentsList/LegalDocumentList';
import { LegalDocumentDetail } from '@pages/legalDocuments/legalDocumentDetail/LegalDocumentDetail';
import { Support } from '@pages/support/Support';
import { CommonAnalytics } from '@pages/project/ProjectAnalytics/CommonAnalytics/CommonAnalytics';
import { RatesInfo } from '@pages/rates/RatesInfo';
import { DashboardInfoAboutPay } from '@pages/dashboard/DashboardInfoAboutPay';
import { OAuthAuthorization } from '@pages/authentication/OAuthAuthorization';
import { ProjectSettings } from '@pages/project/ProjectSettings/ProjectSettings';
import { JobsWithoutRegistrationFilters } from '@pages/main/JobsWithoutRegistrationFilters';
import { YandexPublic } from '@pages/yandexPublic/YandexPublic';
import { RegistrationSuccess } from '@pages/authentication/RegistrationSuccess';
import { UniversityPage } from '@pages/university/UniversityPage';
import { ProjectTasks } from '@pages/project/ProjectTasks/ProjectTasks';
import { AdvancedAnalytics } from '@pages/project/ProjectAnalytics/AdvancedAnalytics/AdvancedAnalytics';
import { ProjectAnalyticsLayout } from '@pages/project/ProjectAnalytics/ProjectAnalyticsLayout';
import { Notifications } from '@pages/notifications/Notifications';
import { VacanciesLayout } from '@layouts/vacancies/VacanciesLayout';
import { EditDelegationForm } from '@pages/cloud/delegations/EditDelegationForm';
import { EditInstanceResourceForm } from '@pages/cloud/instances/EditInstanceResourceForm';
import { CreateTemplate } from '@pages/cloud/templates/CreateTemplate/CreateTemplate';
import { EditTemplate } from '@pages/cloud/templates/EditTemplate/EditTemplate';
import { EditInfrastructure } from '@pages/cloud/infrastructures/EditInfrastructure/EditInfrastructure';
import { Landing } from '@pages/landing/Landing';
import { EditDeploymentResource } from '@pages/cloud/deployments/EditDeploymentsResources/EditDeploymentResource';
import { NewsList } from '@pages/news/newsList/NewsList';
import { NewsDetail } from '@pages/news/newsDetail/NewsDetail';
import { ModalsLayoutProvider } from '@layouts/modals/ModalsLayoutProvider';
import { withAuthGuard } from './guards/withAuthGuard';
import { Projects } from '@pages/projects/Projects';
import { CreateProject } from '@pages/projects/CreateProject';

export const useAppRoutes = () => {
  const isAuth = useAppSelector(isAuthenticated);

  const routes = (
    <Route element={<ModalsLayoutProvider />}>
      {/* home page */}
      {isAuth ? (
        <Route element={<MainLayout />}>
          <Route path="/" element={<ProfileLayout />}>
            <Route index element={<MyProfile />}></Route>
            <Route path="/rate" index element={<MyRates />}></Route>
          </Route>
        </Route>
      ) : (
        <Route element={<PublicLayout />}>
          <Route path="/" element={<MainScreen />} />
        </Route>
      )}
      {/* home page */}

      <Route Component={withAuthGuard(AuthLayout, { inverse: true })}>
        <Route path="/authorization" element={<Authorization />} />
        <Route path="/authorization/oauth/yandex" element={<OAuthAuthorization />} />
        <Route path="/authorization/oauth/vk" element={<OAuthAuthorization />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/registration/verify-mail" element={<VerifyEmail />} />
        <Route path="/registration/name" element={<Identity />} />
        <Route path="/registration/password" element={<PasswordEntryScreen />} />
        <Route path="/registration/success" element={<RegistrationSuccess />} />
        <Route path="/resetting-password" element={<Resetting />} />
        <Route path="/resetting-password/check-email" element={<CheckEmail />} />
        <Route path="/resetting-password/new-password" element={<NewPassword />} />
        <Route path="/resetting-password/success" element={<Success />} />
        <Route path="/resetting-password/success" element={<Success />} />
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="/about" element={<About />} />
        <Route path="/legal/privacy" element={<TermsOfUsePage />} />
        <Route element={<OpenProjectLayout />}>
          <Route path="/openProjectsCustomer" element={<JobsWithoutRegistrationCustomer />} />
          <Route path="/openProjectsExecutor" element={<JobsWithoutRegistrationExecutor />} />
        </Route>
        <Route path="/openProjectsFilters" element={<JobsWithoutRegistrationFilters />} />
        <Route path="/rates" element={<RatesInfo />} />
        <Route path="/customer" element={<CustomerPage />}></Route>
        <Route path="/specialist" element={<SpecialistPage />}></Route>
        <Route path="/legalDocuments" element={<LegalDocumentList />}></Route>
        <Route path="/legalDocuments/:code" element={<LegalDocumentDetail />}></Route>
        <Route path="/yandexPay" element={<YandexPublic />}></Route>
        <Route path="/university" element={<UniversityPage />}></Route>
        <Route path="/startup" element={<Landing />}></Route>
        <Route path="/news" element={<NewsList />}></Route>
        <Route path="/news/:newsId" element={<NewsDetail />}></Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path="/support" element={<Support />}></Route>
      </Route>

      <Route Component={withAuthGuard(MainLayout)}>
        <Route path="/edit" element={<ProfileEdit />}></Route>
        <Route path="/clouds" element={<CloudsLayout />}>
          <Route index element={<InstanceResources />} />
          <Route path="/clouds/:instanceResourceId/edit" element={<EditInstanceResourceForm />} />
          <Route path="/clouds/new" element={<CreateInstanceResourceForm />} />
          <Route path="/clouds/templates" element={<Templates />} />
          <Route path="/clouds/templates/new" element={<CreateTemplate />} />
          <Route path="/clouds/templates/:templateId/edit" element={<EditTemplate />} />
          <Route path="/clouds/delegations" element={<Delegations />} />
          <Route path="/clouds/delegations/new" element={<CreateDelegationForm />} />
          <Route path="/clouds/delegations/:delegationId/edit" element={<EditDelegationForm />} />
          <Route path="/clouds/infrastructures" element={<Infrastructures />} />
          <Route path="/clouds/infrastructures/new" element={<CreateInfrastructureForm />} />
          <Route path="/clouds/deployments" element={<DeploymentResources />} />
          <Route path="/clouds/deployments/new" element={<CreateDeploymentResourceForm />} />
          <Route
            path="/clouds/deployments/:deploymentResourceId"
            element={<DeploymentResourceDetail />}
          />
          <Route
            path="/clouds/deployments/:deploymentResourceId/edit"
            element={<EditDeploymentResource />}
          />
        </Route>
        <Route path="/clouds/delegations/:delegationId" element={<DelegationDetail />} />
        <Route path="/clouds/:instanceResourceId" element={<InstanceResourceDetail />} />
        <Route
          path="/clouds/deployments/:deploymentResourceId"
          element={<DeploymentResourceDetail />}
        />
        <Route
          path="/clouds/infrastructures/:infrastructureId/edit"
          element={<EditInfrastructure />}
        />
        <Route path="/clouds/:instanceResourceId/edit" element={<EditInstanceResourceForm />} />
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route path="/dashboard/requests" element={<DashboardAnalyticsRequests />} />
          <Route path="/dashboard/projects" element={<DashboardAnalyticsProjects />} />
          <Route path="/dashboard/infoVacancy" element={<DashboardInfoAboutVacancy />} />
          <Route path="/dashboard/infoTasks" element={<DashboardInfoAboutTasks />} />
          <Route path="/dashboard/infoPay" element={<DashboardInfoAboutPay />} />
        </Route>
        <Route path="/userPage/:userID" element={<Profile />} />
        <Route path="/createRequisite" element={<BalanceCreateRequisite />} />
        <Route path="/tasks" element={<TasksLayout />}>
          <Route index element={<TasksList />} />
          <Route path="/tasks/new" element={<TaskCreate />} />
          <Route path="/tasks/:taskId" element={<TaskPageDetail />} />
          <Route path="/tasks/:taskId/edit" element={<TaskEdit />} />
        </Route>
        <Route path="/vacancies" element={<VacanciesLayout />}></Route>
        <Route path="/vacancies/:vacancyId" element={<VacancyPageDetail />} />
        <Route path="/messages" element={<MessagesLayout />}>
          <Route index element={<EmptyChat />} />
          <Route path="/messages/:chatId" element={<Chat />} />
          <Route path="/messages/:chatId/page/:page/messageId/:messageId" element={<Chat />} />
        </Route>
        <Route path="/balance" element={<BalanceLayout />}>
          <Route index element={<Requisites />} />
          <Route path="/balance/requisites/new" element={<BalanceCreateRequisite />} />
          <Route path="/balance/requisites/:id" element={<BalanceUpdateRequisite />} />
          <Route path="/balance/profit" element={<Profit />}></Route>
        </Route>
        <Route path="/projects" element={<ProjectsLayout />}>
          <Route index element={<Projects />} />
          <Route path="/projects/new" element={<CreateProject />} />
        </Route>
        <Route path="/projects/:projectId/edit" element={<EditProject />} />
        <Route path="/projects/:projectId/settings" element={<ProjectSettings />}></Route>
        <Route path="/projects/:projectId" element={<ProjectDetail />}>
          <Route path="/projects/:projectId/details" element={<Project />} />
          <Route path="/projects/:projectId/customer" element={<Customer />} />
          <Route path="/projects/:projectId/participants" element={<Participants />} />
          <Route path="/projects/:projectId/tasks" element={<ProjectTasks />} />
          <Route path="/projects/:projectId/analytics" element={<ProjectAnalyticsLayout />}>
            <Route index path="/projects/:projectId/analytics" element={<CommonAnalytics />} />
            <Route path="/projects/:projectId/analytics/advanced" element={<AdvancedAnalytics />} />
          </Route>
          <Route path="" element={<ProjectPageRedirect />} />
        </Route>
        <Route path="/favorite" element={<FavoriteLayout />}>
          <Route index path="/favorite" element={<FavoriteUsers />} />
          <Route path="/favorite/tasks" element={<FavoriteTasks />} />
          <Route path="/favorite/documents" element={<FavoriteDocuments />} />
          <Route path="/favorite/vacancies" element={<FavoriteVacancies />} />
          <Route path="/favorite/messages" element={<FavoriteMessages />} />
        </Route>
        <Route path="/notifications" element={<Notifications />} />
      </Route>

      <Route element={<PublicLayout />}>
        <Route path="*" element={<NotMatch />}></Route>
      </Route>
    </Route>
  );

  return createBrowserRouter(createRoutesFromElements(routes));
};
