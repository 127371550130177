import React, { useState } from 'react';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { VacanciesHeader } from '@pages/vacancy/VacanciesHeader/VacanciesHeader';
import { Outlet } from 'react-router-dom';

export const VacanciesLayout: React.FC = () => {
  const [rightSideButton, setRightSideButton] = useState<React.ReactNode>(undefined);

  return (
    <MainContentLayout>
      <VacanciesHeader rightSideButton={rightSideButton} />
      <Outlet context={{ setRightSideButton }} />
    </MainContentLayout>
  );
};
