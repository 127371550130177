import { Outlet } from 'react-router-dom';
import { MainContentLayout } from '@layouts/MainContentLayout';
import { DashboardNavigationBar } from '@pages/dashboard/DashboardNavigationBar';

export function DashboardLayout() {
  return (
    <MainContentLayout>
      <DashboardNavigationBar />
      <Outlet />
    </MainContentLayout>
  );
}
