import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { createElement, ReactElement } from 'react';
import { ModalError } from '@components/ModalError';

interface SidebarState {
  sidebarVisibility: boolean;
  modal: ReactElement | null;
}

export const SIDEBAR_VISIBILITY_KEY = 'sidebarVisibility';

const savedSidebarVisibility = localStorage.getItem(SIDEBAR_VISIBILITY_KEY);
const initialSidebarVisibility = savedSidebarVisibility ? JSON.parse(savedSidebarVisibility) : true;

const initialState: SidebarState = {
  sidebarVisibility: initialSidebarVisibility,
  modal: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarVisibility = !state.sidebarVisibility;
      localStorage.setItem(SIDEBAR_VISIBILITY_KEY, JSON.stringify(state.sidebarVisibility));
    },
    openModal: (state, action: PayloadAction<ReactElement>) => {
      state.modal = action.payload;
    },
    closeModal: (state) => {
      state.modal = null;
    },
    openModalError: (state, action: PayloadAction<Error>) => {
      state.modal = createElement(ModalError, { errorMessage: action.payload.message });
    },
  },
});

export const modalContext = (state: RootState) => state.ui.modal;
export const isModalOpen = (state: RootState) => Boolean(state.ui.modal);

export const { toggleSidebar, openModal, closeModal, openModalError } = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
