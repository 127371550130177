import { PaginationClass, ToApiFile, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export interface SignUpRequest {
  email: string;
}

export enum IdentifierType {
  Email = '54480ce1-00eb-4179-a2b6-f74daa6b9e72',
}

export interface SignUpResponse {
  success: boolean;
}

export interface SignInRequest {
  identifierTypeId: string;
  identifierValue: string;
  password: string;
}

export interface SignInResponse {
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
}

export interface SignOutRequest {
  token: string;
}

export interface SignOutResponse {
  success: boolean;
}

class MyAbout {
  userName!: string;
  @ToApiFile()
  icon!: string;
  firstName!: string;
  lastName!: string;
  patronymic!: string;
  gender!: 'MALE' | 'FEMALE' | null;
  location!: string;
  @ToDate('YYYY-MM-DD')
  birthDate!: Dayjs;
  resumeUrl!: string;
  resume!: FileItem[];
  directionId!: string;
  stack!: string[];
  certificates!: FileItem[];
  about!: string;
  experienceDescription!: string;
  feedbacks!: string[];
  experience!: number;
  roles!: string[];
}

class About {
  userName!: string;
  @ToApiFile()
  icon!: string;
  firstName!: string;
  lastName!: string;
  patronymic!: string;
  gender!: 'MALE' | 'FEMALE' | null;
  location!: string;
  @ToDate('YYYY-MM-DD')
  birthDate!: Dayjs;
  resumeUrl!: string;
  resume!: FileItem[];
  directionId!: string;
  stack!: string[];
  @Type(() => FileItem)
  certificates!: FileItem[];
  about!: string;
  experienceDescription!: string;
  @Type(() => FileItem)
  feedbacks!: FileItem[];
  experience!: number;
}

export class FileItem {
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  extension!: string;
  id!: string;
  mime!: string;
  name!: string;
  @Type(() => Owner)
  owner!: Owner;
  size!: number;
  tag!: string;
}

class Owner {
  storedFileId!: string;
  userId!: string;
  userName!: string;
}

export class Link {
  phones!: string[];
  telegram!: string;
  contract!: boolean;
  email!: string;
  urls!: string[];
}

class Work {
  workStatusId!: string;
  hourPrice!: number | null;
  monthPrice!: number;
  manager!: boolean;
}

export class Organization {
  @ToApiFile()
  logo!: string;
  name!: string;
  url!: string;
  description!: string;
  phone!: string;
  rules!: string;
  inn!: string;
  email!: string;
  officeLocation!: string;
}

export class User {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  @Type(() => About)
  about!: About | null;
  favorite!: boolean;
  @Type(() => Link)
  link!: Link | null;
  @Type(() => Work)
  work!: Work | null;
  @Type(() => Organization)
  organization!: Organization | null;
}

export class UserRole {
  id!: string;
  name!: Role;
}

export enum Role {
  Manager = 'Менеджер',
  Customer = 'Заказчик',
  Executor = 'Исполнитель',
  Default = 'Пользователь',
  Admin = 'Администратор',
}

export enum RoleCodes {
  Manager = 'MANAGER',
  Customer = 'CUSTOMER',
  Executor = 'EXECUTOR',
  Default = 'DEFAULT',
  Admin = 'ADMIN',
}

export interface ProfileAboutMeFormRequest {
  icon?: string;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  userName?: string;
  gender?: 'MALE' | 'FEMALE';
  birthDate?: string;
  location?: string;
  resumeUrl?: string;
  resume?: string[];
  directionId?: string;
  stack?: string[];
  certificates?: string[];
  about?: string;
  experienceDescription?: string;
  feedbacks?: string[];
  experience?: number;
  roles: string[];
}

export interface ProfileAboutCompanyRequest {
  logo?: string;
  name?: string;
  url?: string;
  description?: string;
  phone?: string;
  rules?: string;
  inn?: string;
  email?: string;
  officeLocation?: string;
}

export interface ProfileAboutCompanyResponse {
  success: boolean;
  id: string;
}

class UserQuery {
  id!: string | null;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: string | null;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: string | null;
  userId!: string;
  userName!: string | null;
  storedFileId!: string | null;
}

export class UserQueryResponse extends PaginationClass {
  @Type(() => UserQuery)
  data!: UserQuery[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export class UserResponse extends PaginationClass {
  @Type(() => User)
  data!: User[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export type ProfilesRequestBody = {
  start?: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  stack?: string[];
  experienceFrom?: number;
  experienceTo?: number;
  hourPriceFrom?: number;
  hourPriceTo?: number;
  monthPriceFrom?: number;
  monthPriceTo?: number;
  directionIds?: string[];
  rating?: number;
  location?: string;
  sortField?: string;
  roleId?: string;
};

export type SpecialistRequestBody = {
  start?: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  stack?: string[];
  experienceFrom?: number;
  experienceTo?: number;
  hourPriceFrom?: number;
  hourPriceTo?: number;
  monthPriceFrom?: number;
  monthPriceTo?: number;
  statusId?: string[];
  rating?: number;
  location?: string;
  sortField?: string;
  roleId?: string;
};

export class ProfileMeResponse {
  @Type(() => MyAbout)
  about!: MyAbout | null;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  favorite!: boolean;
  id!: string;
  integration!: string[];
  link!: Link;
  organization!: Organization;
  @Type(() => UserRole)
  roles!: UserRole[];
  work!: Work;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface ResetPasswordUpdateRequest {
  email: string;
  code: string;
  newPassword: string;
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export type OauthProvider = {
  id?: string;
  created?: string;
  changed?: string;
  name?: string;
  url?: string;
  clientId?: string;
  sortOrder?: number | null;
};

export enum AUTH_PROVIDER {
  YANDEX = 'YANDEX',
  VK = 'VK',
}

export enum INTEGRATION_PROVIDER {
  YANDEX = 'YANDEX',
  EGILE = 'EGILE',
  SYS = 'SYS',
}

export enum OAUTH_REDIRECT {
  YANDEX_REDIRECT = 'https://egile.ru/authorization/oauth/yandex',
  VK_REDIRECT = 'https://egile.ru/authorization/oauth/vk',
}

export class FavoriteUsersResponse extends PaginationClass {
  @Type(() => User)
  data!: User[];
}

export class YandexOrganization {
  id!: string;
  name!: string;
  title!: string;
}

export class YandexOrganizationProject {
  id!: string;
  name!: string;
}

export type SysProject = {
  id: string;
  name: string;
};
