import { DictionaryValue } from '@store/dictionary/contracts';

export type SelectOption = { value: any; label: string };

export function fromDictionaryToSelectOption(dictValue: DictionaryValue): SelectOption {
  return {
    value: dictValue.id,
    label: dictValue.name,
  };
}
