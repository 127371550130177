import React, { useMemo } from 'react';
import styles from './ProjectSettings.module.scss';
import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import { FormControlLabel, Paper } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { ReactComponent as Status } from '@assets/img/status.svg';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { selectUserIntegrations } from '@store/user/user.slice';
import { openModal } from '@store/ui/ui.slice';
import { TrackerModal } from './TrackerModal';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import { useParams } from 'react-router-dom';
import {
  useDetailProjectQuery,
  useDisconnectTrackerFromProjectMutation,
} from '@store/project/project.slice';

const CONNECTED_INTEGRATION_PROVIDER = Object.freeze({
  [INTEGRATION_PROVIDER.YANDEX]: 'Яндекс',
  [INTEGRATION_PROVIDER.SYS]: 'SYS',
});

type IntegrationProviderKey = keyof typeof CONNECTED_INTEGRATION_PROVIDER;

export const ProjectSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const integrations = useAppSelector(selectUserIntegrations);

  const { data: project } = useDetailProjectQuery(projectId || '');
  const [disconnectTrackerFromProject] = useDisconnectTrackerFromProjectMutation();

  const projectTrackerStatus = useMemo(() => {
    const projectTrackerStatus = project?.tracker;
    return projectTrackerStatus;
  }, [project?.tracker]);

  const cloudStatus = useMemo(() => {
    const cloudStatus = project?.cloudProjectODTO;
    return cloudStatus;
  }, [project?.cloudProjectODTO]);

  const handleTracker = () => {
    if (projectTrackerStatus) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Отключить трекер?`)) {
        disconnectTrackerFromProject({
          projectId: projectId!,
          tracker: project!.tracker!.provider! as INTEGRATION_PROVIDER,
        });
      }
    } else {
      dispatch(openModal(<TrackerModal projectId={projectId!} />));
    }
  };

  const provider = projectTrackerStatus?.provider as IntegrationProviderKey;

  return (
    <div className="col container-content">
      <div className={styles.wrapper}>
        <div className={styles.nav__back}>
          <NavigationBack />
        </div>
        <Paper className={styles.container} elevation={0} variant="outlined">
          <div className={styles.title}>
            <h2>Настройка проекта</h2>
          </div>
          <div className={styles.settings__content__wrapper}>
            <div className={styles.settings__content}>
              <div className={styles.statuses__settings}>
                {projectTrackerStatus ? (
                  <div className="d-flex flex-column gap-2">
                    <div>
                      <Status />
                      <span className={styles.statuses__settings__title}>Показать статусы</span>
                    </div>
                    <div>
                      Настройка статусов невозможна, так как включен{' '}
                      {CONNECTED_INTEGRATION_PROVIDER[provider]} трекер.
                      <br />
                      Настройка осуществляется через{' '}
                      <span className={styles.statuses__settings__yandex}>
                        {CONNECTED_INTEGRATION_PROVIDER[provider]} трекер
                      </span>
                      .
                    </div>
                  </div>
                ) : (
                  <>
                    <Status />
                    <span className={styles.statuses__settings__title}>Настройка статусов</span>
                  </>
                )}
              </div>
              <hr />
              <div className={styles.budget_rule}>
                <div
                  className={
                    cloudStatus ? styles.budget_rule__title : styles.budget_rule__title__inactive
                  }>
                  <span>Задать бюджетное правило</span>
                </div>
                {!cloudStatus ? (
                  <div>
                    <span>Задать бюджетное правило можно только при включенном Облачным ДЦ</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <hr />
              <div className={styles.buttons}>
                {integrations.some((integration) =>
                  Object.values(INTEGRATION_PROVIDER).includes(integration as INTEGRATION_PROVIDER),
                ) ? (
                  <div className={styles.tracker}>
                    <span className={styles.tracker__title}>
                      {`Трекер ${projectTrackerStatus ? `включен` : `выключен`}`}
                    </span>
                    <FormControlLabel
                      control={
                        <MuiSwitch
                          checked={projectTrackerStatus}
                          onChange={handleTracker}
                          name="tracker"
                        />
                      }
                      label=""
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};
