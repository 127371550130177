import { ApiClient } from '@store/api-client';
import { AttachProjectRequest, Project, SpentTimeMock } from './contracts';
import Api from '@api-schema';
import { enqueueSnackbar } from 'notistack';

// Doesnt exist in swagger
export const getTrackerProjects = async (provider: string) => {
  const { data } = await ApiClient.get<Project[]>(`/api/tracker/${provider}/projects`);
  return data;
};

// Doesnt exist in swagger
export const attachProject = async (request: AttachProjectRequest) => {
  await ApiClient.post(`/api/tracker/${request.provider}/attach`, {
    easyProjectId: request.easyProjectId,
    trackerProjectId: request.trackerProjectId,
    organizationId: request.organizationId,
  });
};

// Doesnt exist in swagger
export const getTasksFavorite = async () => {
  // const { data } = await ApiClient.post(`/api/task/favorite`, request);
  return {
    pages: 0,
    page: 0,
    totalCount: 0,
    queriedCount: 0,
    startRecord: 0,
    data: [],
  };
};

// Doesnt exist in swagger
export const setTasksFavorite = async (
  requestBody: Api.operations['changeFavoriteStatus_2']['requestBody']['content']['application/json'],
  taskId: Api.operations['changeFavoriteStatus_2']['parameters']['path']['id'],
) => {
  const { data } = await ApiClient.put<
    Api.operations['changeFavoriteStatus_2']['responses']['200']['content']['application/json']
  >(`/api/task/${taskId}/favorite`, requestBody);
  enqueueSnackbar('Избранное задачи успешно обновлено', {
    variant: 'success',
  });
  return data;
};

export const getSpentTime = async () => {
  const data = await SpentTimeMock;
  return data;
};
