import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './TaskForm.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '@components/MUI/Select';
import { Project } from '@store/project/contracts';
import { useParams } from 'react-router-dom';
import { SysTaskStatus, TaskField, TrackerExecutor } from '@store/tasks/contracts';
import { Autocomplete } from '@components/MUI/Autocomplete';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import {
  useLazyGetProjectExecutorsQuery,
  useLazyGetProjectPrioritiesQuery,
  useLazyGetProjectQueuesQuery,
  useLazyGetProjectsQuery,
  useLazyGetProjectTypesQuery,
} from '@store/project/project.slice';
import { DurationInput } from '@components/MUI/DurationInput/DurationInput';
import { useLazyGetSysTaskStatusesQuery } from '@store/tasks/tracker.slice';
import { MarkdownEditorComponent } from '@components/ui/MarkdownEditor';
import { withSuspense } from 'src/hocs/withSuspense';

export type TaskFormProps = {
  value: TaskFormValue;
  submit: (value: TaskFormValue, form: FormikBag<TaskFormProps, TaskFormValue>) => void;
};

type TaskFormValue = {
  id?: string;
  projectId: string;
  description: string;
  name: string;
  priority: TaskField | null;
  type: TaskField | null;
  queue: TaskField | null;
  deadline: string | null;
  tags: string[];
  estimation: number | null;
  executors: string[];
  provider: INTEGRATION_PROVIDER | null;
  statusId: string | null;
};

const TaskFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  description: Yup.string().required('Поле обязательно для заполнения'),
  projectId: Yup.string().required('Поле обязательно для заполнения'),
  queue: Yup.object()
    .nullable()
    .when('provider', {
      is: INTEGRATION_PROVIDER.YANDEX,
      then: Yup.object().required('Поле обязательно для заполнения'),
      otherwise: Yup.object().nullable(),
    }),
  deadline: Yup.date()
    .nullable()
    .min(
      new Date().toISOString().split('T')[0],
      `Выберите сегодняшнюю дату или от ${new Date(Date.now() + 86400000).toLocaleDateString()}`,
    ),
  estimation: Yup.string()
    .nullable()
    .matches(
      /^P(\d{1,3}W)?(\d{1,3}D)?(T)?(\d{1,3}H)?(\d{1,3}M)?(\d{1,3}S)?$/,
      'Введите в формате 1н 2д 4ч 30м 20с',
    ),
  executors: Yup.array().when('provider', {
    is: INTEGRATION_PROVIDER.YANDEX,
    then: Yup.array().max(1, 'Можно указать только одного исполнителя'),
  }),
  type: Yup.object().nullable().required('Поле обязательно для заполнения'),
  tags: Yup.array(),
  priority: Yup.object().nullable(),
  statusId: Yup.string().when('provider', {
    is: INTEGRATION_PROVIDER.SYS,
    then: Yup.string().required('Поле обязательно для заполнения'),
    otherwise: Yup.string().nullable(),
  }),
});

const MarkdownEditor = withSuspense(MarkdownEditorComponent);

const FormComponent: React.FC = () => {
  const form = useFormikContext<TaskFormValue>();
  const { projectList } = useProjectList();
  const { taskId } = useParams();
  form.validationSchema = TaskFormSchema;

  const [queuesList, setQueuesList] = useState<TaskField[]>([]);
  const [typesList, setTypesList] = useState<TaskField[]>([]);
  const [prioritiesList, setPrioritiesList] = useState<TaskField[]>([]);
  const [executorList, setExecutorList] = useState<TrackerExecutor[]>([]);
  const [sysStatusesList, setSysStatusesList] = useState<SysTaskStatus[]>([]);
  const [getProjectQueues] = useLazyGetProjectQueuesQuery();
  const [getProjectTypes] = useLazyGetProjectTypesQuery();
  const [getProjectPriorities] = useLazyGetProjectPrioritiesQuery();
  const [getProjectExecutors] = useLazyGetProjectExecutorsQuery();
  const [getTaskStatuses] = useLazyGetSysTaskStatusesQuery();

  useEffect(() => {
    if (form.values.projectId) {
      getProjectQueues(form.values.projectId).then((projectQueues) => {
        if (projectQueues.data) {
          setQueuesList(projectQueues.data);
        }

        if (form.values.queue) {
          const queue = projectQueues?.data?.find((q) => q['id'] === form.values.queue?.id);
          form.setFieldValue('queue', queue);
        } else {
          form.setFieldValue(`queue`, null);
        }
      });
    }
  }, [form.values.projectId]);

  useEffect(() => {
    if (form.values.projectId) {
      getProjectExecutors({ projectId: form.values.projectId }).then((list) => {
        if (list.data) setExecutorList(list.data);
        if (form.values.executors.length > 0) {
          const value = list?.data
            ?.filter((i) => form.values.executors.includes(i.id))
            ?.map((i) => {
              return {
                value: i.id,
                label: i.userName,
              };
            });
          form.setFieldValue(`executors`, value);
        }
      });
    }
  }, [form.values.projectId]);

  useEffect(() => {
    if (form.values.projectId) {
      getProjectTypes({
        projectId: form.values.projectId,
        ...(form.values.queue && { queueId: form.values.queue.id }),
      }).then((projectTypes) => {
        if (projectTypes.data) setTypesList(projectTypes.data);
        if (form.values.type) {
          const type = projectTypes?.data?.find((type) => type['id'] === form.values.type?.id);
          form.setFieldValue(`type`, type);
        } else {
          form.setFieldValue(`type`, null);
        }
      });
    }
  }, [form.values.projectId, form.values.queue]);

  useEffect(() => {
    if (form.values.projectId) {
      getProjectPriorities({ projectId: form.values.projectId }).then((projectPriorities) => {
        if (projectPriorities.data) setPrioritiesList(projectPriorities.data);
        if (form.values.priority) {
          const priority = projectPriorities?.data?.find(
            (type) => type['id'] === form.values.priority?.id,
          );
          form.setFieldValue(`priority`, priority);
        } else {
          form.setFieldValue(`priority`, null);
        }
      });
    }
  }, [form.values.projectId]);

  useEffect(() => {
    if (form.values.projectId && form.values.provider === INTEGRATION_PROVIDER.SYS) {
      getTaskStatuses(form.values.projectId).then((taskStatuses) => {
        if (taskStatuses.data) setSysStatusesList(taskStatuses.data);
      });
    }
  }, [form.values.projectId, form.values.provider]);

  const isEdit = useMemo(() => {
    return Boolean(taskId);
  }, [taskId]);

  useEffect(() => {
    if (form.values.projectId && !isEdit) {
      const selectedProjectProvider =
        projectList.find((project) => project.id === form.values.projectId)?.tracker?.provider ||
        null;
      form.setFieldValue('provider', selectedProjectProvider);
    }
  }, [form.values.projectId, isEdit]);
  return (
    <Form onSubmit={form.handleSubmit}>
      <div className="mb-4">
        <Select
          values={
            projectList?.map((project) => ({
              value: project.id,
              label: project.name,
            })) || []
          }
          disabled={isEdit || projectList.length === 0}
          name={'projectId'}
          label={projectList.length === 0 ? 'Создайте заявку и переведите в проект' : 'Проект'}
          isOutlined={true}
        />
      </div>
      {form.values.provider === INTEGRATION_PROVIDER.YANDEX && (
        <div className="mb-4">
          <Select
            values={queuesList.map((queue) => ({ value: queue, label: queue.display }))}
            name={'queue'}
            label={'Выберите очередь'}
            disabled={!form.values.projectId || isEdit}
            isOutlined={true}
          />
        </div>
      )}
      <div className="mb-4">
        <Select
          values={typesList.map((type) => ({ value: type, label: type.display }))}
          name={'type'}
          label={'Тип задачи'}
          disabled={!form.values.projectId}
          isOutlined={true}
        />
      </div>
      {form.values.provider === INTEGRATION_PROVIDER.SYS && (
        <div className="mb-4">
          <Select
            values={sysStatusesList.map((sysStatus) => ({
              value: sysStatus.id,
              label: sysStatus.name!,
            }))}
            name={'statusId'}
            label={'Статус задачи'}
            disabled={!form.values.projectId}
            isOutlined={true}
          />
        </div>
      )}
      <div className="mb-4">
        <InputField
          name="name"
          placeholder="Название задачи"
          type="text"
          className={styles.name__field}
        />
      </div>
      <div className="mb-4">
        <InputField
          name="deadline"
          placeholder="Дедлайн задачи"
          type="date"
          className={styles.name__field}
        />
      </div>
      <div className="mb-4">
        <Autocomplete
          values={executorList.map((executor) => ({
            value: executor.id,
            label: executor.userName,
          }))}
          name="executors"
          label="Исполнители"
          multiple={true}
          isOutlined
          freeSolo={false}
        />
      </div>
      <div className="mb-4">
        <Autocomplete name="tags" values={[]} isOutlined freeSolo multiple label={'Тег'} />
      </div>
      <div className="mb-4">
        <Select
          values={prioritiesList.map((queue) => ({ value: queue, label: queue.display }))}
          name={'priority'}
          label={'Приоритет'}
          disabled={!form.values.projectId}
          isOutlined={true}
        />
      </div>
      <div className="mb-4">
        <MarkdownEditor name="description" placeholder="Описание задачи" />
      </div>
      <div className="mb-4">
        <DurationInput
          name="estimation"
          placeholder="Например: 3w 4d 4h 30m"
          label="Оценка времени"
          type="text"
          fullWidth
          className={styles.duration__input}
        />
      </div>
      <Button
        disabled={!form.isValid}
        style={{ padding: '10px 20px' }}
        variant="contained"
        type="submit"
        disableElevation={true}>
        {isEdit ? 'Сохранить изменения' : 'Создать задачу'}
      </Button>
    </Form>
  );
};

function useProjectList(): {
  projectList: Project[];
} {
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [getProjects] = useLazyGetProjectsQuery();

  useEffect(() => {
    getProjects({
      limit: 30,
      sortOrder: 'desc',
      start: 0,
    }).then((project) => setProjectList(project?.data?.data || []));
  }, []);

  return {
    projectList,
  };
}

export const TaskForm = withFormik<TaskFormProps, TaskFormValue>({
  displayName: 'TaskForm',
  mapPropsToValues: (props) => {
    return {
      id: props.value.id,
      name: props.value.name,
      description: props.value.description,
      projectId: props.value.projectId,
      priority: props.value.priority,
      queue: props.value.queue,
      tags: props.value.tags || [],
      type: props.value.type,
      deadline: props.value.deadline,
      estimation: props.value.estimation,
      executors: props.value.executors,
      provider: props.value.provider,
      statusId: props.value.statusId,
    };
  },
  enableReinitialize: true,
  validationSchema: TaskFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    //@ts-expect-error
    values.executors = values.executors.map((ex) => ex['value']);
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
