import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import { isRejected } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

const ignoredErrors: Set<string> = new Set(['ConditionError']);

function isNotificationErrorAction(action: AnyAction) {
  return isRejected(action) && !ignoredErrors.has(action?.error.name || '');
}

function isRtkErrorAction(action: AnyAction) {
  return action.type.startsWith('api');
}

function extractErrorMessage(action: AnyAction) {
  if (isRtkErrorAction(action)) {
    return action['payload']['data']['message'] || action['payload']['data']['code'];
  }
  return action.error.message;
}

export const errorLogger: Middleware = () => (next) => (action: AnyAction) => {
  if (isNotificationErrorAction(action)) {
    const message = extractErrorMessage(action);
    enqueueSnackbar(message, {
      variant: 'error',
    });
  }

  return next(action);
};
