import { PaginationClass, ToDate } from '@store/common';
import { INTEGRATION_PROVIDER, User } from '@store/user/contracts';
import { RequestBody } from '@store/utility';
import { Vacancy } from '@store/vacancy/contracts';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';
import Api from '@api-schema';
import { JsonSchema7 } from '@jsonforms/core';

export class Project {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  projectStatusId!: string;
  projectStatusName!: string;
  projectTypeId!: string;
  projectTypeName!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  deadLine!: Dayjs;
  budget!: number;
  description!: string;
  stack!: string;
  requirement!: string;
  task!: string;
  tag!: string;
  requestId!: string;
  requestName!: string;
  teamId!: string | null;
  teamName!: string | null;
  teamLeadId!: string | null;
  teamLeadName!: string | null;
  @Type(() => TeamPosition)
  teamPositions!: TeamPosition[];
  @Type(() => ProjectTracker)
  tracker!: ProjectTracker;
  @Type(() => CloudProjectODTO)
  cloudProjectODTO!: CloudProjectODTO;
  @Type(() => User)
  customer!: User;
  @Type(() => Attachments)
  attachments!: Attachments[];
  visibilityStatusId!: string;
  prefix!: string;
  currencyId!: string;
}

class ProjectTracker {
  trackerProjectId!: number;
  organizationId!: number;
  provider!: INTEGRATION_PROVIDER;
}

class CloudProjectODTO {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  folderId!: string;
  status!: string;
  code!: number;
}

export class Attachments {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  favorite!: boolean;
  name!: string;
  size!: number;
  mime!: string;
  extension!: string;
  tag!: string;
  @Type(() => Owner)
  owner!: Owner;
}

class Owner {
  userId!: string;
  userName!: string;
  storedFileId!: string;
}

export class GetProjectsResponse extends PaginationClass {
  @Type(() => Project)
  data!: Project[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export class GetManagerProjectsResponse extends PaginationClass {
  @Type(() => Project)
  data!: Project[];
}

class TeamPosition {
  id!: string;
  positionId!: string;
  positionName!: string;
  teamId!: string;
  userId!: string;
  userName!: string;
  vacancy!: Vacancy;
  integrations!: string[];
  email!: string;
}

export class TaskStatus {
  statusName!: string;
  count!: number;
}

class Employee {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  userName!: string;
  positionName!: string;
  tasks!: TaskStatus[];
  workedHours!: number;
}

export class GetTeamAnalyticResponse extends PaginationClass {
  data!: Employee[];
}

export interface BudgetAnalytics {
  planBudget: string;
  spentBudget: string;
  predictionBudget: string;
  costVariance: string;
  timeDeviation: string;
}

export const budgetAnalyticsData: BudgetAnalytics[] = [
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
];

class CommonAnalyticsTask {
  statusType!: string;
  taskCount!: number;
}

export class CommonAnalytics {
  spentHours!: number;
  executorsCount!: number;
  daysUntilDeadline!: number;
  tasksCount!: number;
  tasks!: CommonAnalyticsTask[];
}

export class WIPAnalyticsResponse {
  userName!: string;
  userId!: string;
  data!: number;
}

export class WastedTimeAnalyticResponse {
  @ToDate('DD.MM')
  date!: Dayjs;
  hours!: number;
}

export class LeadTimeAnalyticResponse {
  daysCount!: number;
  taskCount!: number;
}

export class EffectivenessAnalyticResponse {
  @ToDate('DD.MM')
  date!: Dayjs;
  percent!: number;
}

export class CycleTimeAnalyticResponse {
  userName!: string;
  userId!: string;
  data!: number;
}

export class BurnoutAnalyticResponse {
  @ToDate('DD.MM')
  date!: string;
  closedTasks!: number;
  mustCloseTasks!: number;
}

type ThroughputAnalyticResponseData = {
  executorName: string;
  taskCount: number;
  percentage: number;
};

export class ThroughputAnalyticResponse {
  @ToDate('DD.MM')
  startDate!: Dayjs;
  @ToDate('DD.MM')
  endDate!: Dayjs;
  totalTaskCount!: number;
  data!: ThroughputAnalyticResponseData[];
}

export type ProjectTransition = {
  id?: string;
  display?: string;
  fields?: Api.components['schemas']['ProjectFieldDTO'][];
  schema?: JsonSchema7;
};

type YandexTrackerConnectionPayload = RequestBody<Api.operations['connectProjectToYandexTracker']>;

type SysTrackerConnectionPayload = RequestBody<Api.operations['connectProjectToSysTracker']>;

export type ConnectToTrackerMap = {
  [INTEGRATION_PROVIDER.YANDEX]: YandexTrackerConnectionPayload;
  [INTEGRATION_PROVIDER.SYS]: SysTrackerConnectionPayload;
  [INTEGRATION_PROVIDER.EGILE]: unknown;
};

export type DisconnectTrackerPayload = {
  projectId: string;
  tracker: INTEGRATION_PROVIDER;
};
