import { formatDate } from '@components/ui/formatDate';
import { Tag } from '../tags/NewsTags';
import { Link } from 'react-router-dom';
import styles from './NewsCard.module.scss';

export type NewsItem = {
  id: number;
  tags?: Array<Tag>;
  chapter?: {
    id: number;
    name: string;
  };
  title?: string;
  anons?: string | undefined;
  content?: string;
  image?: string;
  pub_date?: string;
  unpub_date?: string | null | undefined;
  admin_email?: string | null | undefined;
};

type NewsCardProps = {
  item: NewsItem;
};

export const NewsCard = ({ item }: NewsCardProps) => {
  return (
    <>
      <div className={`row ms-0 me-0 flex-column flex-sm-row ${styles.item}`}>
        <div className={`col col-sm-3 mb-3 mb-sm-0 ${styles.image}`}>
          <img src={item.image} alt="Новость" className={styles.image} loading="lazy" />
        </div>
        <div className="col d-flex flex-column justify-content-between ps-0 ps-sm-4">
          <div>
            {item.tags ? (
              <div className="d-flex">
                {item.tags.map((tag: Tag) => (
                  <Link
                    to={{
                      pathname: '/news',
                      search: `page_size=5&page=1&ordering=desc&tags__id=${tag?.id}`,
                    }}
                    className={`${styles.tag} me-3 mb-3`}
                    key={tag?.id}
                    replace>
                    {tag?.name}
                  </Link>
                ))}
              </div>
            ) : (
              ''
            )}

            <Link to={`/news/${item.id}`}>
              <h2>{item.title}</h2>
            </Link>

            <p className={`mb-0 ${styles.anons}`}>{item.anons ? item.anons : item.content}</p>
          </div>

          <p className={`mb-0 ${styles.date}`}>{formatDate(item.pub_date, 'DD.MM.YYYY')}</p>
        </div>
      </div>
    </>
  );
};
