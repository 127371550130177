import React, { useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { ProjectTasksAnalytics } from '@store/dashboard/contracts';
import { localisedTaskStatuses } from './TaskStatusList';
import { statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';

type ProjectBarsProps = {
  projects?: ProjectTasksAnalytics[];
};

interface StatusCount {
  [key: string]: number;
}

export const ProjectBars: React.FC<ProjectBarsProps> = ({ projects }) => {
  const projectNames = useMemo(() => {
    if (projects) return projects.map((project) => project?.projectName);
    return [];
  }, [projects]);

  const tasks = useMemo(() => {
    if (!projects) {
      return [];
    }

    return projects.map((project) => {
      const statusCountObject = project.statusCountTaskList.reduce((acc, current) => {
        acc[current.statusType] = current.taskCount;
        return acc;
      }, {} as StatusCount);
      return statusCountObject;
    });
  }, [projects]);

  const barChartSeries = useMemo(() => {
    return Object.entries(localisedTaskStatuses).map(([_, value]) => ({
      dataKey: value.statusType,
      label: value.name,
    }));
  }, []);

  const statusColor = useMemo(() => {
    return Object.entries(localisedTaskStatuses).map(
      ([_, value]) => statusTaskColors.get(value.statusType) || '',
    );
  }, []);

  return (
    <div
      style={
        tasks.length > 8
          ? { width: '95%' }
          : tasks.length > 3
          ? { width: '80%' }
          : tasks.length === 1
          ? { width: '30%' }
          : { width: '50%' }
      }>
      <BarChart
        yAxis={[{ tickMinStep: 1 }]}
        dataset={tasks.length > 0 ? tasks : [{ dataKey: '' }]}
        xAxis={[{ scaleType: 'band', data: projectNames }]}
        series={barChartSeries}
        height={350}
        colors={statusColor}
        slotProps={{
          legend: { hidden: true },
          bar: {
            clipPath: `inset(0px round 4px 4px 0px 0px)`,
          },
        }}
      />
    </div>
  );
};
