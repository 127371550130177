import { ReactNode, Suspense } from 'react';

type WithSuspenseProps<T> = T & {
  fallback?: ReactNode;
};

export const withSuspense = <P extends object>(
  Component: React.ComponentType<P>,
  fallback: ReactNode = <div>Loading...</div>,
) => {
  return function SuspendedComponent(props: WithSuspenseProps<P>) {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
};
