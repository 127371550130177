import styles from './Requisite.module.scss';
import { FC, useCallback } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/img/pencil.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/wastebasket.svg';
import { CardButton } from '@components/ui/CardButton';
import { useNavigate } from 'react-router-dom';
import { Requisite } from '@store/requisite/contracts';
import { RequisiteDeleteModal } from './RequisiteDeleteModal';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { useRemoveRequisiteMutation } from '@store/requisite/requisite.slice';

interface RequisitesCardProps {
  data: Requisite;
}

export const RequisitesCard: FC<RequisitesCardProps> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [removeRequisite] = useRemoveRequisiteMutation();
  const openDeleteModal = useCallback(() => {
    dispatch(
      openModal(
        <RequisiteDeleteModal
          requisite={data}
          onDelete={() => {
            removeRequisite({ id: data.id || '' });
            dispatch(closeModal());
          }}
          children={<></>}
        />,
      ),
    );
  }, [dispatch, data]);
  return (
    <div className={styles.requisites_card}>
      <h6 className="color-gray mt-3">Номер счёта</h6>
      <h6>{data.accountNumber}</h6>
      <h6 className="color-gray mt-3">Банк получателя</h6>
      <h6>{data.bankName}</h6>
      <h6 className="color-gray mt-3">БИК</h6>
      <h6>{data.bik}</h6>
      <h6 className="color-gray mt-3">Корреспондентский счет</h6>
      <h6>{data.corporateAccount}</h6>
      <h6 className="color-gray mt-3">ИНН</h6>
      <h6>{data.inn} </h6>
      <h6 className="color-gray mt-3">КПП</h6>
      <h6>{data.kpp} </h6>
      <div className="mt-3">
        <CardButton
          title="Редактировать"
          icon={<EditIcon />}
          onClick={() => navigate(`/balance/requisites/${data.id}`)}
        />
        <CardButton title="Удалить" icon={<DeleteIcon />} onClick={openDeleteModal} />
      </div>
    </div>
  );
};
