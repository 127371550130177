import { RatesCardBasic } from '@pages/rates/ratesCard/RatesCardBasic';
import { RatesCardPersonal } from '@pages/rates/ratesCard/RatesCardPersonal';
import styles from '../Rates.module.scss';
import { RatesCardPremium } from '@pages/rates/ratesCard/RatesCardPremium';

const benefits = [
  'Ведение 1 проекта',
  'В проектах не более 5 специалистов',
  'Добавление специалистов по email',
  'Наличие базового функционала',
  'Хранение до 100 МБ',
];

const benefitsPersonal = [
  'Ведение до 10 проектов',
  'Возможность создания вакансий',
  'Расширенный список функционала мониторинга',
  'Дополнительные скидки на Яндекс.Облако и Яндекс.Трекер',
  'И все что входит в тариф Стартап',
];

const benefitsPremium = [
  'Ведение до 30 проектов',
  'Использование Telegram-ботов',
  'Ускоренная линия технической поддержки',
  'Скидки на дополнительные опции',
  'И все что входит в Персональный тариф',
];

export const RatesPageForCustomer = () => {
  return (
    <div className={styles.gridLayout}>
      <RatesCardBasic list={benefits} title="Бесплатно" />
      <RatesCardPersonal list={benefitsPersonal} />
      <RatesCardPremium list={benefitsPremium} />
    </div>
  );
};
