import { api } from '@store/api-client';
import {
  AnalyticsRequestsResponse,
  AnalyticsVananciesResponse,
  ProjectTasksAnalytics,
  TimeProjectAnalyticsResponse,
} from './contracts';
import { RequestBody, ResponseBody } from '@store/utility';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { listAnalyticsRequestsType } from './dashboard.service';
import { PaginationQuery } from '@store/common';

const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTimeAnalytics: builder.query<
      TimeProjectAnalyticsResponse,
      RequestBody<Api.operations['getTimeAnalytics']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/dashboard/time/analytics',
        body: requestBody,
      }),
      transformResponse: (response: ResponseBody<Api.operations['getTimeAnalytics']>) => {
        return plainToInstance(TimeProjectAnalyticsResponse, response);
      },
      providesTags: ['DashboardTimeAnalytics'],
    }),

    getTaskStatuses: builder.query<
      ProjectTasksAnalytics[],
      RequestBody<Api.operations['getTaskAnalytics']>
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/dashboard/status/task',
        body: requestBody,
      }),
      providesTags: ['DashboardTaskStatuses'],
      transformResponse: (response: ProjectTasksAnalytics[]) => {
        return response.filter((data) => data.projectId !== '00000000-0000-0000-0000-000000000000');
      },
    }),

    getDashboardProjectStatuses: builder.query<
      ResponseBody<Api.operations['getProjectAnalytics']>,
      any
    >({
      query: () => ({
        method: 'POST',
        url: '/api/dashboard/status/project',
      }),
      providesTags: ['DashboardProjectStatuses'],
    }),

    getRequestsAnalytics: builder.query<AnalyticsRequestsResponse, listAnalyticsRequestsType>({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/dashboard/request',
        body: requestBody,
      }),
      providesTags: ['DashboardRequestAnalytics'],
    }),

    getVacanciesAnalytics: builder.query<AnalyticsVananciesResponse, PaginationQuery>({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/dashboard/offer',
        body: requestBody,
      }),
      providesTags: ['DashboardVacanciesAnalytics'],
    }),
  }),
});

export const {
  useGetTimeAnalyticsQuery,
  useGetTaskStatusesQuery,
  useGetDashboardProjectStatusesQuery,
  useGetRequestsAnalyticsQuery,
  useGetVacanciesAnalyticsQuery,
} = dashboardApi;
