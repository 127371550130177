import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { loadTemplates, selectDetailTemplate } from '@store/cloud/cloud.slice';
import Button from '@mui/material/Button/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FormWrapper } from '@components/Form/Form';
import styles from '@pages/cloud/Templates.module.scss';
import { ReactComponent as EditIconGreen } from '@assets/img/editIconGreen.svg';
import { closeModal } from '@store/ui/ui.slice';

type TemplateDetailProps = {
  templateId: string;
};

const TemplateDetail: React.FC<TemplateDetailProps> = ({ templateId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const template = useAppSelector((state) => selectDetailTemplate(state, templateId || ''));

  useEffect(() => {
    dispatch(loadTemplates({}));
  }, [dispatch]);

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  const editTemplateDetail = useCallback(() => {
    navigate(`/clouds/templates/${templateId}/edit`);
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={cancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '480px', width: '100%' }}>
          <FormWrapper label={'Шаблон'}>
            <div className={styles.detailTemplatesMain}>
              <div className={styles.detailTemplates}>
                <h2 className={styles.detailTemplatesTitle}>Название шаблона</h2>
                <p className={styles.detailTemplatesResult}>{template?.name || '-'}</p>
              </div>
              <div className={styles.detailTemplates}>
                <h2 className={styles.detailTemplatesTitle}>Память</h2>
                <p className={styles.detailTemplatesResult}>{template?.memory || '-'} Gb</p>
              </div>
              <div className={styles.detailTemplates}>
                <h2 className={styles.detailTemplatesTitle}>Дисковое пространство</h2>
                <p className={styles.detailTemplatesResult}>{template?.diskSize || '-'} Gb</p>
              </div>
              <div className={styles.detailTemplates}>
                <h2 className={styles.detailTemplatesTitle}>Ядро</h2>
                <p className={styles.detailTemplatesResult}>{template?.cores || '-'}</p>
              </div>
              <div className={styles.btns}>
                <Button className={styles.btnCancel} onClick={cancel}>
                  Закрыть
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<EditIconGreen />}
                  onClick={editTemplateDetail}>
                  Редактировать
                </Button>
              </div>
            </div>
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
};
export { TemplateDetail };
