import { ResettingFormStepTwo } from '@pages/authentication/ResettingFormStepTwo';
import { useResetPasswordRequestMutation } from '@store/user/user.slice';
import styles from '@pages/authentication/Authentication.module.scss';

export function CheckEmail() {
  const [userResetPasswordRequest] = useResetPasswordRequestMutation();

  const handleResend = async (email: string) => {
    userResetPasswordRequest({
      email,
    });
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Проверьте почту</div>
      <ResettingFormStepTwo resend={handleResend} />
    </div>
  );
}
