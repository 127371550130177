import { PropsWithChildren } from 'react';
import { TableCellOutput } from './TableCellOutput';
import { Head } from './Head';
import styles from './Table.module.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { RequestParams } from './TableHeadCell';
import { CircularProgress } from '@mui/material';

export interface Columns {
  label?: string;
  format?: (value: any) => string | number;
  filterInput?: boolean;
  checkboxInput?: boolean;
  children?: TableChildren;
  isSortColumn?: boolean;
  additionalItem?: JSX.Element;
}

export type TableChildren = (value?: any, row?: any) => JSX.Element;

export interface AdaptiveTableMUIProps extends PropsWithChildren {
  columns: Record<string, Columns>;
  rows: Record<string, any>[];
  filter?: Record<string, RequestParams>;
  setRequestBody?: (value: Record<string, RequestParams>) => void;
  isEmpty?: boolean;
  emptyLabel?: string;
  sortColumn?: string;
  setSortColumn?: (column: string) => void;
  sortOrder?: 'asc' | 'desc';
  setSortOrder?: (sortOrder: 'asc' | 'desc') => void;
  additionalElement?: JSX.Element;
  isLoading?: boolean;
}

export function TableMUI({
  rows,
  columns,
  setRequestBody,
  filter = {},
  children,
  isEmpty,
  emptyLabel,
  setSortColumn,
  setSortOrder,
  sortOrder,
  sortColumn,
  isLoading,
}: AdaptiveTableMUIProps) {
  if (isEmpty)
    return (
      <div className={styles.empty__label}>
        <p>{emptyLabel}</p>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Table aria-label="table">
          <Head
            columns={columns}
            setRequestBody={setRequestBody}
            filter={filter}
            setSortColumn={setSortColumn}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            sortColumn={sortColumn}
          />
          <TableBody data-testid="table-body">
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={Object.keys(columns).length}>
                  <div className={styles.loader}>
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              rows.map((row, rowIndex) => {
                return (
                  <TableRow key={row.id || rowIndex} style={{ lineHeight: '50px' }}>
                    {Object.keys(columns).map((key, colIndex) => (
                      <TableCell
                        key={key + rowIndex + colIndex}
                        style={{
                          lineHeight: 'inherit',
                          padding: 0,
                          paddingLeft: '1rem',
                          paddingRight: '1rem',
                        }}>
                        <TableCellOutput
                          value={row[key]}
                          format={columns[key].format}
                          children={columns[key].children}
                          row={row}
                          titles={columns}
                          title={key}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={Object.keys(columns).length}>
                  <div className={styles.no__results}>
                    <p>Нет данных для отображения</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {children}
    </div>
  );
}
