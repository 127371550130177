import { Card } from './Card';
import { ReactComponent as TimeGreenIcon } from '@assets/img/timeGreenIcon.svg';
import { ReactComponent as TeamGreenIcon } from '@assets/img/teamGreenIcon.svg';
import { ReactComponent as TaskGreenIcon } from '@assets/img/taskGreenIcon.svg';
import { ReactComponent as EventGreenIcon } from '@assets/img/eventGreenIcon.svg';
import styles from './CradsBlock.module.scss';

export type CommonAnalyticsSummaryCards = {
  spentHours?: number;
  executorsCount?: number;
  daysUntilDeadline?: number;
  tasksAtWork?: number;
};

type CardBlockProps = {
  summary: CommonAnalyticsSummaryCards | null;
};

export const CardsBlock = ({ summary }: CardBlockProps) => {
  return (
    <div className={styles.card__block}>
      <Card Icon={TimeGreenIcon} text="Затраченное время" count={summary?.spentHours || 0} />
      <Card Icon={TeamGreenIcon} text="Исполнителей" count={summary?.executorsCount || 0} />
      <Card Icon={EventGreenIcon} text="Дней до дедлайна" count={summary?.daysUntilDeadline || 0} />
      <Card Icon={TaskGreenIcon} text="Задач в работе" count={summary?.tasksAtWork || 0} />
    </div>
  );
};
