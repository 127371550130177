import styles from './Specialist.module.scss';
import { Button } from '@components/MUI/Button';
import { AboutCard } from '@components/AboutBenefits/AboutCard/AboutCard';
import { CardMechanism } from '@components/AboutMechanism/CardMechanism/CardMechanism';
import { Typography } from '@components/AboutInfo/AboutInfo';
import manager from '@assets/img/AboutMechanism/Manager.svg';
import developer from '@assets/img/AboutMechanism/Developer.svg';
import { Search } from '@components/MUI/Search';
import { useCallback } from 'react';
import { Title } from '@components/AboutBenefits/AboutBenefits';
import { ProjectInfoCard } from '@components/ProjectInfoCard/ProjectInfoCard';
import { Link, useNavigate } from 'react-router-dom';
import specialists from '@assets/img/specialist/specialists.png';

export const SpecialistPage = () => {
  const navigation = useNavigate();

  const navigateToOpenProjects = useCallback(() => {
    navigation('/openProjectsExecutor');
  }, [navigation]);

  return (
    <>
      <div className={styles.mainInfoBackground}>
        <div className={styles.container}>
          <div className={styles.mainInfoBlock}>
            <div>
              <p className={styles.title}>Работай не выходя из дома</p>
              <p className={styles.subTitle}>
                и принимай участие в <span>уникальных проектах</span>
                <br /> крупнейших компаний
              </p>
              <div className={styles.mainInfoBtn}>
                <Link to="/authorization">
                  <Button className={styles.btn} variant="contained">
                    Присоединиться
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              <img src={specialists} className={styles.specialistsPic} alt="специалисты" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainPopular}>
        <div className={styles.container}>
          <h1 className={styles.popularProjects}>Преимущества</h1>
          <div className={styles.gridLayout}>
            <AboutCard
              countCard="01"
              colorTitle="Удаленная работа и индивидуальный гибкий рабочий график"
              title=""
              description="Не нужно тратить время на дорогу в офис.
						Выполняйте задачи дома в привычной для вас обстановке.
						Нет строго графика работы, необходимо только выполнять задачи в установленный срок."
            />
            <AboutCard
              countCard="02"
              colorTitle="Развитие в разных сферах и компаниях"
              title=""
              description="На платформе вы найдете проекты во многих сферах и от разных компаний,
						для которых необходимо реализовать задачи, подходящие для всех уровней разработки.
						Это может стать стимулом для повышения уровня своих знаний в области программирования."
            />
            <AboutCard
              countCard="03"
              colorTitle="Баланс работы и отдыха"
              title=""
              description="Управляйте сами своим временем.
						Вы можете самостоятельно решать сколько вам потребуется времени на выполнение задач,
						не забывая при этом про отдых."
            />
            <AboutCard
              countCard="04"
              colorTitle="Разработка индивидуального трека развития"
              title=""
              description="На платформе множество проектов в разных сферах и компаниях,
						для которых необходимо реализовать задачи с разными уровнями разработки.
						Это может стимулировать для поднятия уровня знаний в программировании."
            />
            <AboutCard
              countCard="05"
              colorTitle="Поддержка от опытных коллег и тимлида в процессе работы"
              title=""
              description="Работа над проектом может быть сложной и требовательной. Тимлид и опытные коллеги могут поддерживать мотивацию членов команды, поощрять их достижения и помогать преодолевать трудности."
            />
          </div>
        </div>
      </div>
      <div className={styles.mainBenefits}>
        <div className={styles.container}>
          <h1 className={styles.popularProjects}>Описание ролей</h1>
          <div className={styles.gridLayout}>
            <CardMechanism avatar={developer} titleCard="Исполнитель">
              <div className={styles.gap1}>
                <Typography>Выбор проекта и роли</Typography>
                <Typography>Исполнение задач, используя инструменты платформы</Typography>
                <Typography>Возможность получить консультацию от опытных коллег</Typography>
                <Typography>Сдача отчета менеджеру проекта о выполненной работе</Typography>
                <Typography>Быстрое получение оплаты через сервис “Безопасная сделка”</Typography>
              </div>
            </CardMechanism>
            <CardMechanism avatar={manager} titleCard="Менеджер проекта">
              <div className={styles.gap1}>
                <Typography>Принятие проекта</Typography>
                <Typography>
                  Связь с заказчиком для обсуждения технического задания и планирования работы
                </Typography>
                <Typography>Подбор команды исполнителей</Typography>
                <Typography>Распределение задач в команде и запуск проекта</Typography>
                <Typography>Управление индивидуальным треком исполнителей</Typography>
                <Typography>Принятие выполненной командой работы</Typography>
                <Typography>Сдача проекта заказчику</Typography>
                <Typography>
                  Проведение расчетов с заказчиком и командой исполнителей, используя сервис
                  “Безопасная сделка”
                </Typography>
              </div>
            </CardMechanism>
          </div>
        </div>
      </div>
      <div className={styles.search}>
        <div className={styles.container}>
          <div className={styles.searchTitle}>
            <Title>Найти вакансию</Title>
          </div>
          <Search placeholder="Вакансия" handleSearch={navigateToOpenProjects} />
        </div>
      </div>
      <div className={styles.mainPopular}>
        <div className={styles.container}>
          <h1 className={styles.popularProjects}>Открытые проекты</h1>
          <div className={styles.blockGrid}>
            <ProjectInfoCard
              projectName="Система полива «Капля»"
              textDeadline="Прием откликов"
              dateOfDeadline="до 15 мая 2024"
              textBudget="Бюджет"
              budget="350 000 ₽"
            />
            <ProjectInfoCard
              projectName="Система предиктивной аналитики"
              textDeadline="Прием откликов"
              dateOfDeadline="до 03 июня 2024"
              textBudget="Бюджет"
              budget="По договорённости"
            />
            <ProjectInfoCard
              projectName="Система полива «Капля»"
              textDeadline="Прием откликов"
              dateOfDeadline="Бессрочно"
              textBudget="Бюджет"
              budget="По договорённости"
            />
          </div>
          <div className={styles.buttonBlock}>
            <Link to="/registration">
              <Button variant="contained" style={{ padding: '14px 24px' }}>
                Смотреть все
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
