import { CommonAnalytics } from '@store/project/contracts';
import { useMemo } from 'react';

const localisedStatusesMap = new Map([
  ['New', 'Начальный'],
  ['InProgress', 'В работе'],
  ['Done', 'Завершен'],
  ['Cancelled', 'Отменен'],
  ['Paused', 'На паузе'],
]);

export function useProjectCommonAnalytics(commonAnalytics: CommonAnalytics | null) {
  const tasks = useMemo(() => {
    return commonAnalytics?.tasks;
  }, [commonAnalytics]);

  const tasksCount = useMemo(() => {
    if (!tasks) return 0;
    const allTasks = tasks.reduce((acc, task) => task.taskCount + acc, 0);

    return allTasks;
  }, [commonAnalytics]);

  const localisedStatuses = useMemo(() => {
    if (!tasks) return {};
    const statuses = tasks.reduce((acc, task) => {
      const status = localisedStatusesMap.get(task.statusType)!;
      return {
        ...acc,
        [status]: task?.taskCount,
      };
    }, {});

    return statuses;
  }, [commonAnalytics, tasks]);

  return { tasksCount, localisedStatuses };
}
