import { useCallback, useEffect, useState } from 'react';
import { Notification } from '@store/notification/contracts';
import { useStack } from './useStack';

type NotificationService = {
  poolingInterval: number;
  maxSize: number;
  stopInitialPooling?: boolean;
};

export const useNotification = ({ maxSize }: NotificationService) => {
  const { stack: notifications, push } = useStack<Notification>(maxSize, 'id');
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  const queryData: Notification[] = [];

  const addNotification = useCallback((newNotifications: Notification[]) => {
    newNotifications.forEach((notification) => {
      push(notification);
    });
  }, []);

  const resetCount = () => {
    setNewNotificationCount(0);
  };

  useEffect(() => {
    if (!queryData) return;

    if (queryData.length > 0) {
      addNotification(queryData);
    }
  }, [queryData]);

  const reversedNotifications = notifications.slice().reverse();

  return { notifications: reversedNotifications, newNotificationCount, resetCount };
};
