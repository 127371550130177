import { PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

interface AnalyticsResponse {
  pages: number;
  page: number;
  totalCount: number;
  queriedCount: number;
  startRecord: number;
}

export interface AnalyticsRequestsResponse extends AnalyticsResponse {
  data: AnalyticsRequests[];
}

export interface AnalyticsRequests {
  id: string;
  name: string;
  projectType: string;
  deadLine: string;
  budget: number;
  managers: Manager[];
  statusName: string;
  created: string;
}

interface Manager {
  managerId: string;
  managerName: string;
  statusId: string;
  statusName: string;
}

export interface AnalyticsVananciesResponse extends AnalyticsResponse {
  data: AnalyticsVacancies[];
}

export interface AnalyticsVacancies {
  id: string;
  vacancyName: string;
  offerStatusName: string;
  payment: string;
  vacancyId: string;
}

export interface AnalyticsTasks {
  team: string;
  project: string;
  newTasks: number;
  inProgress: number;
  inTesting: number;
  done: number;
  overdue: number;
}

type AdditionalProperty = {
  [key: string | symbol]: {
    value: string;
    searchType: 'LIKE' | 'EQUAL' | 'LESS' | 'GREATER';
    sort: boolean;
  };
};

export type RequestBodyType =
  | AdditionalProperty
  | {
      start: number;
      limit: number;
      sortOrder?: string;
    };

export type DashboardProjects = {
  projectsCount?: number;
  statusCountList?: ProjectStatus[];
};

type ProjectStatus = {
  statusType?: string;
  taskCount?: number;
};

export type ProjectTasksAnalytics = {
  projectId: string;
  projectName: string;
  statusCountTaskList: TaskStatus[];
};

type TaskStatus = {
  statusType: string;
  taskCount: number;
};

export class TimeProjectAnalytics {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  projectName!: string;
  spentTime!: number;
  remainingTime!: number;
}

export class TimeProjectAnalyticsResponse extends PaginationClass {
  @Type(() => TimeProjectAnalytics)
  data!: TimeProjectAnalytics[];
}

type Status = {
  statusName: string;
  count: number;
};

export type Release = {
  name: string;
  startDate: string;
  endDate: string;
  statusCountTaskList: Status[];
};
