import { Vacancy } from '@store/vacancy/contracts';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as PublicVacancyService from '@store/publicVacancy/publicVacancy.service';
import { apiErrorHandler } from '@store/api-client';
import { RootState } from '@store/index';
import Api from '@api-schema';

type PublicVacancyState = {
  publicVacancies: Vacancy[];
  loading: boolean;
  error: Error | null;
};

const initialState: PublicVacancyState = {
  publicVacancies: [],
  loading: false,
  error: null,
};

type SetActionPayload = {
  publicVacancies: Vacancy[];
};

const publicVacancySlice = createSlice({
  name: 'publicVacancy',
  initialState,
  reducers: {
    setPublicVacancies: (state, action: PayloadAction<SetActionPayload>) => {
      state.publicVacancies = [...action.payload.publicVacancies];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const publicVacancy = createAsyncThunk(
  'publicVacancy/vacancies',
  async (
    request: Api.operations['getExecutorVacancies_1']['requestBody']['content']['application/json'],
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await PublicVacancyService.getPublicVacancies({
        start: request.start,
        limit: request.limit,
        sortOrder: request.sortOrder,
        name: request.name,
        stack: request.stack,
        positionName: request.positionName,
        directionIds: request.directionIds,
      });
      dispatch(setPublicVacancies({ publicVacancies: response.data }));
      return response;
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

const { setPublicVacancies, setLoading } = publicVacancySlice.actions;
export const selectPublicVacancies = (state: RootState) => state.publicVacancy.publicVacancies;
export const selectIsLoadingPublicVacancies = (state: RootState) => state.publicVacancy.loading;
export const publicVacancyReducer = publicVacancySlice.reducer;
