import styles from '@components/AboutInfo/AboutInfo.module.scss';
import { styled, Typography as MTypography } from '@mui/material';
import tilda from '@assets/img/aboutInfo/tilda.svg';
import iconComputer from '@assets/img/aboutInfo/iconComputer.png';
import { ButtonMUI } from '@components/MUI/ButtonMUI';
import { Link } from 'react-router-dom';

export const Typography = styled(MTypography)`
  color: #515151;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;

  &:before {
    content: url(${tilda});
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  & sup {
    color: #199b3e;
    top: 0px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    align-items: flex-start;
    sup {
      top: -0.2rem;
    }
  }
`;

export function AboutInfo() {
  const handleScrollToPricing = () => {
    const pricingBlock = document.getElementById('pricing');
    pricingBlock?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={styles.aboutInfoBackground}>
      <div className={styles.aboutPlatform}>
        <div className={styles.aboutBlock}>
          <div className={styles.aboutSigns}>
            <p className={styles.title}>
              Egile – это платформа для разработки
              <br className={styles.hideBr} /> и реализации бизнес-проектов
              <br className={styles.hideBr} /> в <span>гибко настраиваемых командах</span>
            </p>
            <div className={styles.descriptionBlock}>
              Используйте интеграцию различных сервисов и инструментов для управления <br />{' '}
              командами, чтобы повысить производительность проектной работы <br />и снизить общие
              затраты
            </div>
            <div className={styles.buttons}>
              <ButtonMUI variant="contained" className={styles.buttons__connection}>
                <Link className="text-white" to="/authorization">
                  Подключиться к облаку
                </Link>
              </ButtonMUI>
              <ButtonMUI
                variant="outlined"
                onClick={handleScrollToPricing}
                className={styles.buttons__solution}>
                Нужно автономное решение?
              </ButtonMUI>
            </div>
          </div>
          <div className={styles.aboutBlock__computer}>
            <img className={styles.iconStyles} src={iconComputer} alt="computer" />
          </div>
        </div>
      </div>
    </div>
  );
}
