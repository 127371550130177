import { ApiClient } from '@store/api-client';
import { GetProjectsResponse, budgetAnalyticsData } from './contracts';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { ResponseBody } from '@store/utility';
import { PaginationQuery } from '@store/common';

export const getManagerCustomerProjects = async (request: Partial<PaginationQuery>) => {
  const { data } = await ApiClient.post<
    ResponseBody<Api.operations['getProjectsInWhichUserIsEitherCustomerOrManager']>
  >('/api/project/manager/customer/list', request);
  return plainToInstance(GetProjectsResponse, data);
};

//TODO:ручка api/project в ближайшее время должна быть добавлена, метод  updateProject обновить согласно схеме, временно убрал
// export const updateProject = async (request: RequestBody<Api.operations['saveProject']>) => {
//   const { data } = await ApiClient.post<ProjectUpdateResponse>('api/project', request);
//   enqueueSnackbar(`Проект отредактирован`, {
//     variant: 'success',
//   });
//   return data;
// };
//TODO: статусы проекта либо изменятся, либо их не будет, пока не трогаю
// export const getProjectStatuses = async (
//   projectId: PathParameters<Api.operations['getAvailableStatuses'], 'projectId'>,
// ) => {
//   const { data } = await ApiClient.get<ResponseBody<Api.operations['getAvailableStatuses']>>(
//     `/api/project/${projectId}/statuses`,
//   );
//   return plainToInstance(ProjectStatusesResponse, data);
// };
export const getBudgetAnalytics = async () => {
  const data = budgetAnalyticsData;
  return data;
};
