import { JsonForms } from '@jsonforms/react';
import AjvErrors from 'ajv-errors';
import { CoreActions, createAjv, JsonFormsCore, JsonSchema7 } from '@jsonforms/core';
import { JsonFormsInitStateProps, JsonFormsReactProps } from '@jsonforms/react';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { ErrorObject } from 'ajv';

type JsonFormFormData = {
  [name: string]: string;
};

export type JsonFormProps = {
  schema: JsonSchema7;
  formData: JsonFormFormData;
} & Pick<JsonFormsInitStateProps, 'cells' | 'uischema'> &
  Pick<JsonFormsReactProps, 'onChange'> &
  Partial<Pick<JsonFormsInitStateProps, 'renderers'>>;

const mapValidationErrors = (errors: ErrorObject[]) => {
  const fixedErrors: ErrorObject[] = [];

  errors.forEach((error: ErrorObject) => {
    if (error.keyword === 'errorMessage' && error.params?.errors) {
      error.params.errors.forEach((subError: any) => {
        if (subError.keyword === 'required') {
          const missingProperty = subError.params.missingProperty;

          fixedErrors.push({
            ...subError,

            instancePath: `/${missingProperty}`,

            message: error.message || subError.message,
          });
        } else {
          fixedErrors.push({
            ...subError,

            instancePath: subError.instancePath || error.instancePath,

            message: subError.message || error.message,
          });
        }
      });
    } else {
      fixedErrors.push({
        ...error,

        instancePath: error.instancePath || '',

        message: error.message,
      });
    }
  });

  return fixedErrors;
};

const ajv = createAjv({ allErrors: true });
AjvErrors(ajv);

export const JsonForm = ({
  schema,
  formData,
  renderers,
  cells,
  uischema,
  onChange,
}: JsonFormProps) => {
  const middleware = (
    state: JsonFormsCore,
    action: CoreActions,
    defaultReducer: (state: JsonFormsCore, action: CoreActions) => JsonFormsCore,
  ) => {
    const currentState = defaultReducer(state, action);

    const { errors, data } = currentState;

    const validate = ajv.compile(schema);

    const isValid = validate(data);

    let validationErrors;

    if (!isValid && Array.isArray(errors) && errors.length > 0) {
      validationErrors = mapValidationErrors(errors);
    }

    const updatedState = {
      ...currentState,
      errors: validationErrors,
    };

    return defaultReducer(updatedState, action);
  };

  return (
    <>
      <JsonForms
        data={formData}
        ajv={ajv}
        renderers={renderers ?? materialRenderers}
        cells={cells ?? materialCells}
        schema={schema}
        uischema={uischema}
        middleware={middleware}
        onChange={onChange}
        validationMode="ValidateAndShow"
      />
    </>
  );
};
