import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { ProfileAboutMe } from './profileComponents/ProfileAboutMe';
import { ProfileWork } from './profileComponents/ProfileWork';
import { ProfileAboutCompany } from './profileComponents/ProfileAboutCompany';
import { ProfileFooter } from './profileComponents/profileFooter/ProfileFooter';
import { ProfileMain } from './profileComponents/ProfileMain';
import { useParams } from 'react-router-dom';
import { User } from '@store/user/contracts';
import styles from './MyProfile.module.scss';
import { useLazyGetProfileQuery } from '@store/user/user.slice';

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userID } = useParams();
  const [user, setUser] = useState<User | null | undefined>(null);
  const directions = useAppSelector(selectDictionary(DictionaryName.DIRECTION));
  const myDirection = directions.find(
    (direction) => direction.id === user?.about?.directionId,
  )?.name;
  const statuses = useAppSelector(selectDictionary(DictionaryName.WORK_STATUS));
  const myStatus = statuses.find((status) => status.id === user?.work?.workStatusId)?.name;
  const [getProfile] = useLazyGetProfileQuery();

  useEffect(() => {
    if (userID) {
      getProfile(userID!).then((user) => setUser(user?.data));
    }
  }, [dispatch, userID]);

  return (
    <div className="col container-content">
      <div className={styles.profile__content}>
        <ProfileMain
          icon={user?.about?.icon}
          firstName={user?.about?.firstName}
          lastName={user?.about?.lastName}
          patronymic={user?.about?.patronymic}
          direction={myDirection}
          phones={user?.link?.phones}
          telegram={user?.link?.telegram}
          isFavorite={user?.favorite}
        />

        <ProfileAboutMe
          birthDate={user?.about?.birthDate}
          location={user?.about?.location}
          userName={user?.about?.userName}
          direction={myDirection}
          stack={user?.about?.stack}
          experience={user?.about?.experience}
          experienceDescription={user?.about?.experienceDescription}
          resumeUrl={user?.about?.resumeUrl}
          resume={user?.about?.resume}
          certificates={user?.about?.certificates}
          about={user?.about?.about}
        />

        <ProfileWork
          status={myStatus}
          hourPrice={user?.work?.hourPrice}
          monthPrice={user?.work?.monthPrice}
        />

        <ProfileAboutCompany
          logoUrl={user?.organization?.logo}
          name={user?.organization?.name}
          url={user?.organization?.url}
          officeLocation={user?.organization?.officeLocation}
          email={user?.organization?.email}
          phone={user?.organization?.phone}
          description={user?.organization?.description}
          rules={user?.organization?.rules}
          inn={user?.organization?.inn}
        />

        <ProfileFooter registration={user?.created} />
      </div>
    </div>
  );
};
