import { baseQuery } from '@store/api-client';
import {
  ChatDetailed,
  FavoriteMessagesResponse,
  PaginatedChat,
  SetFavoriteMessageRequest,
} from './contracts';
import { createApi } from '@reduxjs/toolkit/query/react';
import Api from '@api-schema';
import { plainToInstance } from 'class-transformer';
import { ResponseBody } from '@store/utility';

export const chatApi = createApi({
  reducerPath: 'chats',
  tagTypes: ['chatsList', 'chatDialogs'],
  baseQuery,
  endpoints: (builder) => ({
    chats: builder.query<PaginatedChat, Api.operations['getChats']['parameters']['query']>({
      query: (request) => ({
        method: 'GET',
        url: `/api/chat`,
        params: request,
      }),
      providesTags: ['chatsList'],
      transformResponse: (
        response: Api.operations['getChats']['responses']['200']['content']['application/json'],
      ) => {
        return plainToInstance(PaginatedChat, response);
      },
    }),

    createChat: builder.mutation<
      Api.operations['createChat']['responses']['200']['content']['application/json'],
      Api.operations['createChat']['requestBody']['content']['application/json']
    >({
      query: (body) => ({
        method: `POST`,
        url: `/api/chat`,
        body,
      }),
    }),

    markMessagesAsViewed: builder.mutation<
      Api.operations['readMessage']['responses']['200']['content']['application/json'],
      Api.operations['readMessage']['requestBody']['content']['application/json']
    >({
      query: (body) => ({
        method: 'PUT',
        url: `/api/message`,
        body,
      }),
    }),

    sendMessage: builder.mutation<
      Api.operations['sendMessage']['responses']['200']['content']['application/json'],
      Api.operations['sendMessage']['requestBody']['content']['application/json']
    >({
      query: (body) => ({
        method: 'POST',
        url: '/api/message',
        body,
      }),
      invalidatesTags: ['chatDialogs'],
    }),

    chat: builder.query<ChatDetailed, Api.operations['getMessages']['parameters']>({
      query: (params) => ({
        method: `GET`,
        url: `api/message/${params.path.id}`,
        params: params.query,
      }),
      providesTags: ['chatDialogs'],
      keepUnusedDataFor: 0,
      transformResponse: (
        response: Api.operations['getMessages']['responses']['200']['content']['application/json'],
      ) => {
        return plainToInstance(ChatDetailed, response);
      },
    }),

    setFavoriteMessage: builder.mutation<
      ResponseBody<Api.operations['changeFavoriteStatus_5']>,
      SetFavoriteMessageRequest
    >({
      query: (params) => ({
        method: 'PUT',
        url: `/api/chat/message/${params.id}/favorite`,
        body: params.requestBody,
      }),
    }),

    getFavoriteMessages: builder.query<
      FavoriteMessagesResponse,
      Api.operations['getFavoriteMessages']['requestBody']['content']['application/json']
    >({
      query: (body) => ({
        method: 'POST',
        url: '/api/chat/message/favorite',
        body: body,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: Api.operations['getFavoriteMessages']['responses']['200']['content']['application/json'],
      ) => {
        return plainToInstance(FavoriteMessagesResponse, response);
      },
    }),

    getMessagePage: builder.mutation<
      Api.operations['getMessagePage']['responses']['200']['content']['application/json'],
      Api.operations['getMessagePage']['requestBody']['content']['application/json']
    >({
      query: (requestBody) => ({
        method: 'POST',
        url: '/api/chat/message/page',
        body: requestBody,
      }),
    }),

    getPersonalChat: builder.mutation<
      Api.operations['getPersonalChat']['responses']['200']['content']['application/json'],
      Api.operations['getPersonalChat']['parameters']['path']['id']
    >({
      query: (params) => ({
        method: `GET`,
        url: `/api/chat/personal/${params}`,
      }),
      transformResponse: (
        response: Api.operations['getPersonalChat']['responses']['200']['content']['application/json'],
      ) => {
        return response;
      },
    }),
  }),
});

export const {
  useChatsQuery,
  useChatQuery,
  useSendMessageMutation,
  useSetFavoriteMessageMutation,
  useGetFavoriteMessagesQuery,
  useGetMessagePageMutation,
  useGetPersonalChatMutation,
} = chatApi;
