export const SendMessageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M3.49878 20C3.09878 20 2.74878 19.85 2.44878 19.55C2.14878 19.25 1.99878 18.9 1.99878 18.5V5.5C1.99878 5.1 2.14878 4.75 2.44878 4.45C2.74878 4.15 3.09878 4 3.49878 4H20.4988C20.8988 4 21.2488 4.15 21.5488 4.45C21.8488 4.75 21.9988 5.1 21.9988 5.5V18.5C21.9988 18.9 21.8488 19.25 21.5488 19.55C21.2488 19.85 20.8988 20 20.4988 20H3.49878ZM11.9988 12.45L3.49878 6.875V18.5H20.4988V6.875L11.9988 12.45ZM11.9988 10.95L20.3988 5.5H3.62378L11.9988 10.95ZM3.49878 6.875V5.5V18.5V6.875Z"
        fill="#4CA0E0"
      />
    </svg>
  );
};
