import React, { useCallback, useEffect, useState } from 'react';
import { Select, SelectOption } from '@components/MUI/Select';
import { SelectChangeEvent } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { object } from 'yup';
import { Button } from '@components/MUI/Button';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import styles from './AddTrackerForm.module.scss';
import { selectUserIntegrations } from '@store/user/user.slice';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import { YandexTracker } from './TrackerIntegrations/YandexTracker';
import { SysTracker } from './TrackerIntegrations/SysTracker';

export type AddTrackerValue = {
  tracker: INTEGRATION_PROVIDER;
  organizationId: string;
  externalProjectId: string;
};

type AddTrackerProps = {
  submit: (value: AddTrackerValue, form: FormikBag<AddTrackerProps, AddTrackerValue>) => void;
};

const addTrackerSchema = object({
  tracker: Yup.string(),
  externalProjectId: Yup.string().required('Поле обязательно для заполнения'),
});

const yandexTrackerSchema = addTrackerSchema.shape({
  organizationId: Yup.string().required('Поле обязательно для заполнения'),
});

const trackersConfig = {
  [INTEGRATION_PROVIDER.YANDEX]: {
    validationSchema: yandexTrackerSchema,
    component: YandexTracker,
  },
  [INTEGRATION_PROVIDER.SYS]: {
    validationSchema: addTrackerSchema,
    component: SysTracker,
  },
  [INTEGRATION_PROVIDER.EGILE]: {
    validationSchema: addTrackerSchema,
    component: null,
  },
};

const AddTracker: React.FC = () => {
  const dispatch = useAppDispatch();
  const form = useFormikContext<AddTrackerValue>();
  const integrations = useAppSelector(selectUserIntegrations);

  const [integrationOptions, setIntegrationOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (integrations.length > 0) {
      setIntegrationOptions(
        integrations.map((integration) => ({ value: integration, label: integration })),
      );
    }
  }, []);

  useEffect(() => {
    const schema =
      trackersConfig[form.values.tracker as INTEGRATION_PROVIDER]?.validationSchema ||
      addTrackerSchema;
    form.validationSchema = schema;
  }, [form.values.tracker]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const SelectedTracker =
    trackersConfig[form.values.tracker as INTEGRATION_PROVIDER]?.component || null;
  return (
    <Form onSubmit={form.handleSubmit} className={styles.form}>
      <Select
        name="tracker"
        values={integrationOptions}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('tracker', event.target.value);
        }}
        isOutlined={true}
        label="Трекер"
      />
      {SelectedTracker && <SelectedTracker />}
      <div className="d-flex justify-content-end mt-5">
        <Button className={styles.button__cancel} onClick={handleCancel}>
          Отменить
        </Button>
        <Button
          disabled={!form.isValid}
          className={styles.button__submit}
          variant="contained"
          type="submit"
          disableElevation={true}>
          Добавить
        </Button>
      </div>
    </Form>
  );
};

export const AddTrackerForm = withFormik<AddTrackerProps, AddTrackerValue>({
  displayName: 'AddTrackerForm',
  enableReinitialize: true,
  validationSchema: addTrackerSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(AddTracker);
