import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { StatusLabel, statusProjectColors } from '@components/ui/StatusLabel/StatusLabel';
import styles from './ProjectAnalyticsLayout.module.scss';
import { useDetailProjectQuery } from '@store/project/project.slice';

export const ProjectAnalyticsLayout: React.FC = () => {
  const { projectId } = useParams();
  const { data: project } = useDetailProjectQuery(projectId!);

  return (
    <div className={styles.common__analytics}>
      <div className={styles.common__analytics__head}>
        <h2>{project?.name}</h2>
        <StatusLabel
          text={project?.projectStatusName || ''}
          color={statusProjectColors.get(project?.projectStatusName || '')}
        />
      </div>
      <header className={classNames('header', styles.common__analytics__links)}>
        <ul>
          <li>
            <NavLink
              to={`/projects/${projectId}/analytics`}
              className={({ isActive }) => (isActive ? 'active' : '')}
              end>
              Общая аналитика
            </NavLink>
          </li>
        </ul>
      </header>
      <div>
        <div className={styles.common__analytics__blocks}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
