import { Chip } from '@mui/material';
import { TaskTransition } from '@store/tasks/contracts';
import styles from './TaskTransitions.module.scss';

type TaskTransitionsProps = {
  transitions: TaskTransition[];
  onChange: (transition: TaskTransition) => void;
  className?: string;
};

export function TaskTransitions({ transitions, onChange, className }: TaskTransitionsProps) {
  return (
    <div className={styles.container}>
      {transitions.map((transition) => (
        <Chip
          key={transition.id}
          label={transition.display}
          variant="outlined"
          onClick={() => onChange(transition)}
          className={className}
        />
      ))}
    </div>
  );
}
