import React, { useEffect, useState } from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { SpentTime } from '@store/tasks/contracts';
import { getSpentTime } from '@store/tasks/tracker.service';
import { SpentTimeItem } from './SpentTimeItem';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { AddTimeModal } from '../AddTimeModal/AddTimeModal';
import styles from './SpentTimeModal.module.scss';

type SpentTimeModalProps = {
  taskId?: string;
};

export const SpentTimeModal: React.FC<SpentTimeModalProps> = ({ taskId }) => {
  const dispatch = useAppDispatch();
  const [spentTime, setSpentTime] = useState<SpentTime | null>(null);

  useEffect(() => {
    if (taskId) {
      getSpentTime().then((spentTime) => setSpentTime(spentTime));
    }
  }, [taskId]);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleAdd = () => {
    dispatch(openModal(<AddTimeModal />));
  };

  return (
    <Modal boxClassName={styles.modal__wrapper}>
      <FormWrapper label="Затраченное время">
        <div className={styles.modal}>
          <div className={styles.modal__total}>
            <span>Всего: </span>
            <span className={styles.modal__total__value}>{spentTime?.totalTime}</span>
          </div>
          <div className={styles.modal__table}>
            <div className={styles.modal__table__head}>
              <div className={styles.modal__table__head__item}>Времени затрачено</div>
              <div className={styles.modal__table__head__item}>Дата</div>
              <div className={styles.modal__table__head__item}>Комментарий</div>
              <div className={styles.modal__table__head__item}></div>
            </div>
            {spentTime?.timeItems?.map((timeItem, index, timeArray) => (
              <>
                <SpentTimeItem timeItem={timeItem} key={timeItem.id} />
                {index !== timeArray.length - 1 ? <hr /> : <></>}
              </>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button className={styles.buttons__cancel} onClick={handleCancel}>
              Закрыть
            </Button>
            <Button className={styles.buttons__add} variant="contained" onClick={handleAdd}>
              Добавить время
            </Button>
          </div>
        </div>
      </FormWrapper>
    </Modal>
  );
};
