import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox, InputAdornment, ListItemText, MenuItem, Paper } from '@mui/material';
import { ProjectBars } from './ProjectBars';
import { TaskStatusList } from './TaskStatusList';
import styles from './TasksByStatus.module.scss';
import { useGetTaskStatusesQuery } from '@store/dashboard/dashboard.slice';
import { useGetProjectsQuery } from '@store/project/project.slice';
import { Select, SelectOption } from '@components/MUI/Select';
import { Formik } from 'formik';
import { ReactComponent as ClearIcon } from '@assets/img/close-icon.svg';
import { FormikObserver } from '@helpers/FormikObserver';
import { RoleCodes } from '@store/user/contracts';

export const TasksByStatus: React.FC = () => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const { data: projects } = useGetTaskStatusesQuery({ projectIds: selectedProjects });
  const { data: usersProjects } = useGetProjectsQuery({
    limit: 50,
    start: 0,
    sortOrder: 'desc',
    roles: [RoleCodes.Customer, RoleCodes.Manager],
  });

  const projectForSelect = useMemo(() => {
    return usersProjects?.data.map((usersProject) => {
      return {
        value: usersProject.id,
        label: usersProject.name,
      };
    });
  }, [usersProjects?.data]);

  const renderOption = useCallback((option: SelectOption, value: string[]) => {
    return (
      <MenuItem value={option.value} key={option.value}>
        <div className={styles.select_option}>
          <Checkbox
            sx={{
              padding: '0',
            }}
            checked={value.includes(option.value)}
          />
          <ListItemText primary={option.label} />
        </div>
      </MenuItem>
    );
  }, []);

  const handleChangeSelectedProjects = ({ selectedProject }: { selectedProject: string[] }) => {
    setSelectedProjects(selectedProject);
  };

  const convertValuesToLabels = useCallback(
    (selectedValues: string[]) => {
      return selectedValues.map((value) => {
        return projectForSelect?.find((projectToSelect) => projectToSelect.value === value)?.label;
      });
    },
    [projectForSelect],
  );

  return (
    <Paper elevation={0} variant="outlined" className={styles.container}>
      <h2 className={styles.title}>Количество задач по статусам</h2>
      <p className={styles.select__label}>Проекты</p>
      <Formik
        onSubmit={() => {}}
        initialValues={{
          selectedProject: [],
        }}>
        {({ values, setFieldValue }) => (
          <div className={styles.select}>
            <FormikObserver onChange={handleChangeSelectedProjects} />
            <Select
              name="selectedProject"
              values={projectForSelect ?? []}
              isOutlined
              fullWidth
              disabled={projectForSelect?.length === 0}
              multiple
              displayEmpty
              renderValue={(selected) => {
                if ((Array.isArray(selected) && selected?.length === 0) || !selected) {
                  return <p className={styles.select_placeholder}>Выберите проект</p>;
                } else if (Array.isArray(selected)) {
                  const selectedLabels = convertValuesToLabels(selected);
                  return (
                    <p className={styles.select_selectedProjects}>{selectedLabels.join(', ')}</p>
                  );
                }
              }}
              renderOption={renderOption}
              endAdornment={
                values.selectedProject.length > 0 ? (
                  <InputAdornment position="end" sx={{ marginRight: '16px' }}>
                    {values.selectedProject.length > 1 && (
                      <span className={styles.select_selectedCounter}>
                        {values.selectedProject.length}
                      </span>
                    )}
                    <ClearIcon
                      className={styles.select_clearIcon}
                      onClick={() => setFieldValue('selectedProject', [])}
                    />
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
          </div>
        )}
      </Formik>
      <div className={styles.content}>
        <ProjectBars projects={projects} />
        <TaskStatusList />
      </div>
    </Paper>
  );
};
