import styles from './CardsCustomer.module.scss';
import React from 'react';

interface CardsCustomerProps {
  cardNumber: string;
  cardTitle: string;
  cardDescription: string;
  cardImg: string;
}

export const CardsCustomer: React.FC<CardsCustomerProps> = (CardsCustomerProps) => {
  return (
    <div className={styles.card_main}>
      <div className={styles.card_number_container}>
        <span className={styles.card_number}>{CardsCustomerProps.cardNumber}</span>
        <div className={styles.card_element}></div>
      </div>
      <div className={styles.card_title_container}>
        <h1 className={styles.card_title}>{CardsCustomerProps.cardTitle}</h1>
        <span className={styles.card_description}>{CardsCustomerProps.cardDescription}</span>
      </div>
      <div className={styles.card_icon_container}>
        <img src={CardsCustomerProps.cardImg} className={styles.cardIconImg} alt="" />
      </div>
    </div>
  );
};
