import { useMemo } from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { Checkbox } from '@components/ui/Checkbox/Checkbox';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import dayjs from 'dayjs';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { SelectChangeEvent, Tooltip } from '@mui/material';
import { Button } from '@components/MUI/Button';
import styles from './CreateProjectForm.module.scss';
import { DragAndDrop } from '@components/ui/DranAndDrop/DragAndDrop';
import classNames from 'classnames';
import { Attachments } from '@store/project/contracts';
import { ReactComponent as HelpIcon } from '@assets/img/help.svg';
import { useParams } from 'react-router-dom';
import { MarkdownEditorComponent } from '@components/ui/MarkdownEditor';
import { withSuspense } from 'src/hocs/withSuspense';

const MarkdownEditor = withSuspense(MarkdownEditorComponent);

export type CreateProjectFormProps = {
  value: CreateProjectFormValue;
  submit: (
    value: CreateProjectFormValue,
    form: FormikBag<CreateProjectFormProps, CreateProjectFormValue>,
  ) => void;
};

type CreateProjectFormValue = {
  name: string;
  projectTypeId: string;
  deadLine: dayjs.Dayjs;
  description: string;
  budget: number;
  tag?: string;
  currencyId: string;
  stack: string;
  requirement: string;
  task: string;
  managerNeed?: boolean;
  attachments: Attachments[];
  visibilityStatusId: string;
};

const CreateRequestFormSchema = Yup.object({
  name: Yup.string()
    .required('Поле обязательно для заполнения')
    .min(7, 'Название заявки должно содержать минимум 7 символов'),
  projectTypeId: Yup.string().required('Поле обязательно для заполнения'),
  deadLine: Yup.date()
    .min(
      new Date().toISOString().split('T')[0],
      `Выберите сегодняшнюю дату или от ${new Date(Date.now() + 86400000).toLocaleDateString()}`,
    )
    .required('Поле обязательно для заполнения'),
  description: Yup.string().required('Поле обязательно для заполнения').nullable(),
  budget: Yup.number()
    .moreThan(0, 'Бюджет не может быть отрицательным')
    .required('Поле обязательно для заполнения')
    .max(999999999, 'Введите число меньше 1000000000'),
  tag: Yup.string().optional(),
  visibilityStatusId: Yup.string().optional(),
  currencyId: Yup.string().required('Поле обязательно для заполнения'),
  attachments: Yup.array(),
  stack: Yup.string().required('Поле обязательно для заполнения'),
  requirement: Yup.string().required('Поле обязательно для заполнения'),
  task: Yup.string().required('Поле обязательно для заполнения'),
  managerNeed: Yup.boolean(),
});

const FormComponent = () => {
  const form = useFormikContext<CreateProjectFormValue>();
  const { projectId } = useParams();

  form.validationSchema = CreateRequestFormSchema;
  const projectTypes = useDictionary(DictionaryName.PROJECT_TYPE, fromDictionaryToSelectOption);
  const visibilityStatuses = useDictionary(
    DictionaryName.VISIBILITY_STATUS,
    fromDictionaryToSelectOption,
  );
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);

  const isEdit = useMemo(() => {
    return Boolean(projectId);
  }, [projectId]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название проекта"
          type="text"
          className={styles.form__field}
        />
        <Select
          name="projectTypeId"
          onChange={(event: SelectChangeEvent) => {
            form.setFieldValue('projectTypeId', event.target.value);
          }}
          values={projectTypes}
          label="Тип проекта"
          isOutlined
          className={styles.form__field}
        />
        <div className={styles.visibility}>
          <Select
            name="visibilityStatusId"
            onChange={(event: SelectChangeEvent) => {
              form.setFieldValue('visibilityStatusId', event.target.value);
            }}
            values={visibilityStatuses}
            label="Доступность"
            isOutlined
            className={styles.form__field}
          />
          <Tooltip
            className={styles.visibility__tooltip}
            title="Публичный проект доступен всем пользователем системы.
           Приватный проект только тем пользователям, которые были подключены к проекту  ">
            <HelpIcon />
          </Tooltip>
        </div>

        <InputField
          name="deadLine"
          placeholder="Дедлайн проекта"
          type="date"
          className={styles.form__field}
        />
        <MarkdownEditor
          name="description"
          className={styles.form__field}
          placeholder="Описание проекта"
        />
        <InputField name="tag" placeholder="Тег" type="text" className={styles.form__field} />
        <InputField
          name="budget"
          placeholder="Бюджет проекта"
          type="number"
          className={styles.form__field}
        />
        <Select
          name="currencyId"
          onChange={(event: SelectChangeEvent) => {
            form.setFieldValue('currencyId', event.target.value);
          }}
          values={currencies}
          label="Валюта"
          isOutlined
          className={styles.form__field}
        />
        <DragAndDrop
          name="attachments"
          tag="PROJECT"
          accept={{
            'application/*': ['.doc', '.docx', '.pdf'],
            'text/*': ['.txt'],
          }}
        />
        <InputField
          name="stack"
          placeholder="Стэк технологий"
          type="text"
          className={classNames(styles.form__field, styles.stack__field)}
        />
        <InputField
          name="requirement"
          placeholder="Требования к разработке"
          type="text"
          className={styles.form__field}
          multiline
          rows={4}
        />
        <InputField
          name="task"
          placeholder="Что необходимо сделать"
          type="text"
          className={styles.form__field}
          multiline
          rows={4}
        />
        {!isEdit && (
          <Checkbox
            name="managerNeed"
            label="Заказчик принимает на себя управление проектом"
            fieldClassName={styles.checkbox}
          />
        )}
      </div>
      <Button
        type="submit"
        disabled={!form.isValid || form.isSubmitting}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        {isEdit ? 'Сохранить' : 'Создать'}
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const CreateProjectForm = withFormik<CreateProjectFormProps, CreateProjectFormValue>({
  displayName: 'CreateRequestForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      projectTypeId: props.value.projectTypeId,
      deadLine: props.value.deadLine,
      description: props.value.description,
      budget: props.value.budget,
      tag: props.value.tag,
      currencyId: props.value.currencyId,
      visibilityStatusId: props.value.visibilityStatusId,
      stack: props.value.stack,
      requirement: props.value.requirement,
      task: props.value.task,
      managerNeed: props.value.managerNeed,
      attachments: props.value.attachments,
    };
  },
  enableReinitialize: true,
  validationSchema: CreateRequestFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
