import styles from '@pages/news/newsDetail/NewsDetail.module.scss';
import { formatDate } from '@components/ui/formatDate';
import { CircularProgress } from '@mui/material';
import { DynamicHtmlContainer } from '@components/DynamicHtmlContainer';
import { Link, useParams } from 'react-router-dom';
import { useGetNewsDetailQuery } from '@store/news/news';

export const NewsDetail = () => {
  const { newsId } = useParams();

  const { data: newsData, isLoading, isError, isFetching } = useGetNewsDetailQuery(newsId!);

  let result;

  if (isError || !newsData) {
    result = (
      <div className="pt-5 w-100 text-center">
        <h3>Ошибка загрузки новости</h3>
      </div>
    );
  } else {
    result = (
      <div>
        <div
          className={`${styles.banner} d-flex`}
          style={{
            backgroundImage: `url(${newsData?.image})`,
          }}>
          <div className={`${styles.banner__backdrop} w-100`}>
            <div className={styles.container}>
              {newsData.tags ? (
                <div className="d-flex">
                  {newsData.tags.map((tag: any) => (
                    <Link
                      to={{
                        pathname: '/news',
                        search: `tags__id=${tag.id}`,
                      }}
                      className={`${styles.tag} me-3 mb-3`}
                      key={tag.id}>
                      {tag.name}
                    </Link>
                  ))}
                </div>
              ) : (
                ''
              )}

              <h1 className="mb-3 mb-lg-4'">{newsData?.title}</h1>

              <p className={`mb-0 ${styles.date}`}>
                {formatDate(newsData?.pub_date, 'DD.MM.YYYY')}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.container}>
          <div className={`${styles.breadcrumbs} mb-4`}>
            <Link to="/">Главная</Link>
            <Link
              to={{
                pathname: '/news/',
                search: `page_size=5&page=1`,
              }}>
              Новости
            </Link>
            <span>{newsData?.title}</span>
          </div>
        </div>

        <DynamicHtmlContainer
          className={`${styles.container} ${styles.content}`}
          input={newsData?.content}></DynamicHtmlContainer>

        <div className={styles.container}>
          <Link
            to={{
              pathname: '/news/',
              search: `page_size=5&page=1`,
            }}>
            <div className="d-flex align-items-center">
              <svg
                className="me-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.6883 2.7594C16.0807 2.36691 16.718 2.36973 17.107 2.76568C17.4911 3.15671 17.4884 3.78429 17.1008 4.17191L9.93289 11.3398C9.54236 11.7303 9.54236 12.3635 9.93289 12.754L17.1008 19.9218C17.4884 20.3095 17.4911 20.937 17.107 21.3281C16.718 21.724 16.0807 21.7268 15.6883 21.3343L7.10789 12.754C6.71736 12.3635 6.71736 11.7303 7.10789 11.3398L15.6883 2.7594Z"
                  fill="#78858B"
                />
              </svg>
              <span className={styles.navLink}>Вернуться к новостям</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        {isLoading || isFetching ? (
          <div className="pt-5 w-100 text-center">
            <CircularProgress />
          </div>
        ) : (
          result
        )}
      </div>
    </div>
  );
};
