import { useTheme } from '@mui/material';
import styles from './StatusTypesList.module.scss';
import { TaskStatusType } from '@store/tasks/contracts';

type StatusTypesListProps = {
  statuses: Record<string, number>;
};

export const StatusTypesList = ({ statuses }: StatusTypesListProps) => {
  const theme = useTheme();
  return (
    <div className={styles.tags__list}>
      {Object.entries(statuses).map(([key, value]) => (
        <div className={styles.tags__list__tag} key={key}>
          <div
            className={styles.tags__list__tag__color}
            style={{
              backgroundColor: theme.statuses.typesColorPalette[key as TaskStatusType],
            }}></div>
          <span className={styles.tags__list__tag__title}>{key}</span>
          <span className={styles.tags__list__tag__count}>{value}</span>
        </div>
      ))}
    </div>
  );
};
