import { PaginationClass, ToDate } from '@store/common';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import { JsonSchema7 } from '@jsonforms/core';
import Api from '@api-schema';

export class GetTasksResponse extends PaginationClass {
  @Type(() => Task)
  data!: Task[];
}

export class TaskField {
  id!: string;
  key!: string;
  display!: string;
}

export class TrackerExecutor {
  id!: string;
  userName!: string;
  integrations!: INTEGRATION_PROVIDER[];
}

export enum TaskStatusType {
  New = 'New',
  Paused = 'Paused',
  InProgress = 'InProgress',
  Done = 'Done',
  Cancelled = 'Cancelled',
}

export class Task {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  externalIdentifier!: string;
  favorite!: boolean;
  name!: string;
  description!: string;
  statusType!: TaskStatusType;
  @Type(() => TaskField)
  status!: TaskField;
  @Type(() => TaskField)
  type!: TaskField;
  @Type(() => TaskField)
  priority!: TaskField;
  @Type(() => TaskField)
  queue!: TaskField;
  provider!: INTEGRATION_PROVIDER;
  projectId!: string;
  projectName!: string;
  executors: string[] = [];
  key!: string;
  tags!: string[];
  @ToDate('YYYY-MM-DD')
  deadline!: Dayjs;
  transitions: TaskTransition[] = [];
  estimation!: number | null;
}

type TaskTransitionFieldOption = {
  key?: string;
  display?: string;
} | null;

export type TaskTransitionsField = {
  key?: string;
  display?: string;
  required?: boolean;
  type?: string;
  elementType?: string | null;
  options?: TaskTransitionFieldOption[] | null;
};

export class TaskTransition {
  id?: string;
  display?: string;
  fields?: Api.components['schemas']['TrackerTaskTransitionFieldDTO'][];
  schema?: JsonSchema7;
}

export interface Project {
  id: number;
  version: number;
  key: string;
  name: string;
  description: string;
  status: string;
  lead: PreviewKeyless;
  organizationId: number;
}

interface PreviewKeyless {
  self: string;
  id: number;
  display: string;
}

export enum TrackerProvider {
  Yandex = 'YANDEX',
}

export interface LoadProjectsRequest {
  provider: TrackerProvider;
}

export interface AttachProjectRequest {
  provider: string;
  easyProjectId: string;
  trackerProjectId: number;
  organizationId: number;
}

export interface CreateTaskRequest {
  data: {
    projectId: string;
    description: string;
    name: string;
    priority: TaskField | null;
    type: TaskField | null;
    queue?: TaskField | null;
    tags: string[];
    executors: string[];
    deadline: string | null;
    estimation: number | null;
  };
}

export interface UpdateTaskRequest {
  taskId: string;
  data: {
    projectId: string;
    description: string;
    name: string;
    priority: TaskField | null;
    type: TaskField | null;
    queue?: TaskField | null;
    tags: string[];
    executors: string[];
    deadline: string | null;
    estimation: number | null;
  };
}

export interface CreateTaskResponse {
  success: boolean;
}

export type SpentTime = {
  totalTime: string;
  timeItems: TimeItem[];
};

export type TimeItem = {
  id: string;
  timeSpent: string;
  date: string;
  comment: string | null;
};

export const SpentTimeMock: SpentTime = {
  totalTime: '2 час(а) 40 минут(ы)',
  timeItems: [
    {
      id: '1',
      timeSpent: '50 минут',
      date: '05.04.2024',
      comment:
        'Идейные соображения высшего порядка, а также новая модель организационной деятельности способствует подготовки и реализации форм развития.',
    },
    {
      id: '2',
      timeSpent: '50 минут',
      date: '03.04.2024',
      comment: null,
    },
    {
      id: '3',
      timeSpent: '1 час',
      date: '02.04.2024',
      comment:
        'Идейные соображения высшего порядка, а также новая модель организационной деятельности способствует подготовки и реализации форм развития.',
    },
  ],
};

export type SysTaskStatus = {
  id?: number;
  name?: string;
};
