import React, { useMemo } from 'react';
import { CardsBlock, CommonAnalyticsSummaryCards } from './CardsBlock/CardsBlock';
import { PieBlock } from './PieBlock/PieBlock';
import { CommonAnalytics } from '@store/project/contracts';
import styles from './CommonAnalyticsBlock.module.scss';
import { useGetProjectSummaryQuery } from '@store/project/project.slice';
import { useParams } from 'react-router-dom';

export type CommonAnalyticsBlockProps = {
  commonAnalytics: CommonAnalytics | null;
};

export const CommonAnalyticsBlock: React.FC = () => {
  const { projectId } = useParams();
  const { data: projectSummary } = useGetProjectSummaryQuery(projectId!, {
    skip: !projectId,
  });

  const dataForCards: CommonAnalyticsSummaryCards | null = useMemo(() => {
    if (!projectSummary) return null;
    return {
      spentHours: projectSummary.spentHours,
      executorsCount: projectSummary.executorsCount,
      daysUntilDeadline: projectSummary.daysUntilDeadline,
      tasksAtWork: projectSummary.tasksCount,
    };
  }, [projectSummary]);
  return (
    <div>
      <div className={styles.common__analytics}>
        <CardsBlock summary={dataForCards} />
        {projectSummary && <PieBlock commonAnalytics={projectSummary} />}
      </div>
    </div>
  );
};
