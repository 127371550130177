import styles from './LegalDocumentDetail.module.scss';
import { CircularProgress } from '@mui/material';
import { useGetLegalDocumentByCodeQuery } from '@store/legalDocument/legalDocument';
import { useParams } from 'react-router-dom';
import download from '@assets/img/upload.svg';
import { DynamicHtmlContainer } from '@components/DynamicHtmlContainer';

export const LegalDocumentDetail = () => {
  const { code } = useParams();
  const { data: document, isLoading, isError } = useGetLegalDocumentByCodeQuery(code);

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {isLoading && (
          <div className="pt-5 w-100 text-center">
            <CircularProgress />
          </div>
        )}
        {isError && (
          <div className="pt-5 w-100 text-center">
            <h3>Ошибка загрузки документа</h3>
          </div>
        )}
        {document && (
          <div>
            <h3 className="mb-3 mb-lg-4">{document?.name}</h3>
            <DynamicHtmlContainer input={document?.description} />
            <DynamicHtmlContainer className={styles.text} input={document?.html} />
            {document?.latest_file?.file && (
              <a
                key={document?.name}
                href={document?.latest_file?.file}
                target="_blank"
                rel="noreferrer">
                Загрузить
                <img src={download} alt="загрузить" />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
