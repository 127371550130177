import dayjs from 'dayjs';
import { CreateProjectForm, CreateProjectFormProps } from './CreateProjectForm';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './CreateProjectForm.module.scss';
import { useCreateProjectMutation } from '@store/project/project.slice';
import { useNavigate } from 'react-router-dom';

export const CreateProject = () => {
  const navigate = useNavigate();
  const [createProject] = useCreateProjectMutation();

  const createSaveRequest: CreateProjectFormProps['submit'] = async (CreateRequestForm, form) => {
    try {
      form.setSubmitting(true);
      const { id } = await createProject({
        name: CreateRequestForm.name,
        projectTypeId: CreateRequestForm.projectTypeId,
        deadLine: dayjs(CreateRequestForm.deadLine).format('DD.MM.YYYY HH:mm'),
        description: CreateRequestForm.description,
        budget: CreateRequestForm.budget,
        tag: CreateRequestForm.tag,
        currencyId: CreateRequestForm.currencyId,
        stack: CreateRequestForm.stack,
        requirement: CreateRequestForm.requirement,
        task: CreateRequestForm.task,
        managerNeed: !CreateRequestForm.managerNeed,
        attachments: CreateRequestForm.attachments.map((item) => item.id),
        visibilityStatusId: CreateRequestForm.visibilityStatusId,
      }).unwrap();
      navigate(`/projects/${id}/details`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className="row">
        <div className={styles.form__page__container}>
          <h2 className={styles.form__page__title}>Создание проекта</h2>
          <CreateProjectForm
            value={{
              name: '',
              projectTypeId: '',
              deadLine: dayjs(),
              description: '',
              budget: Number(''),
              tag: '',
              currencyId: '',
              visibilityStatusId: '',
              stack: '',
              requirement: '',
              task: '',
              managerNeed: false,
              attachments: [],
            }}
            submit={createSaveRequest}
          />
        </div>
      </div>
    </Card>
  );
};
