import { Transform } from 'class-transformer';
import dayjs from 'dayjs';

interface CancellationQuery {
  signal?: AbortSignal;
}

export interface Pagination<D> {
  pages: number | undefined;
  page: number | null;
  totalCount: number;
  queriedCount: number;
  startRecord: number;
  data: D[];
}

export interface PaginationQuery extends CancellationQuery, AdditionalProperty {
  start: number;
  limit: number;
  sortOrder: 'asc' | 'desc';
}

interface AdditionalProperty {
  [key: string]:
    | {
        value: string;
        searchType: 'LIKE' | 'EQUAL' | 'LESS' | 'GREATER';
        sort: boolean;
      }
    | string
    | number
    | AbortSignal
    | undefined;
}

export function ToDate(from: string) {
  return Transform(
    (params) => {
      if (params.value) {
        return dayjs(params.value, from);
      }
    },
    {
      toClassOnly: true,
    },
  );
}

export function ToApiFile() {
  return Transform(
    (params) => {
      return params.value ? `${process.env.REACT_APP_API_URL}/api/file/${params.value}` : undefined;
    },
    {
      toClassOnly: true,
    },
  );
}

export class PaginationClass {
  pages!: number;
  page!: null | number;
  totalCount!: number;
  queriedCount!: number;
  startRecord!: number;
}
