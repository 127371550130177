import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '@store/user/user.slice';
import { LoginForm } from './LoginForm';
import styles from '@pages/authentication/Authentication.module.scss';
import { IdentifierType } from '@store/user/contracts';

interface FormData {
  email: string;
  password: string;
}

export function Success() {
  const navigate = useNavigate();
  const [userLogin] = useLoginMutation();

  const onSubmit = async (data: FormData) => {
    await userLogin({
      password: data.password,
      identifierTypeId: IdentifierType.Email,
      identifierValue: data.email,
    });
    navigate('/');
  };

  return (
    <div className={`form-data mt-5 mb-5 ${styles.formAuth}`}>
      <div className="fw-bold fs-2 color-black mb-3">Пароль изменен</div>
      <div style={{ marginBottom: '32px' }}>
        <p>Войдите в аккаунт с новым паролем</p>
      </div>
      <div className="display-flex flex-direction-column">
        <LoginForm
          value={{
            email: '',
            password: '',
          }}
          submit={onSubmit}
        />
      </div>
    </div>
  );
}
