import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper, Radio, RadioGroup } from '@mui/material';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { useAppSelector } from '@hooks/index';
import { selectDictionary } from '@store/dictionary/dictionary.slice';
import { DictionaryName } from '@store/dictionary/contracts';
import { SpecialistRequestBody } from '@store/user/contracts';
import styles from './jobCharacteristicsForm.module.scss';

type JobCharacteristicsValues = {
  location?: string;
  experienceFrom?: string;
  experienceTo?: string;
  hourPriceFrom?: string;
  hourPriceTo?: string;
  statusId?: string[];
  stack?: string[];
  roleId?: string;
};

type JobCharacteristicsFormProps = {
  value: SpecialistRequestBody;
  submit: (
    value: SpecialistRequestBody,
    form: FormikBag<JobCharacteristicsFormProps, SpecialistRequestBody>,
  ) => void;
};

const JobCharacteristics: React.FC = () => {
  const form = useFormikContext<JobCharacteristicsValues>();
  const { setFieldValue } = useFormikContext();
  const directions = useAppSelector(selectDictionary(DictionaryName.DIRECTION));

  return (
    <Paper className={styles.container} variant="outlined">
      <Form onSubmit={form.handleSubmit}>
        <div>
          <h6 className="fw-500 fs-8">Стоимость часа работы</h6>
          <RadioGroup>
            <FormControlLabel
              value="Бесплатно"
              control={<Radio className={styles.radio} />}
              label="Бесплатно"
              onChange={() => {
                setFieldValue('hourPriceFrom', undefined);
                setFieldValue('hourPriceTo', '1000');
              }}
              checked={form.values.hourPriceTo === '1000'}
            />
            <FormControlLabel
              value="1000"
              control={<Radio className={styles.radio} />}
              label="до 1000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '1000');
                setFieldValue('hourPriceTo', '2000');
              }}
              checked={form.values.hourPriceFrom === '1000' && form.values.hourPriceTo === '2000'}
            />
            <FormControlLabel
              value="3000"
              control={<Radio className={styles.radio} />}
              label="от 1000 до 2000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '2000');
                setFieldValue('hourPriceTo', '3000');
              }}
              checked={form.values.hourPriceFrom === '2000' && form.values.hourPriceTo === '3000'}
            />
            <FormControlLabel
              value="1000"
              control={<Radio className={styles.radio} />}
              label="от 3000"
              onChange={() => {
                setFieldValue('hourPriceFrom', '3000');
                setFieldValue('hourPriceTo', undefined);
              }}
              checked={form.values.hourPriceFrom === '3000'}
            />
          </RadioGroup>
        </div>
        <div className={styles.form__direction}>
          <h6 className="fw-500 fs-8">Направление</h6>
          <FormGroup>
            <FormControlLabel
              name="directionIds"
              control={<Checkbox className={styles.checkbox} />}
              label="Аналитика"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Аналитик')?.id}
            />
            <FormControlLabel
              name="directionIds"
              control={<Checkbox className={styles.checkbox} />}
              label="Архитектура"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Архитектор')?.id}
            />
            <FormControlLabel
              name="directionIds"
              control={<Checkbox className={styles.checkbox} />}
              label="Дизайн"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Дизайнер')?.id}
            />
            <FormControlLabel
              name="directionIds"
              control={<Checkbox className={styles.checkbox} />}
              label="Разработка"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Разработчик')?.id}
            />
            <FormControlLabel
              name="directionIds"
              control={<Checkbox className={styles.checkbox} />}
              label="Тестирование"
              onChange={form.handleChange}
              value={directions.find((direction) => direction.name === 'Тестировщик')?.id}
            />
          </FormGroup>
        </div>
        <div className={styles.form__stack}>
          <h6 className="fw-500 fs-8">Стек технологий</h6>
          <FormGroup>
            <FormControlLabel
              name="stack"
              control={<Checkbox className={styles.checkbox} />}
              label="PHP"
              onChange={form.handleChange}
              value={'PHP'}
            />
            <FormControlLabel
              name="stack"
              control={<Checkbox className={styles.checkbox} />}
              label="JS"
              onChange={form.handleChange}
              value={'JS'}
            />
            <FormControlLabel
              name="stack"
              control={<Checkbox className={styles.checkbox} />}
              label="Angular"
              onChange={form.handleChange}
              value={'Angular'}
            />
            <FormControlLabel
              name="stack"
              control={<Checkbox className={styles.checkbox} />}
              label="Python"
              onChange={form.handleChange}
              value={'Python'}
            />
          </FormGroup>
        </div>
      </Form>
    </Paper>
  );
};

export const JobCharacteristicsForm = withFormik<
  JobCharacteristicsFormProps,
  SpecialistRequestBody
>({
  displayName: 'JobCharacteristicsForm',
  mapPropsToValues: (props) => {
    return {
      location: props.value.location,
      experienceFrom: props.value.experienceFrom,
      experienceTo: props.value.experienceTo,
      hourPriceFrom: props.value.hourPriceFrom,
      hourPriceTo: props.value.hourPriceTo,
      statusId: props.value.statusId,
      stack: props.value.stack,
      roleId: props.value.roleId,
    };
  },
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(JobCharacteristics);
