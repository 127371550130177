import styles from './MainFooter.module.scss';
import { ReactComponent as LogoFooter } from '@assets/img/footer/LogoFooter.svg';
import { styled, Typography as MTypography } from '@mui/material';
import { Link as MLink } from 'react-router-dom';
import mail from '@assets/img/footer/mail.svg';
import telegram from '@assets/img/footer/telegram.svg';

const Typography = styled(MTypography)`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 13px;
`;

const Link = styled(MLink)`
  color: #fff;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    color: #199b3e;
  }
`;

export function MainFooter() {
  return (
    <div className={styles.mainFooter}>
      <div className={styles.container}>
        <div className={styles.footerBlock}>
          <div className={styles.commonBlock}>
            <div className={styles.linkBlock}>
              <div className={styles.gap}>
                <Typography>Egile</Typography>
                <Link to="/about">О платформе</Link>
                <Link to="/support">Помощь</Link>
                <Link to="/news">Новости</Link>
                <Link to="/rates">Тарифы</Link>
                <Link to="/legalDocuments">Юридические документы</Link>
                <Link to="/yandexPay">Партнерство с Яндексом</Link>
              </div>
              <div className={styles.gap}>
                <Typography>Информация</Typography>
                <Link to="/specialist">Исполнителям</Link>
                <Link to="/customer">Заказчикам</Link>
                <Link to="/university">Учебным заведениям</Link>
                <Link to="/startup">Стартапам</Link>
              </div>
              <div className={styles.gap}>
                <Typography>Контакты</Typography>
                <div className={styles.gap15}>
                  <a className="text-white" href="mailto:mail@egile.ru">
                    <img src={mail} alt="mail" className="me-1" />
                    mail@egile.ru
                  </a>
                  <a
                    className="text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://t.me/egiletech">
                    <img src={telegram} alt="mail" className="me-1" />
                    Egile
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.logoBlock}>
            <LogoFooter className={styles.logoFooter} />
            <Typography sx={{ color: '#959799' }}>© 2020-2024 Egile</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
