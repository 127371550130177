import styles from './LegalDocumentList.module.scss';
import { CircularProgress } from '@mui/material';
import { useGetLegalDocumentsQuery } from '@store/legalDocument/legalDocument';
import { Link } from 'react-router-dom';
import { formatDate } from '@components/ui/formatDate';

export const LegalDocumentList = () => {
  const { data: documents, isLoading, isError } = useGetLegalDocumentsQuery({});

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.grid}>
          <h2>Юридические документы</h2>
          {isLoading && (
            <div className="pt-5 w-100 text-center">
              <CircularProgress />
            </div>
          )}
          {isError && (
            <div className="pt-5 w-100 text-center">
              <h2>Ошибка загрузки списка документов</h2>
            </div>
          )}
          {documents?.length === 0 && (
            <div className="pt-5 w-100">
              <h2>Список документов пуст</h2>
            </div>
          )}
          {documents &&
            documents.length > 0 &&
            documents.map((document) => (
              <div className="d-flex flex-column gap-1 mb-4" key={document.name}>
                <Link
                  to={'/legalDocuments/' + document?.symbol_code + '/'}
                  className="color-green_hover color-green fs-6 mb-0 fw-500"
                  rel="noopener noreferrer">
                  {document?.name}
                </Link>
                {document?.date && (
                  <span>Действует с {formatDate(document?.date, 'DD.MM.YYYY')}</span>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
