import { StatusTypesList } from './StatusTypesList/StatusTypesList';
import { TasksPie } from './TasksPie/TasksPie';
import { CommonAnalyticsBlockProps } from '../CommonAnalyticsBlock';
import styles from './PieBlock.module.scss';
import { useProjectCommonAnalytics } from '@hooks/useProjectCommonAnalytics';

export const PieBlock = ({ commonAnalytics }: CommonAnalyticsBlockProps) => {
  const { localisedStatuses, tasksCount } = useProjectCommonAnalytics(commonAnalytics);

  return (
    <div className={styles.pie__block}>
      <h2 className={styles.pie__block__title}>Количество задач</h2>
      <div className={styles.pie__block__content}>
        <StatusTypesList statuses={localisedStatuses} />
        <TasksPie statuses={localisedStatuses} tasksCount={tasksCount} />
      </div>
    </div>
  );
};
