import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import React from 'react';
import styles from './EditProject.module.scss';
import { CircularProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDetailProjectQuery, useUpdateProjectMutation } from '@store/project/project.slice';
import { CreateProjectForm, CreateProjectFormProps } from '../CreateProject/CreateProjectForm';

export const EditProject: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [updateProjectMutation, { isLoading }] = useUpdateProjectMutation();
  const { data: project, isLoading: projectLoading } = useDetailProjectQuery(projectId || '');

  const submitEditProject: CreateProjectFormProps['submit'] = async (form, editProjectForm) => {
    try {
      editProjectForm.setSubmitting(true);
      let deadline: string;
      if (dayjs(form.deadLine, 'YYYY-MM-DDTHH:mm').isValid()) {
        deadline = dayjs(form.deadLine, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm');
      }
      await updateProjectMutation({
        id: projectId!,
        projectTypeId: form.projectTypeId!,
        deadLine: deadline!,
        description: form.description!,
        budget: form.budget!,
        attachments: form?.attachments?.map((at) => at.id),
        stack: form.stack!,
        requirement: form.requirement!,
        task: form.task!,
        name: form.name!,
        visibilityStatusId: form.visibilityStatusId!,
        currencyId: form.currencyId!,
      });
      navigate(`/projects/${projectId}/details`);
    } catch (e: any) {
    } finally {
      editProjectForm.setSubmitting(false);
    }
  };

  return (
    <div className="col container-content">
      <div className={styles.wrapper}>
        <div className={styles.nav__back}>
          <NavigationBack />
        </div>
        <Paper className={styles.container} elevation={0} variant="outlined">
          <div className={styles.title}>
            <h2>Редактирование проекта</h2>
          </div>
          {isLoading || projectLoading ? (
            <CircularProgress />
          ) : (
            <CreateProjectForm
              value={{
                projectTypeId: project?.projectTypeId!,
                deadLine: project?.deadLine!,
                description: project?.description!,
                budget: project?.budget!,
                attachments: project?.attachments || [],
                stack: project?.stack!,
                requirement: project?.requirement!,
                task: project?.task!,
                name: project?.name!,
                currencyId: project?.currencyId!,
                visibilityStatusId: project?.visibilityStatusId || '',
              }}
              submit={submitEditProject}
            />
          )}
        </Paper>
      </div>
    </div>
  );
};
