import React, { useState } from 'react';
import { TasksReleasesChart } from './TasksReleasesChart';
import { StatusList } from './StatusList';
import { Release } from '@store/dashboard/contracts';
import styles from './TasksAnalytics.module.scss';

export const TasksAnalytics: React.FC = () => {
  const [releases] = useState<Release[]>([]);

  return (
    <div className={styles.tasks__block}>
      <div className={styles.tasks__block__title}>
        <h2>Аналитика по задачам</h2>
      </div>
      <div className={styles.gantt__chart}>
        <TasksReleasesChart releases={releases} />
        <StatusList />
      </div>
    </div>
  );
};
