import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import {
  getCloudDeploymentResourceList,
  buildCloudDeploymentResource,
  destroyCloudDeploymentResource,
  startCloudDeploymentResource,
  stopCloudDeploymentResource,
  buildAndStartCloudDeploymentResource,
} from '@store/cloud/cloud.service';
import { CloudDeploymentResource } from '@store/cloud/contracts';
import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import styles from './DeploymentResources.module.scss';

const DeploymentResources = () => {
  const dispatch = useAppDispatch();
  const [DeploymentResources, setDeploymentResources] = useState<CloudDeploymentResource[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const {
    data: fetchedData,
    total,
    loading,
  } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getCloudDeploymentResourceList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setDeploymentResources(fetchedData);
  }, [dispatch, fetchedData]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col">
      <div className={styles.deploymentTable}>
        <AdaptiveTableMUI
          emptyLabel="У вас нет делегатов"
          setRequestBody={(value) => setRequestBody(value)}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          isLoading={loading}
          columns={{
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row: CloudDeploymentResource) => (
                <NavLink to={`/clouds/deployments/${row.id}`}>{value}</NavLink>
              ),
            },
            status: {
              label: 'Состояние',
              filterInput: false,
              children: (_, row: CloudDeploymentResource) => (
                <>{row.status !== undefined && row.status !== null ? row.status.message : ''}</>
              ),
            },
            actions: {
              children: (_, row) => (
                <ActionButtons
                  showBuild={true}
                  showDestroy={true}
                  showBuildAndStart={true}
                  showStart={true}
                  showStop={true}
                  handleBuild={buildCloudDeploymentResource}
                  handleDestroy={destroyCloudDeploymentResource}
                  handleBuildAndStart={buildAndStartCloudDeploymentResource}
                  handleStart={startCloudDeploymentResource}
                  handleStop={stopCloudDeploymentResource}
                  objectId={row.id}
                />
              ),
            },
          }}
          rows={DeploymentResources}>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="правил"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { DeploymentResources };
