import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { useRequestBody } from '@hooks/useRequestBody';
import { Project } from '@store/project/contracts';
import { useGetProjectsQuery } from '@store/project/project.slice';
import { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Projects.module.scss';
import { useAppSelector } from '@hooks/index';
import { selectMyRolesForSelect } from '@store/user/user.slice';
import { Formik } from 'formik';
import { Select, SelectOption } from '@components/MUI/Select';
import { Checkbox, InputAdornment, ListItemText, MenuItem } from '@mui/material';
import { ReactComponent as ClearIcon } from '@assets/img/close-icon.svg';
import { FormikObserver } from '@helpers/FormikObserver';
import { Button } from '@components/MUI/Button';
import { CreateRequestIcon } from '@layouts/requests/Icons/CreateRequestIcon';

export const Projects: React.FC = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const userRoles = useAppSelector(selectMyRolesForSelect);
  const navigate = useNavigate();

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const { data: projects } = useGetProjectsQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...(selectedRoles.length > 0 && { roles: selectedRoles }),
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleChangeSelectedProjects = ({ roles }: { roles: string[] }) => {
    setSelectedRoles(roles);
  };

  const convertValuesToLabels = useCallback((selectedValues: string[]) => {
    return selectedValues.map((value) => {
      return userRoles.find((userRole) => userRole.value === value)?.label;
    });
  }, []);

  const renderOption = useCallback((option: SelectOption, value: string[]) => {
    return (
      <MenuItem value={option.value} key={option.value}>
        <div className={styles.container__header__select_option}>
          <Checkbox
            sx={{
              padding: '0',
            }}
            checked={value.includes(option.value)}
          />
          <ListItemText primary={option.label} />
        </div>
      </MenuItem>
    );
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.container__header}>
        <Formik
          onSubmit={() => {}}
          initialValues={{
            roles: [],
          }}>
          {({ values, setFieldValue }) => (
            <div className={styles.container__header__select}>
              <FormikObserver onChange={handleChangeSelectedProjects} />
              <Select
                values={userRoles}
                name="roles"
                isOutlined
                fullWidth
                multiple
                displayEmpty
                renderValue={(selected) => {
                  if ((Array.isArray(selected) && selected?.length === 0) || !selected) {
                    return (
                      <p className={styles.container__header__select_placeholder}>
                        Выберите роли для отображения данных
                      </p>
                    );
                  } else if (Array.isArray(selected)) {
                    const labels = convertValuesToLabels(selected);
                    return (
                      <p className={styles.container__header__select_selectedProjects}>
                        {labels.join(', ')}
                      </p>
                    );
                  }
                }}
                renderOption={renderOption}
                endAdornment={
                  values.roles.length > 0 ? (
                    <InputAdornment position="end" sx={{ marginRight: '16px' }}>
                      {values.roles.length > 1 && (
                        <span className={styles.container__header__select_selectedCounter}>
                          {values.roles.length}
                        </span>
                      )}
                      <ClearIcon
                        className={styles.container__header__select_clearIcon}
                        onClick={() => setFieldValue('roles', [])}
                      />
                    </InputAdornment>
                  ) : (
                    <></>
                  )
                }
              />
            </div>
          )}
        </Formik>
        <Button
          startIcon={<CreateRequestIcon />}
          variant="contained"
          onClick={() => navigate('/projects/new')}>
          Создать проект
        </Button>
      </div>
      <AdaptiveTableMUI
        setRequestBody={setRequestBody}
        setSortColumn={setSortColumn}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        columns={{
          name: {
            label: 'Проект',
            children: (value, row: Project) => (
              <Link to={`/projects/${row.id}/details`}>{value}</Link>
            ),
            filterInput: true,
            isSortColumn: true,
          },
          customer: {
            label: 'Заказчик',
            filterInput: true,
            isSortColumn: true,
          },
          created: {
            label: 'Дата создания',
            format: (value: Dayjs) => value.format('DD.MM.YYYY'),
            filterInput: true,
            isSortColumn: true,
          },
          deadLine: {
            label: 'Дедлайн',
            format: (value: Dayjs) => value.format('DD.MM.YYYY'),
            filterInput: true,
            isSortColumn: true,
          },
          teamName: {
            label: 'Команда',
            children: (value, row: Project) => <Link to={`/projects/${row.teamId}`}>{value}</Link>,
            filterInput: true,
            isSortColumn: true,
          },
          budget: {
            label: 'Бюджет',
            format: (value: number) => `${value} ₽`,
            filterInput: true,
            isSortColumn: true,
          },
          projectStatusName: {
            label: 'Статус',
            children: (value: string) => (
              <StatusLabel color={statusColors.get(value)} text={value} />
            ),
          },
        }}
        rows={projects?.data || []}
        emptyLabel={'У вас пока нет проектов'}>
        <TablePaginationMUI
          limit={limit}
          count={projects?.totalCount}
          entityLabel="проектов"
          page={page}
          handleChangePage={(_, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </AdaptiveTableMUI>
    </div>
  );
};
