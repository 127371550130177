import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { usePagination } from '@hooks/usePagination';
import { useRequestBody } from '@hooks/useRequestBody';
import {
  getCloudInfrastructureList,
  buildCloudInfrastructure,
  destroyCloudInfrastructure,
} from '@store/cloud/cloud.service';
import { CloudInfrastructure } from '@store/cloud/contracts';
import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { openModal } from '@store/ui/ui.slice';
import styles from '@pages/cloud/infrastructures/InfrastructureForm.module.scss';
import { InfrastructureDetail } from '@pages/cloud/infrastructures/InfrastructureDetail';

const Infrastructures = () => {
  const dispatch = useAppDispatch();
  const [Infrastructures, setInfrastructures] = useState<CloudInfrastructure[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();

  const { data: fetchedData, total } = usePagination({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    accumulate: false,
    action: (query) => getCloudInfrastructureList(query),
    additionalParams: requestBody,
  });

  useEffect(() => {
    setInfrastructures(fetchedData);
  }, [dispatch, fetchedData]);

  const handleEditInfrastructures = useCallback((infrastructureId: string) => {
    dispatch(openModal(<InfrastructureDetail infrastructureId={infrastructureId} />));
  }, []);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="col">
      <div className="mt-4">
        <AdaptiveTableMUI
          emptyLabel="У вас нет инфраструктуры"
          setRequestBody={(value) => setRequestBody(value)}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          columns={{
            name: {
              label: 'Название',
              filterInput: true,
              children: (value, row) => (
                <span
                  className={styles.infrastructureLink}
                  onClick={() => handleEditInfrastructures(row.id)}>
                  {value}
                </span>
              ),
            },
            organizatin: {
              label: 'Организация',
              filterInput: true,
              children: (_, row) => row.organization.resourceName,
            },
            cloud: {
              label: 'Облако',
              filterInput: true,
              children: (_, row) => row.cloud.resourceName,
            },
            folder: {
              label: 'Каталог',
              filterInput: false,
              children: (_, row: CloudInfrastructure) => <>{row.folder?.resourceName}</>,
            },
            network: {
              label: 'Сеть',
              filterInput: false,
              children: (_, row: CloudInfrastructure) => <>{row.network?.resourceName}</>,
            },
            status: {
              label: 'Состояние',
              filterInput: false,
              children: (_, row: CloudInfrastructure) => (
                <>{row.status !== undefined && row.status !== null ? row.status.message : ''}</>
              ),
            },
            actions: {
              children: (_, row: CloudInfrastructure) => (
                <ActionButtons
                  showBuild={true}
                  showDestroy={true}
                  handleBuild={buildCloudInfrastructure}
                  handleDestroy={destroyCloudInfrastructure}
                  objectId={row.id}
                />
              ),
            },
          }}
          rows={Infrastructures}>
          <TablePaginationMUI
            limit={limit}
            count={total}
            entityLabel="инфраструктур"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { Infrastructures };
