import {
  Autocomplete as MAutocomplete,
  FormControl,
  FormHelperText,
  styled,
  TextField,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { AnySchema, reach } from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AutocompleteProps = {
  values: AutocompleteOption[];
  className?: string;
  isOutlined?: boolean;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  freeSolo?: boolean;
  label?: string;
};

const StyledAutoComplete = styled(MAutocomplete)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused': {
    outline: 'solid 1px #148C36',
  },
  '&:hover': { outline: 'solid #21B84B 1px' },
  '& label.Mui-focused': {
    top: '10px',
    outline: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    top: '10px',
  },
  legend: {
    display: 'none',
  },
  minWidth: '214px',
  borderRadius: '4px',
});

const StyledOutlinedAutoComplete = styled(MAutocomplete)({
  '& label.Mui-focused': {
    top: '10px',
    outline: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    top: '10px',
  },
  '& .MuiOutlinedInput-root': {
    paddingTop: '20px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #21B84B',
    boxShadow: '-2px -2px 4px 0 hsla(174, 71%, 88%, 0.3), 2px 2px 4px 0 hsla(174, 71%, 88%, 0.3)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'var(--text-light-green) !important',
    borderWidth: '1px',
  },
  legend: {
    display: 'none',
  },
  borderRadius: '4px',
  PaperProps: {
    elevation: 0,
  },
});

export type AutocompleteOption = { value: any; label: string };

export const Autocomplete: React.FC<AutocompleteProps> = ({
  values,
  className,
  isOutlined,
  name,
  placeholder,
  disabled,
  multiple,
  freeSolo,
  label,
}) => {
  const contextForm = useFormikContext();
  const [field, meta] = useField(name);

  const fieldSchema: AnySchema | null = useMemo(() => {
    return contextForm.validationSchema ? reach(contextForm.validationSchema, name) : null;
  }, [contextForm.validationSchema, name]);

  const isRequiredField = useMemo(
    () => Boolean(fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required')),
    [fieldSchema],
  );

  const hasError = useMemo(() => {
    return meta.touched && meta.error !== undefined;
  }, [meta.touched, meta.error]);

  const textFieldPlaceholder = useMemo(() => {
    return isRequiredField ? `${placeholder}*` : `${placeholder}`;
  }, [isRequiredField, placeholder]);

  const AutoCompleteComponent = isOutlined ? StyledOutlinedAutoComplete : StyledAutoComplete;

  return (
    <FormControl fullWidth className={className}>
      <AutoCompleteComponent
        options={values}
        id={name}
        value={field.value}
        onChange={(sourceEvent, value) => {
          const event = {
            ...sourceEvent,
            target: {
              ...sourceEvent.target,
              value,
              name: name,
            },
          };
          field.onChange(event);
        }}
        onBlur={field.onBlur}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              name={name}
              label={label}
              error={hasError}
              placeholder={textFieldPlaceholder === 'undefined' ? '' : textFieldPlaceholder}
            />
          </>
        )}
        className={className}
        disabled={disabled}
        multiple={multiple}
        freeSolo={freeSolo}
        popupIcon={<ExpandMoreIcon />}
        noOptionsText="Ничего не найдено"
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
