import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Box as MBox, styled } from '@mui/material';
import { PasswordInput } from '@components/MUI/PasswordInput';
import { Button } from '@components/MUI/Button';

export type PasswordFormProps = {
  value: PasswordFormValue;
  submit: (value: PasswordFormValue, form: FormikBag<PasswordFormProps, PasswordFormValue>) => void;
};

type PasswordFormValue = {
  password: string;
  passwordRepeat: string;
};

const PasswordFormSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Придумайте пароль, в котором содержится от 8 символов')
    .required('Пароль обязателен для заполнения')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$#@*&()!%?]).*$/,
      'Пароль должен содержать минимум одну заглавную букву, одну строчную букву, одну цифру и один из символов $, #, @, *, &, !, (, ), %, ?',
    ),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
    .min(8, 'Придумайте пароль, в котором содержится от 8 символов')
    .required('Пароль обязателен для заполнения'),
});

const Box = styled(MBox)`
  margin-top: 56px;
`;

const FormComponent: React.FC = () => {
  const PasswordForm = useFormikContext<PasswordFormValue>();
  PasswordForm.validationSchema = PasswordFormSchema;

  return (
    <Form onSubmit={PasswordForm.handleSubmit}>
      <div className="d-flex flex-column gap-4">
        <PasswordInput name="password" type="password" placeholder="Пароль" />
        <PasswordInput name="passwordRepeat" type="password" placeholder="Повторите пароль" />
      </div>
      <Box>
        <Button
          variant="contained"
          className="pt-3 pb-3 w-100 fs-7 fw-600"
          type="submit"
          disabled={
            !PasswordForm.isValid ||
            !PasswordForm.values.password ||
            !PasswordForm.values.passwordRepeat
          }>
          Завершить регистрацию
        </Button>
      </Box>
    </Form>
  );
};

export const PasswordForm = withFormik<PasswordFormProps, PasswordFormValue>({
  displayName: 'PasswordForm',
  mapPropsToValues: (props) => {
    return {
      password: props.value.password,
      passwordRepeat: props.value.passwordRepeat,
    };
  },
  enableReinitialize: true,
  validationSchema: PasswordFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
