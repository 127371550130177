import styles from './Project.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { useCallback, useEffect } from 'react';
import { openModal } from '@store/ui/ui.slice';
import { ConnectTrackerModal } from '@pages/projects/ConnectTrackerModal/ConnectTrackerModal';
import { Button } from '@components/MUI/Button';
import { ReactComponent as EditIconGreen } from '@assets/img/editIconGreen.svg';
import { ReactComponent as SettingsIcon } from '@assets/img/settings.svg';
import { selectCurrentUserId } from '@store/user/user.slice';
import { StatusLabel } from '@components/ui/StatusLabel/StatusLabel';
import { projectStatusColors } from '@pages/dashboard/Projects/AmountOfProjects/AmountOfProjects';
import { Star } from '@components/Star/Star';
import upload from '@assets/img/upload.svg';
import { downloadFile } from '@store/docs/utils';
import { useDetailProjectQuery } from '@store/project/project.slice';
import { useSetDocsFavoriteMutation } from '@store/docs/docs.slice';
import { ProjectTransitions } from '../ProjectTransitions/ProjectTransitions';

export const Project = () => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const myId = useAppSelector(selectCurrentUserId);
  const [setDocsFavorite] = useSetDocsFavoriteMutation();

  const { data: project } = useDetailProjectQuery(projectId || '');

  const openConnectTrackerModal = useCallback(() => {
    if (!projectId) return;

    dispatch(openModal(<ConnectTrackerModal projectId={projectId} />));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!window.location.search.includes('connected=true')) return;
    openConnectTrackerModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConnectTrackerModal, window.location.search]);

  if (!projectId) {
    navigate('/projects');
    return null;
  }

  if (project)
    return (
      <>
        <div className={styles.header}>
          <div className={styles.title_container}>
            <div className={styles.title}>
              <h1>{project?.name}</h1>
              <StatusLabel
                color={projectStatusColors.get(project?.projectStatusName)}
                text={project?.projectStatusName}
              />
            </div>
            <ProjectTransitions projectPrefix={project?.prefix} />
          </div>
          {myId === project.teamLeadId ? (
            <div>
              <Button
                variant="text"
                className={styles.edit__button}
                startIcon={<EditIconGreen />}
                onClick={() => navigate(`/projects/${projectId}/edit`)}
                size="medium">
                Редактирование
              </Button>
              <Button
                variant="text"
                className={styles.settings__button}
                startIcon={<SettingsIcon />}
                onClick={() => navigate(`/projects/${projectId}/settings`)}
                size="medium">
                Настройки
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <b>Тип проекта</b>
            <p className={styles.bold__item}>{project?.projectTypeName}</p>
          </div>
          <div className={styles.item}>
            <b>Дедлайн проекта</b>
            <p className={styles.bold__item}>{project?.deadLine?.format('DD.MM.YYYY')}</p>
          </div>
        </div>
        <div className={styles.item}>
          <b>Описание проекта</b>
          <p>{project?.description}</p>
        </div>
        {project?.tag ? (
          <div className={styles.item}>
            <b>Тег</b>
            <p>{project?.tag}</p>
          </div>
        ) : null}
        <div className={styles.item}>
          <b>Бюджет проекта</b>
          <p className={styles.bold__item}>{project?.budget} ₽.</p>
        </div>

        <div className={styles.divider} />
        <div className={styles.item}>
          <b>Доступы</b>
          <a href="/">Ссылка</a>
        </div>
        <div className={styles.item}>
          <b>Вложения</b>
          {project?.attachments && project.attachments.length > 0
            ? project?.attachments?.map((attachment) => (
                <div className={styles.row}>
                  <button
                    onClick={() => downloadFile(attachment.id, `.${attachment.extension}`)}
                    className={styles.attachments__button}>
                    <span>{attachment.name}</span>
                    <img src={upload} alt="upload" />
                  </button>
                  {attachment.id ? (
                    <Star
                      checked={attachment.favorite}
                      onChange={(value) =>
                        setDocsFavorite({ requestBody: { status: value }, docId: attachment.id })
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))
            : '-'}
        </div>
        <div className={styles.item}>
          <b>Стек технологий</b>
          <p>{project?.stack}</p>
        </div>
        <div className={styles.item}>
          <b>Требования к разработке</b>
          <p>{project?.requirement}</p>
        </div>
        <div className={styles.item}>
          <b>Что необходимо сделать</b>
          <p>{project?.task}</p>
        </div>
      </>
    );
  return <></>;
};
