import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as VacanciesIcon } from '@assets/img/vacancies.svg';
import { ReactComponent as OfferedVacanciesIcon } from '@assets/img/offeredVacancies.svg';
import { ReactComponent as MyVacanciesIcon } from '@assets/img/myVacancies.svg';
import { ReactComponent as SpecialistsIcon } from '@assets/img/specialists.svg';
import { ReactComponent as VacanciesResponsesIcon } from '@assets/img/vacanciesResponses.svg';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './VacanciesHeader.module.scss';

type VacanciesHeaderProps = {
  rightSideButton?: React.ReactNode;
};

const VacanciesHeader: React.FC<VacanciesHeaderProps> = ({ rightSideButton }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <header className={styles.header}>
      <ul>
        <li>
          <NavLink
            to="/vacancies/responses"
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ textDecoration: 'none' }}>
            <VacanciesResponsesIcon />
            Отклики
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/vacancies/list"
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ textDecoration: 'none' }}>
            <SpecialistsIcon />
            Специалисты
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/vacancies/my"
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ textDecoration: 'none' }}>
            <MyVacanciesIcon />
            Мои вакансии
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/vacancies/offers"
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ textDecoration: 'none' }}>
            <OfferedVacanciesIcon />
            Предложенные вакансии
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/vacancies/catalog"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end>
            <VacanciesIcon className="me-2" />
            Вакансии
          </NavLink>
        </li>
      </ul>
      {!isTablet ? rightSideButton : <></>}
    </header>
  );
};

export { VacanciesHeader };
