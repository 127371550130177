import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { useCallback, useState } from 'react';
import { JsonFormsCore } from '@jsonforms/core';
import { ErrorObject } from 'ajv';
import { Modal, ModalFooter } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import styles from './Transition.module.scss';
import { useAppDispatch } from '@hooks/index';
import Api from '@api-schema';
import { closeModal } from '@store/ui/ui.slice';
import { JsonForm, JsonFormProps } from '@components/JsonForm';
import { generateVerticalUiSchema } from '@components/JsonForm/helpers';

type FormOnChangeProps = Pick<JsonFormsCore, 'data' | 'errors'>;

export type TransitionField = Api.components['schemas']['ProjectFieldDTO'];

type TransitionFormData = {
  [name: string]: string;
};

export type TransitionValue = {
  transitionId: string;
  [name: string]: string;
};

type TransitionProps = {
  fields: TransitionField[];
  transitionId: string;
  onSubmit: (data: TransitionValue) => void;
} & Pick<JsonFormProps, 'schema'>;

export const Transition = ({ fields, transitionId, onSubmit, schema }: TransitionProps) => {
  const [formData, setFormData] = useState<TransitionFormData>({});
  const [errors, setErrors] = useState<ErrorObject[]>([]);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const uischema = generateVerticalUiSchema(fields);

  const handleFormChange = useCallback((state: FormOnChangeProps) => {
    setFormData(state.data);
    setErrors(state.errors || []);
  }, []);

  const handleFormSubmit = () => {
    const data: TransitionValue = {
      transitionId,
      ...formData,
    };
    onSubmit(data);
    handleCloseModal();
  };

  return (
    <Modal>
      <FormWrapper label="Заполните поля">
        <div className={styles.form}>
          <JsonForm
            formData={formData}
            renderers={materialRenderers}
            cells={materialCells}
            schema={schema}
            uischema={uischema}
            onChange={handleFormChange}
          />
        </div>
        <ModalFooter>
          <Button className="btn btn-sm text-secondary me-3" onClick={handleCloseModal}>
            Отменить
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={errors.length > 0}
            onClick={handleFormSubmit}>
            Отправить
          </Button>
        </ModalFooter>
      </FormWrapper>
    </Modal>
  );
};
