import { JsonSchema7 } from './../../../node_modules/@jsonforms/core/lib/models/jsonSchema7.d';
import Api from '@api-schema';

export const mapFieldsToJsonSchema = (
  fields: Api.components['schemas']['ProjectFieldDTO'][],
): JsonSchema7 => {
  const properties: Record<string, any> = {};
  const requiredFields: string[] = [];
  const errorMessages: Record<string, any> = { required: {} };

  fields.forEach((field) => {
    const { key, display, required, type, options } = field;

    if (required && key) {
      requiredFields.push(key!);
      errorMessages.required[key!] = `Обязательно для заполнения`;
    }

    const fieldSchema: JsonSchema7 = {
      title: display,
      type: type === 'options' ? 'string' : type,
    };

    if (type === 'options' && options) {
      fieldSchema.enum = options.map((option) => option?.key);
    }
    if (key) {
      properties[key] = fieldSchema;
    }
  });

  return {
    type: 'object',
    properties,
    required: requiredFields,
    errorMessage: errorMessages,
  };
};

export const generateVerticalUiSchema = (
  fields: Api.components['schemas']['ProjectFieldDTO'][],
) => ({
  type: 'VerticalLayout',
  elements: fields.map((field) => ({
    type: 'Control',
    scope: `#/properties/${field.key}`,
  })),
});
