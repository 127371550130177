import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import {
  connectToIntegrationProvider,
  disconnectIntegrationProvider,
  isEnabledIntegration,
} from '@store/user/user.slice';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';

type IntegrationToggleProps = {
  type: INTEGRATION_PROVIDER;
};

export function IntegrationProviderToggle({ type }: IntegrationToggleProps) {
  const dispatch = useAppDispatch();
  const integrationStatus = useAppSelector(isEnabledIntegration(type));

  const toggleIntegration = useCallback(async () => {
    if (!integrationStatus) {
      await dispatch(connectToIntegrationProvider(type)).unwrap();
    } else {
      confirmTurnOfIntegration();
    }
  }, [integrationStatus, type]);

  const disableIntegration = useCallback(async () => {
    await dispatch(disconnectIntegrationProvider(type)).unwrap();
    dispatch(closeModal());
  }, [type]);

  const confirmTurnOfIntegration = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить интеграцию?"
          btnProp="Отключить"
          onSubmit={disableIntegration}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  return (
    <div className="d-flex gap-4 align-items-center">
      Интеграция с {type}
      <FormControlLabel
        control={
          <MuiSwitch checked={integrationStatus} onChange={toggleIntegration} name="integration" />
        }
        label=""
      />
    </div>
  );
}
