import React from 'react';
import { Formik } from 'formik';
import { InputField } from '@components/MUI/InputField/InputField';
import { FormikHelpers } from 'formik/dist/types';
import { Button } from '@mui/material';
import styles from '@pages/main/JobsWithoutRegistration.module.scss';
import { styled } from '@mui/system';

interface SearchProps {
  placeholder: string;
  handleSearch: (
    { search }: { search: string },
    formikHelpers: FormikHelpers<{ search: string }>,
  ) => void;
}

const StyledInput = styled(InputField)({
  maxWidth: '37.25rem',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  width: '70vw',
});

export const Search: React.FC<SearchProps> = ({ placeholder, handleSearch }) => {
  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={handleSearch}>
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <StyledInput
              type="search"
              placeholder={placeholder}
              name="search"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.search}
            />
            <Button
              className={styles.hideFindBtn}
              sx={{ padding: '0 30px' }}
              variant="contained"
              type="submit">
              Найти
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
