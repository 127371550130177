import React, { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import styles from './InstanceResourceForm.module.scss';
import { Select } from '@components/MUI/Select';
import { ConfirmDeleteModal } from '@components/MUI/ConfirmDeleteModal/ConfirmDeleteModal';
import { FormControlLabel } from '@mui/material';
import { MuiSwitch } from '@components/ui/Switch/MuiSwith';
import { closeModal, openModal } from '@store/ui/ui.slice';

import {
  loadInfrastructures,
  selectInfrastructureOptions,
  selectInfrastructures,
  loadTemplates,
  selectTemplateOptions,
  loadDeployments,
  selectDeploymentOptions,
  loadInstances,
  selectInstanceOptions,
  loadZones,
  selectZoneOptions,
  loadPlatforms,
  selectPlatformOptions,
  loadImages,
  selectImageOptions,
  selectTemplates,
} from '@store/cloud/cloud.slice';

export type InstanceResourceFormProps = {
  value: InstanceResourceFormValue;
  submit: (
    value: InstanceResourceFormValue,
    form: FormikBag<InstanceResourceFormProps, InstanceResourceFormValue>,
  ) => void;
};

type InstanceResourceFormValue = {
  name: string; // Наименование
  instanceId: string; // Ресурс инстанса
  instanceName: string; // Ресурс инстанса
  instanceParentId: string; // Ресурс инстанса
  infrastructureId: string; // Инфраструктура
  deploymentId: string; // Правила развертывания
  cores: number; // Число ядер процессора
  memory: number; // Память в байтах
  owners: string[]; // Пользователи
  statusId: string; // Состояние виртуальной машины
  imageId: string; // Образ виртуальной машины
  diskSize: number; // Размер жесткого диска в байтах
  zoneId: string; // Ресурс зоны
  zoneName: string; // Ресурс зоны
  platformId: string; // Ресурс платформы
  platformName: string; // Ресурс платформы
  login: string; // Логин
  password: string; // Пароль
  ipAddress: string; // ip адрес
  instanceParamsId: string; // Шаблон виртуальной машины
  remoteName: string; // Удаленный адрес машины
  isPreemptible: boolean;
};

const InstanceResourceFormSchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  instanceName: Yup.string().matches(
    /^[a-z][-a-z0-9]{1,61}[a-z0-9]$/,
    'Введите корректное название виртуальной машины',
  ),
  cores: Yup.number(),
  memory: Yup.number(),
  diskSize: Yup.number(),
  login: Yup.string(),
  password: Yup.string(),
  ipAddress: Yup.string(),
  remoteName: Yup.string(),
  instanceId: Yup.string(),
  instanceParentId: Yup.string(),
  infrastructureId: Yup.string(),
  deploymentId: Yup.string(),
  imageId: Yup.string(),
  zoneId: Yup.string(),
  platformId: Yup.string(),
  instanceParamsId: Yup.string(),
  isPreemptible: Yup.boolean(),
});

const FormComponent = () => {
  const form = useFormikContext<InstanceResourceFormValue>();

  const InstanceResourceForm = useFormikContext();
  const dispatch = useAppDispatch();

  InstanceResourceForm.validationSchema = InstanceResourceFormSchema;
  const infrastructureOptions = useAppSelector(selectInfrastructureOptions);
  const infrastructures = useAppSelector(selectInfrastructures);
  const templateOptions = useAppSelector(selectTemplateOptions);
  const deploymentOptions = useAppSelector(selectDeploymentOptions);
  const instanceOptions = useAppSelector(selectInstanceOptions);
  const zoneOptions = useAppSelector(selectZoneOptions);
  const platformOptions = useAppSelector(selectPlatformOptions);
  const imageOptions = useAppSelector(selectImageOptions);
  const templates = useAppSelector(selectTemplates);

  const [newInstance, setNewInstance] = useState<boolean>(false);

  useEffect(() => {
    dispatch(loadInfrastructures({}));
    dispatch(loadTemplates({}));
    dispatch(loadDeployments({}));
    dispatch(
      loadZones({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );
    dispatch(
      loadPlatforms({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );
    dispatch(loadImages({}));
  }, [dispatch]);

  useEffect(() => {
    form.setFieldValue('zoneName', form.values.zoneId);
  }, [form.values.zoneId]);

  useEffect(() => {
    form.setFieldValue('platformName', form.values.platformId);
  }, [form.values.platformId]);

  useEffect(() => {
    if (form.values.instanceId === '') setNewInstance(true);

    let instanceName = '';
    if (form.values.instanceId && form.values.instanceId !== '') {
      setNewInstance(false);
      let selected = instanceOptions.find((item) => item.value === form.values.instanceId);
      if (selected !== undefined) instanceName = selected.label;
    }
    form.setFieldValue('instanceName', instanceName);
  }, [form.values.instanceId]);

  useEffect(() => {
    form.setFieldValue('zoneId', null);
    form.setFieldValue('platformId', null);
    dispatch(
      loadZones({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );
    dispatch(
      loadPlatforms({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
      }),
    );

    let folderResourceId = '';
    if (form.values.infrastructureId) {
      const infrastructure = infrastructures.find(
        (item) => item.id === form.values.infrastructureId,
      );

      if (
        infrastructure !== undefined &&
        infrastructure !== null &&
        infrastructure.folder !== undefined
      )
        folderResourceId = infrastructure.folder.resourceId;
      form.setFieldValue('instanceParentId', folderResourceId);
    }
    dispatch(
      loadInstances({
        infrastructureId: form.values.infrastructureId == null ? '' : form.values.infrastructureId,
        parentId: folderResourceId,
      }),
    );
  }, [form.values.infrastructureId]);

  useEffect(() => {
    if (form.values.instanceParamsId) {
      const template = templates?.find((template) => template.id === form.values.instanceParamsId);
      form.setFieldValue('cores', template?.cores);
      form.setFieldValue('memory', template?.memory);
      form.setFieldValue('diskSize', template?.diskSize);
    }
  }, [form.values.instanceParamsId]);

  const disableIsPreemptible = useCallback(async () => {
    form.setFieldValue('isPreemptible', false);
    dispatch(closeModal());
  }, []);

  const confirmTurnOffIsPreemptible = useCallback(() => {
    dispatch(
      openModal(
        <ConfirmDeleteModal
          title="Вы действительно хотите отключить прерываемость виртуальной машины?"
          btnProp="Отключить"
          onSubmit={disableIsPreemptible}>
          <div></div>
        </ConfirmDeleteModal>,
      ),
    );
  }, [dispatch]);

  const toggleIsPreemptible = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      confirmTurnOffIsPreemptible();
    } else form.setFieldValue('isPreemptible', event.target.checked);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <div className={styles.form__wrapper}>
        <InputField
          name="name"
          placeholder="Название правила"
          type="text"
          className={styles.form__field}
        />
        <Select
          name="platformId"
          values={platformOptions}
          label="Платформа"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="infrastructureId"
          values={infrastructureOptions}
          label="Инфраструктура"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="zoneId"
          values={zoneOptions}
          label="Зона"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="instanceId"
          values={instanceOptions}
          label="Виртуальная машина"
          isOutlined
          className={styles.form__field}
        />
        {newInstance && (
          <InputField
            name="instanceName"
            placeholder="Название виртуальной машины"
            type="text"
            className={styles.form__field}
          />
        )}

        <Select
          name="instanceParamsId"
          values={templateOptions}
          label="Шаблон"
          isOutlined
          className={styles.form__field}
        />
        <Select
          name="deploymentId"
          values={deploymentOptions}
          label="Правила развертывания"
          isOutlined
          className={styles.form__field}
        />
        <InputField
          name="cores"
          placeholder="Число ядер процессора"
          type="number"
          className={styles.form__field}
          disabled={form.values.instanceParamsId ? true : false}
        />
        <InputField
          name="memory"
          placeholder="Оперативная память, Гб"
          type="number"
          className={styles.form__field}
          disabled={form.values.instanceParamsId ? true : false}
        />
        <InputField
          name="diskSize"
          placeholder="Жесткий диск, Гб"
          type="number"
          className={styles.form__field}
          disabled={form.values.instanceParamsId ? true : false}
        />
        <Select
          name="imageId"
          values={imageOptions}
          label="Образ машины"
          isOutlined
          className={styles.form__field}
        />
        <div className="d-flex gap-4 align-items-center">
          Прерываемая машина
          <FormControlLabel
            control={
              <MuiSwitch
                checked={form.values.isPreemptible}
                onChange={toggleIsPreemptible}
                name="isPreemptible"
              />
            }
            label=""
          />
        </div>
        <InputField
          name="ipAddress"
          placeholder="IP адрес"
          type="string"
          className={styles.form__field}
        />
        <InputField
          name="remoteName"
          placeholder="Удаленный адрес"
          type="string"
          className={styles.form__field}
        />
        <InputField name="login" placeholder="Логин" type="string" className={styles.form__field} />
        <InputField
          name="password"
          placeholder="Пароль"
          type="string"
          className={styles.form__field}
        />
      </div>
      <Button
        type="submit"
        disabled={!form.isValid}
        color="success"
        variant="contained"
        size="large"
        className={styles.submit__button}>
        Сохранить
      </Button>
      <RequiredFieldsIndicator />
    </Form>
  );
};

export const InstanceResourceForm = withFormik<
  InstanceResourceFormProps,
  InstanceResourceFormValue
>({
  displayName: 'InstanceResourceForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      instanceId: props.value.instanceId,
      instanceName: props.value.instanceName,
      instanceParentId: props.value.instanceParentId,
      infrastructureId: props.value.infrastructureId,
      deploymentId: props.value.deploymentId,
      cores: props.value.cores,
      memory: props.value.memory,
      owners: props.value.owners,
      statusId: props.value.statusId,
      imageId: props.value.imageId,
      diskSize: props.value.diskSize,
      zoneId: props.value.zoneId,
      zoneName: props.value.zoneName,
      platformId: props.value.platformId,
      platformName: props.value.platformName,
      login: props.value.login,
      password: props.value.password,
      ipAddress: props.value.ipAddress,
      instanceParamsId: props.value.instanceParamsId,
      remoteName: props.value.remoteName,
      isPreemptible: props.value.isPreemptible,
    };
  },
  enableReinitialize: true,
  validationSchema: InstanceResourceFormSchema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
