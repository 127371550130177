import { Paper, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { InputField } from '@components/MUI/InputField/InputField';
import { Select } from '@components/MUI/Select';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { DragAndDrop } from '@components/ui/DranAndDrop/DragAndDrop';
import { Button } from '@components/MUI/Button';
import * as Yup from 'yup';
import { RequiredFieldsIndicator } from '@components/RequiredFieldsIndicator';
import styles from './ProfileSpecialityForm.module.scss';
import { FileItem } from '@store/user/contracts';

type ProfileSpecialityValue = {
  directionId?: string;
  stack?: string;
  experience?: number;
  experienceDescription?: string;
  resumeUrl?: string;
  resume?: FileItem[];
  certificates?: FileItem[];
  roles: string[];
};

export type ProfileFormSpecialityProps = {
  value: ProfileSpecialityValue;
  submit: (
    value: ProfileSpecialityValue,
    form: FormikBag<ProfileFormSpecialityProps, ProfileSpecialityValue>,
  ) => void;
};

const ProfileFormSpecialitySchema = Yup.object({
  experience: Yup.number()
    .min(0, 'Введите число больше или равное 0')
    .max(50, 'Введите число меньше или равное 50'),
  directionId: Yup.string(),
  stack: Yup.string(),
  experienceDescription: Yup.string(),
  resumeUrl: Yup.string(),
  resume: Yup.array(),
  certificates: Yup.array(),
});

const ProfileSpecialityComponent: React.FC = () => {
  const profileSpecialityForm = useFormikContext<ProfileSpecialityValue>();
  const directions = useDictionary(DictionaryName.DIRECTION, fromDictionaryToSelectOption);

  profileSpecialityForm.validationSchema = ProfileFormSpecialitySchema;

  return (
    <Paper className={styles.profile__speciality__wrap} variant="outlined">
      <div className={styles.profile__speciality__form}>
        <h3>Специальность</h3>
      </div>

      <Form style={{ marginTop: '2.125rem' }}>
        <div className="d-flex flex-column">
          <Select
            name="directionId"
            values={directions}
            isOutlined={true}
            onChange={(event: SelectChangeEvent) => {
              profileSpecialityForm.setFieldValue('directionId', event.target.value);
            }}
            label={'Направление'}
            className={styles.form_field}
            value={profileSpecialityForm?.values?.directionId}
          />
          <InputField name="stack" type="text" placeholder="Стэк" className={styles.form_field} />
          <InputField
            name="experience"
            type="number"
            placeholder="Опыт работы (лет)"
            className={styles.form_field}
          />
          <InputField
            name="experienceDescription"
            type="textarea"
            placeholder="Описание опыта"
            className={styles.form_field}
            multiline
            rows={4}
          />
          <InputField
            name="resumeUrl"
            type="text"
            placeholder="Ссылка на резюме"
            className={styles.form_field}
          />
          <div className="mt-4">
            <h4 className="font-weight-bold mb-3">Резюме</h4>
            <DragAndDrop
              name="resume"
              tag="PROFILE"
              accept={{ 'application/*': ['.doc', '.docx', '.pdf'] }}
            />
          </div>
          <div className="mt-4">
            <h4 className="font-weight-bold mb-3">Сертификаты/дипломы</h4>
            <DragAndDrop
              name="certificates"
              tag="PROFILE"
              accept={{ 'application/*': ['.doc', '.docx', '.pdf'] }}
            />
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          disabled={!profileSpecialityForm.isValid}
          size="large"
          className={styles.submit__button}>
          Сохранить
        </Button>
        <RequiredFieldsIndicator className={styles.footer} />
      </Form>
      <div></div>
    </Paper>
  );
};

export const ProfileSpecialityForm = withFormik<ProfileFormSpecialityProps, ProfileSpecialityValue>(
  {
    displayName: 'ProfileAboutMeForm',
    mapPropsToValues: (props) => {
      return {
        directionId: props.value.directionId,
        stack: props.value.stack,
        experience: props.value.experience,
        experienceDescription: props.value.experienceDescription,
        resumeUrl: props.value.resumeUrl,
        resume: props.value.resume,
        certificates: props.value.certificates,
        roles: props.value.roles,
      };
    },
    enableReinitialize: true,
    validationSchema: ProfileFormSpecialitySchema,
    validateOnMount: false,
    validateOnChange: true,
    handleSubmit: (values, formikBag) => {
      formikBag.props.submit(values, formikBag);
    },
  },
)(ProfileSpecialityComponent);
