abstract class ApplicationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;

    Error.call(this);
    Error.captureStackTrace(this);
    Object.setPrototypeOf(this, new.target.prototype);
  }
  abstract getMessage(): string;
}

export class ApiError extends ApplicationError {
  getMessage(): string {
    return this.message;
  }
}
