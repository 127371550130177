import { styled, Typography as MTypography } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import styles from './YandexCard.module.scss';

export const TypographyService = styled(MTypography)`
  color: var(--count-light);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background: var(--text-default-color);
  }
`;

interface yandexCardProps {
  title: string;
  description: string;
  titleService: string;
  listService: ReactNode;
  link: string;
  linkHref: string;
  picture: string;
}

export const YandexCard: React.FC<yandexCardProps> = ({
  title,
  description,
  titleService,
  listService,
  link,
  linkHref,
  picture,
}) => {
  return (
    <>
      <div className={styles.yandexCard}>
        <div className={styles.cardLeft}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
          <p className={styles.titleService}>{titleService}</p>
          <div>{listService}</div>
          <Link to={linkHref}>{link}</Link>
        </div>
        <div>
          <img src={picture} alt="" className={styles.yandexPicture} />
        </div>
      </div>
    </>
  );
};
