import React, { useEffect } from 'react';
import { AUTH_PROVIDER, OAUTH_REDIRECT } from '@store/user/contracts';
import { useLazyGetOauthTokenQuery } from '@store/user/user.slice';
import { useLocation, useNavigate } from 'react-router-dom';

export const OAuthAuthorization: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getOauthToken] = useLazyGetOauthTokenQuery();

  useEffect(() => {
    if (window.location.search.includes('code')) {
      const query = window.location.search;
      const urlParams = new URLSearchParams(query);
      const code = urlParams.get('code');

      if (code) {
        getOauthToken({
          path: {
            authProvider: location.pathname.includes('yandex')
              ? AUTH_PROVIDER.YANDEX
              : location.pathname.includes('vk')
              ? AUTH_PROVIDER.VK
              : '',
          },
          query: {
            code: code,
            redirect: location.pathname.includes('yandex')
              ? OAUTH_REDIRECT.YANDEX_REDIRECT
              : location.pathname.includes('vk')
              ? OAUTH_REDIRECT.VK_REDIRECT
              : '',
          },
        })
          .then(() => {
            //            if (response.data?.token) dispatch(setToken({ token: response.data.token }));
          })
          .finally(() => navigate('/'));
      }
    }
  }, [window.location.search]);

  return <></>;
};
