import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { InputField } from '@components/MUI/InputField/InputField';
import { Button } from '@components/MUI/Button';
import { PasswordInput } from '@components/MUI/PasswordInput';

export type LoginFormProps = {
  value: LoginFormValue;
  submit: (value: LoginFormValue, form: FormikBag<LoginFormProps, LoginFormValue>) => void;
};

type LoginFormValue = {
  email: string;
  password: string;
};

const LoginFormSchema = Yup.object({
  email: Yup.string().required('Поле обязательно для заполнения'),
  password: Yup.string().required('Поле обязательно для заполнения'),
});

const FormComponent = () => {
  const loginForm = useFormikContext<LoginFormValue>();
  loginForm.validationSchema = LoginFormSchema;

  return (
    <Form onSubmit={loginForm.handleSubmit}>
      <div className="d-flex flex-column gap-4">
        <InputField name="email" type="text" placeholder="Логин" />
        <PasswordInput name="password" type="password" placeholder="Пароль" />
      </div>
      <div className="mb-5 mt-2">
        <Link
          to="/resetting-password"
          className="color-green color-green_hover border-none background-none fw-500 text-decoration-underline">
          Забыли пароль?
        </Link>
      </div>
      <div>
        <Button
          variant="contained"
          className="pt-3 pb-3 w-100"
          type="submit"
          disabled={!loginForm.isValid || loginForm.isSubmitting}>
          Войти
        </Button>
      </div>
    </Form>
  );
};

export const LoginForm = withFormik<LoginFormProps, LoginFormValue>({
  displayName: 'LoginForm',
  mapPropsToValues: (props) => {
    return {
      email: props.value.email,
      password: props.value.password,
    };
  },
  enableReinitialize: true,
  validationSchema: LoginFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
