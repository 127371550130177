import styles from './YandexPublic.module.scss';
import yandexServices from '@assets/img/yandexPublic/yandexServices.png';
import { Button } from '@components/MUI/Button';
import { TypographyService, YandexCard } from '@pages/yandexPublic/cards/YandexCard';
import yaCloud from '@assets/img/yandexPublic/yaCloud.png';
import { ChoiceYaCard } from '@pages/yandexPublic/cards/ChoiceYaCard';
import { YandexBenefit } from '@pages/yandexPublic/cards/YandexBenefit';
import vector from '@assets/img/yandexPublic/vector.png';
import security from '@assets/img/yandexPublic/security.png';
import economy from '@assets/img/yandexPublic/economy.png';
import access from '@assets/img/yandexPublic/access.png';
import up from '@assets/img/yandexPublic/up.png';
import cloudBackground from '@assets/img/yandexPublic/yandexBackground.png';
import yandex360 from '@assets/img/yandexPublic/yandex360.png';
import egileMan from '@assets/img/yandexPublic/egileMan.png';
import dashboardAnalitic from '@assets/img/yandexPublic/dashboardAnalitic.png';
import integrations from '@assets/img/yandexPublic/integration.png';
import leftCorner from '@assets/img/yandexPublic/leftCorner.png';
import rightCorner from '@assets/img/yandexPublic/rightCorner.png';
import { RateYandexCard, TypographyRate } from '@pages/yandexPublic/cards/RateYandexCard';
import { useEffect, useRef } from 'react';
import {
  OrganizationRequestForm,
  OrganizationRequestFormProps,
} from '@components/OrganizationRequestForm/OrganizationRequestForm';
import { CircularProgress } from '@mui/material';
import { useCreateOrganizationConnectRequestMutation } from '@store/user/user.slice';

export const YandexPublic = () => {
  const topRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [createOrganizationConnect, { isLoading, isSuccess }] =
    useCreateOrganizationConnectRequestMutation();

  const submitOrganizationRequestForm: OrganizationRequestFormProps['submit'] = async (
    OrganizationRequestForm,
    form,
  ) => {
    try {
      await createOrganizationConnect({
        fullName: OrganizationRequestForm.fullName,
        phone: OrganizationRequestForm.phone,
        institutionName: OrganizationRequestForm.institutionName,
        email: OrganizationRequestForm.email,
      });
      form.resetForm();
    } catch (e: any) {
      form.setErrors(e);
    }
  };

  useEffect(() => {
    if (!isLoading && !isSuccess && topRef && topRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoading, isSuccess, topRef.current]);

  const handleScroll = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className={styles.main} ref={topRef}>
        <div className={styles.container}>
          <div className={styles.yandexBanner}>
            <div className={styles.partners}>
              <p className={styles.title}>Мы – партнеры Yandex</p>
              <p className={styles.title__subTitle}>
                <span className={styles.title__subTitle__color}>Получай скидку 5%</span> на
                инструменты Yandex 360 и Yandex Cloud
              </p>
              <p className={styles.title__subTitle__sign}>
                и используй сервисы Yandex на платформе Egile еще выгоднее!
              </p>
              <div className={styles.conditions__btn}>
                <Button style={{ padding: '14px 24px' }} variant="contained" onClick={handleScroll}>
                  Условия
                </Button>
              </div>
            </div>
            <div>
              <img
                src={yandexServices}
                alt="Yandex сервисы"
                className={styles.yandexServiceIcons}
              />
            </div>
          </div>
          <div className={styles.yandexService}>
            <YandexCard
              title="Yandex.Cloud"
              description="Платформа для создания,
							 управления и масштабирования вычислительных ресурсов,
							 хранения данных и работы с ними на удаленных серверах."
              titleService="Сервис предоставляет доступ к таким услугам:"
              listService={
                <div className={styles.listService}>
                  <TypographyService>Хранение и обработка</TypographyService>
                  <TypographyService>Искусственный интеллект и машинное обучение</TypographyService>
                  <TypographyService>Базы данных</TypographyService>
                  <TypographyService>Балансировщики нагрузки и многое другое</TypographyService>
                </div>
              }
              link="https://cloud.yandex.ru/ru/"
              linkHref="https://cloud.yandex.ru/ru/"
              picture={yaCloud}
            />
          </div>
          <div className={styles.yandexItem}>
            <ChoiceYaCard
              title="Почему Yandex Cloud?"
              description="Мы используем Облачную инфраструктуру на платформе Egile,
							 так как она имеет высокую производительность, масштабируемость,
							  безопасность и доступность. Yandex Cloud, кроме этого, имеет удобный интерфейс
							   и низкие цены, что делает его привлекательным как для специалистов,
							   так и для заказчиков."
            />
          </div>
          <div className={styles.yandexBenefits}>
            <YandexBenefit
              picture={vector}
              title="Гибкость и масштабируемость"
              description="Облачные сервисы обеспечивают гибкость и масштабируемость для вашего бизнеса.
							 Вы можете легко увеличить или уменьшить объем ресурсов в зависимости от текущих
							 потребностей. Это позволяет избежать избыточности ресурсов и экономить на их использовании."
            />
            <YandexBenefit
              picture={security}
              title="Безопасность и надежность"
              description="Безопасность и надежность являются ключевыми аспектами при выборе облачных сервисов.
							 Большинство провайдеров предоставляют высокий уровень безопасности с использованием передовых
							 технологий и шифрования данных. Ваши данные хранятся на удаленных серверах,
							 что обеспечивает их защиту от потери или кражи."
            />
            <YandexBenefit
              picture={economy}
              title="Экономия средств"
              description="Использование облачных сервисов может значительно
							 сократить затраты на инфраструктуру.
							 Вам не нужно инвестировать в дорогостоящее оборудование и программное обеспечение,
							 а также оплачивать услуги специалистов по его обслуживанию.
							 Облачные решения позволяют экономить на электроэнергии, охлаждении
							 и аренде помещений."
            />
            <YandexBenefit
              picture={access}
              title="Доступность и непрерывность работы"
              description="Облачные сервисы гарантируют доступность и непрерывность работы вашего бизнеса.
							В случае сбоя в работе оборудования или программного обеспечения, ваши данные будут сохранены на удаленных серверах
							и доступны для вас в любое время."
            />
            <YandexBenefit
              picture={up}
              title="Повышение производительности"
              description="Облачные технологии позволяют повысить производительность
							 труда благодаря удаленному доступу к ресурсам и возможности совместной работы над проектами.
							Вы можете работать из любой точки мира и использовать все доступные инструменты для выполнения задач."
            />
          </div>
          <div className={styles.cardsInfo}>
            <div className={styles.card}>
              <p className={styles.card__title}>По мнению IDS</p>
              <p className={styles.card__description}>
                «Большинство компаний, использующих облачные технологии, определяют их центром
                цифровой трансформации, что обусловлено ключевыми преимуществами внедрения облаков,
                такими как: повышение эффективности использования технических и трудовых ресурсов,
                сокращение времени выхода на рынок, упрощение выхода на новые рынки, масштабирование
                ИТ- инфраструктуры, снижение инвестиционных рисков и пр.»
              </p>
            </div>
            <div className={styles.card}>
              <p className={styles.card__title}>По результатам публичных опросов</p>
              <div className={styles.results}>
                <p className={styles.results__data}>82%</p>
                <p className={styles.results__opinion}>
                  считают использование облаков основным драйвером роста цифровизации
                </p>
              </div>
              <div className={styles.results}>
                <p className={styles.results__data}>52%</p>
                <p className={styles.results__opinion}>
                  признают главным барьером цифровой трансформации нехватку квалифицированных кадров
                  или компетенций для проведения преобразований
                </p>
              </div>
            </div>
          </div>
          <div className={styles.greenCard}>
            <div className={styles.infoGreenCard}>
              <div className={styles.firstCard}>
                <p className={styles.titleCard}>Вы – используете Облако</p>
                <p className={styles.descriptionCard}>и платите только за потребляемые мощности</p>
              </div>
              <div className={styles.secondCard}>
                <p className={styles.titleCard}>Мы – взаимодействуем с Yandex</p>
                <p className={styles.descriptionCard}>для поддержки эффективной работы сервиса</p>
              </div>
              <div>
                <p className={styles.descriptionCard}>
                  Специалисты нашей платформы готовы дополнить ваши команды <br />
                  для содействия процессу трансформации вашей компании
                </p>
              </div>
            </div>
            <div className={styles.yandexServiceIcons}>
              <img src={cloudBackground} alt="" />
            </div>
          </div>
          <div className={styles.yandexItem}>
            <YandexCard
              title="Yandex 360"
              description="Набор сервисов для работы с документами, почтой, календарем и контактами."
              titleService="Он включает в себя"
              listService={
                <div className={styles.listService}>
                  <TypographyService>Яндекс.Диск для хранения и обмена файлами</TypographyService>
                  <TypographyService>Яндекс.Почту с защитой от спама и вирусов</TypographyService>
                  <TypographyService>Яндекс.Календарь для планирования событий</TypographyService>
                  <TypographyService>
                    Яндекс.Контакты для управления контактной информацией
                  </TypographyService>
                </div>
              }
              link="https://360.yandex.ru/business/ "
              linkHref="https://360.yandex.ru/business/ "
              picture={yandex360}
            />
          </div>
          <div className={styles.yandexItem}>
            <ChoiceYaCard
              title="Почему Yandex 360?"
              description="Сервис Yandex 360 позволяют создать общую инфраструктуру для всех
							участников проектной работы, благодаря разнообразию предоставляемых инструментов.
							Кроме перечисленных инструментов, важнейшую роль в эффективной организации проектной
							деятельности играет Yandex Трекер,
							который также входит в инструментарий сервиса Yandex 360."
            />
          </div>
          <div>
            <p className={styles.titleTool}>
              Трекер задач — важнейший инструмент для управления работой в проектных командах
            </p>
          </div>
          <div className={styles.egileTracker}>
            <div>
              <img src={egileMan} alt="" className={styles.pictureStyle} />
            </div>
            <div>
              <p className={styles.titleProcess}>
                Самое главное при проектной деятельности и работе
                <br /> в команде - это грамотно настроить процессы
              </p>
              <p className={styles.titleTracker}>Трекер задач позволяет:</p>
              <div className={styles.listTracker}>
                <TypographyService>Отслеживать прогресс в выполнении задач</TypographyService>
                <TypographyService>Контролировать сроки и загрузку сотрудников</TypographyService>
                <TypographyService>
                  Обеспечивает коммуникацию между членами команды
                </TypographyService>
              </div>
              <p className={styles.descriptionTracker}>
                С его помощью можно создавать задачи, назначать <br /> их на исполнителей,
                устанавливать сроки выполнения,
                <br /> а также отслеживать ход выполнения задач.
              </p>
            </div>
          </div>
          <div className={styles.projectTeam}>
            <div>
              <p className={styles.titleYandexTracker}>
                Мы предоставляем Yandex Tracker на платформе Egile для всех участников проектной
                работы:
              </p>
              <div className={styles.listTracker}>
                <TypographyService>
                  Формирование и распределение задач в команде для менеджера
                </TypographyService>
                <TypographyService>
                  Построение и отслеживание плана работы для исполнителя
                </TypographyService>
                <TypographyService>
                  Аналитика по результатам выполнения проекта для заказчика
                </TypographyService>
              </div>
            </div>
            <div>
              <img src={dashboardAnalitic} alt="" className={styles.pictureStyle} />
            </div>
          </div>
          <div className={styles.job}>
            <div>
              <p className={styles.titleYandexTracker}>Работа в одном окне</p>
              <p className={styles.subTitleTool}>
                Инструменты Yandex интегрированы в платформу,поэтому вам не нужно покидать рабочее
                пространство.
              </p>
              <p className={styles.descriptionJob}>
                В условиях многозадачности важно хранить все материалы по проекту в одном месте,
                чтобы не терять информацию и не тратить время на ее поиск.
              </p>
              <p className={styles.descriptionJob}>
                Это также упрощает процесс коммуникации между участниками проекта, так как они могут
                легко получить доступ к нужной информации. Кроме того, хранение данных в одном месте
                позволяет контролировать изменения и отслеживать прогресс проекта.
              </p>
            </div>
            <div>
              <img src={integrations} alt="" className={styles.pictureStyle} />
            </div>
          </div>
          <div className={styles.greenCard} ref={cardRef}>
            <img src={leftCorner} alt="" className={styles.leftCorner} />
            <div className={styles.programs}>
              <p className={styles.titlePrograms}>Присоединяйся к нашей программе</p>
              <p className={styles.programsDescription}>
                Используйте сервисы Яндекс Облако и Яндекс 360 на сумму более 10 тыс руб <br />
                в месяц, и мы предоставим Вам скидку 5% на данные сервисы при оплате <br />
                через платформу Egile.
              </p>
            </div>
            <div className={styles.yandexServiceIcons}>
              <img src={rightCorner} alt="" className={styles.rightCorner} />
            </div>
          </div>
          <div className={styles.yandexItem}>
            <div>
              <p className={styles.rates360}>Тарифы Yandex 360</p>
            </div>
            <div className={styles.ratesBlocks}>
              <RateYandexCard
                title="Стартап"
                price="236 ₽"
                oldPrice="249 ₽"
                timeOfUSe="В месяц за пользователя"
                items={
                  <>
                    <TypographyRate>100 ГБ места на диске для каждого сотрудника</TypographyRate>
                    <TypographyRate>1 500 писем в Рассылках</TypographyRate>
                    <TypographyRate>Видеовстречи без ограничений по времени</TypographyRate>
                    <TypographyRate>Без рекламы</TypographyRate>
                  </>
                }
                btnText="Подключить"
                onClickConnect={scrollToForm}
              />
              <RateYandexCard
                title="Оптимальный"
                price="445 ₽"
                oldPrice="469 ₽"
                timeOfUSe="В месяц за пользователя"
                profitable={true}
                items={
                  <>
                    <TypographyRate>1 ТБ места на диске для каждого сотрудника</TypographyRate>
                    <TypographyRate>50 000 писем в Рассылках</TypographyRate>
                    <TypographyRate>
                      Трансляции видеовстречи без ограничений по времени
                    </TypographyRate>
                    <TypographyRate>Без рекламы</TypographyRate>
                    <TypographyRate>Единый вход (SSO)</TypographyRate>
                    <TypographyRate>Защищенный архив рабочих писем</TypographyRate>
                  </>
                }
                btnText="Подключить"
                onClickConnect={scrollToForm}
              />
              <RateYandexCard
                title="Расширенный"
                price="1329 ₽"
                oldPrice="1399 ₽"
                timeOfUSe="В месяц за пользователя"
                items={
                  <>
                    <TypographyRate>3 ТБ места на Диске для каждого сотрудника</TypographyRate>
                    <TypographyRate>100 000 писем в Рассылках</TypographyRate>
                    <TypographyRate>
                      Трансляции и видеовстречи без ограничений по времени
                    </TypographyRate>
                    <TypographyRate>Без рекламы</TypographyRate>
                    <TypographyRate>Единый вход (SSO)</TypographyRate>
                    <TypographyRate>Защищённый архив рабочих писем</TypographyRate>
                  </>
                }
                btnText="Подключить"
                onClickConnect={scrollToForm}
              />
            </div>
          </div>
          <div className={styles.form} ref={formRef}>
            <div className={styles.container}>
              <div className={styles.formMain}>
                {isLoading ? (
                  <CircularProgress />
                ) : isSuccess ? (
                  <span className={styles.title__color}>
                    Ваша заявка на подключение успешно отправлена
                  </span>
                ) : (
                  <OrganizationRequestForm
                    value={{
                      fullName: '',
                      phone: '',
                      institutionName: '',
                      email: '',
                      agreement: false,
                    }}
                    submit={submitOrganizationRequestForm}
                    organizationPlaceholder="Название организации*"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
