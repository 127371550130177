import React from 'react';
import { ReactComponent as SendIcon } from '@assets/img/messages/send_icon.svg';
import styles from './InputMessage.module.scss';
import { FormikBag, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';
import { SvgIcon, styled } from '@mui/material';
import { Button } from '@mui/material';

type MessageFormProps = {
  value: MessageFormValue;
  submit: (value: MessageFormValue, form: FormikBag<MessageFormProps, MessageFormValue>) => void;
};

export type MessageFormValue = {
  message: string;
};
const MessageFormSchema = Yup.object({
  message: Yup.string().required('Поле обязательно для заполнения'),
});

const SendButton = styled(Button)(() => ({
  cursor: 'pointer',
  'svg path': {
    transition: 'all 0.3s ease',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    'svg path': {
      fill: '#21B84B',
    },
  },
  '&:active': {
    'svg path': {
      fill: '#148C36',
    },
  },
  '&:disabled': {
    'svg path': {
      fill: '#8CCD9E',
    },
  },
}));

const InputMessage: React.FC = () => {
  const messageForm = useFormikContext<MessageFormValue>();
  messageForm.validationSchema = MessageFormSchema;
  return (
    <div className="bg-white p-2 d-flex justify-content-between align-items-center position-absolute bottom-0 w-100">
      <div className="w-100">
        <form onSubmit={messageForm.handleSubmit} className={styles.form}>
          <InputField
            name="message"
            isLabel={false}
            placeholder="Написать сообщение"
            type="text"
            className="chatForm"
          />
          <SendButton
            type="submit"
            className={styles.sendButton}
            disabled={messageForm.values.message === ''}
            disableRipple>
            <SvgIcon component={SendIcon} inheritViewBox />
          </SendButton>
        </form>
      </div>
    </div>
  );
};

export const MessageForm = withFormik<MessageFormProps, MessageFormValue>({
  displayName: 'MessageForm',
  mapPropsToValues: (props) => {
    return {
      message: props.value.message,
    };
  },
  enableReinitialize: true,
  validationSchema: MessageFormSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
    formikBag.resetForm({});
  },
})(InputMessage);
